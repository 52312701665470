import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ChannelReaction } from 'src/app/common/enums';
import {
  ChannelChat,
  ChannelDetail,
  DocumentFile,
  Entdata,
} from 'src/app/model';
import { ChannelService } from 'src/app/services/channel.service';
import { LoginService } from 'src/app/services/login.service';
import { WebsocketsService } from 'src/app/services/websockets.service';
import { LayoutService } from '../../../../../services/layout.service';

@Component({
  selector: 'app-chat-field',
  templateUrl: './chat-field.component.html',
  styleUrls: ['./chat-field.component.css'],
})
export class ChatFieldComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  public currentItem: Entdata = new Entdata();

  text: string = '';
  @ViewChild('msgList') private msgListElem: ElementRef;
  channelHistory: ChannelChat[] = [];

  public showLoader: boolean = false;
  public isEmojiPickerVisible: boolean;

  profileForm: UntypedFormGroup;
  fileUpload = { status: '', message: '', filename: '', chat: null };
  error: string;

  constructor(
    private loginService: LoginService,
    private channelService: ChannelService,
    private websocketService: WebsocketsService,
    private fb: UntypedFormBuilder,
    public layoutService: LayoutService
  ) {}
  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    this.closeAllReactions();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.channelService.markAsRead(this.ITEMID);
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    console.log('blur');
  }

  ngOnDestroy(): void {
    this.websocketService.echo.leaveChannel('Channel.Item.' + this.ITEMID);
  }

  ngOnInit(): void {
    this.currentItem.isActive = 1;
    this.currentItem.id = +this.ITEMID;
    this.profileForm = this.fb.group({
      name: [''],
      profile: [''],
    });

    this.getChannelHistory();
    this.websocketService.echo
      .private('Channel.Item.' + this.ITEMID)
      .listen('.NewChannelMessage', (e) => {
        this.websocketService.audio.play();
        let chatIndex = this.channelHistory.findIndex(
          (x) => x.id == e.channelMsg.id
        );

        if (chatIndex == -1) {
          this.channelHistory.push(e.channelMsg);
        } else {
          this.channelHistory[chatIndex] = e.channelMsg;
        }
      });
  }

  public getChannelHistory() {
    this.channelService.getChannelHistory(this.ITEMID).then((res) => {
      this.channelHistory = res;
      this.scrollToBottom();
    });
  }

  public isMessageMine(chat: ChannelChat): boolean {
    return chat.user_id == this.loginService.getLoginUser().id;
  }

  public sendMessage() {
    if (this.text != '') {
      let newChatMsg: ChannelChat = new ChannelChat();
      newChatMsg.message = this.text;
      newChatMsg.type = 'text';
      newChatMsg.details = [];
      newChatMsg.entdata_id = this.ITEMID;
      newChatMsg.created_at = new Date();
      newChatMsg.user_id = this.loginService.getLoginUser().id;
      let lastIndex = this.channelHistory.length;
      this.channelHistory.push(newChatMsg);
      this.text = '';
      this.scrollToBottom();
      this.channelService.newChannelChat(newChatMsg).then((res) => {
        this.channelHistory[lastIndex] = res;
      });
    }
  }

  scrollToBottom(): void {
    this.msgListElem.nativeElement.scrollTop =
      this.msgListElem.nativeElement.scrollHeight;
  }

  public setReaction(chat: ChannelChat, reaction: ChannelReaction) {
    chat.showReactions = false;
    let newReaction =
      chat.myreaction == reaction ? ChannelReaction.NoReaction : reaction;
    chat.myreaction = newReaction;
    let newDetail: ChannelDetail = new ChannelDetail();
    newDetail.reaction = newReaction;
    newDetail.channel_chat_id = chat.id;
    let myDetIndex = chat.details.findIndex(
      (x) => x.user_id == this.loginService.getLoginUser().id
    );
    if (myDetIndex == -1) {
      let newDet: ChannelDetail = new ChannelDetail();
      newDet.channel_chat_id = chat.id;
      newDet.reaction = newReaction;
      newDet.user_id = this.loginService.getLoginUser().id;
      newDet.user = this.loginService.getLoginUser();
      chat.details.push(newDet);
    } else {
      chat.details[myDetIndex].reaction = newReaction;
    }

    this.channelService.setReaction(newDetail).then((res) => {
      chat = res;
    });
  }

  onSelectedFile(event) {
    if (event.length > 0) {
      const file = event[0];
      this.profileForm.get('profile').setValue(file);
      for (let file of event) {
        let docFIle: DocumentFile = new DocumentFile();
        docFIle.filename = file.name;
        this.upload(docFIle);
      }
    }
  }

  upload(attachment: any) {
    const formData = new FormData();

    formData.append('profile', this.profileForm.get('profile').value);
    formData.append('channel_id', this.ITEMID);
    this.channelService
      .uploadAttachment(formData, this.ITEMID)
      .subscribe(
        (res) => (this.fileUpload = res),
        (err) => (this.error = err)
      )
      .add((data) => {
        attachment.file_path = this.fileUpload.filename;

        let newChat: ChannelChat = new ChannelChat();

        newChat.myreaction = 0;
        newChat.entdata_id = this.ITEMID;
        newChat.type = 'file';
        newChat.message = this.fileUpload.filename;
        newChat.user_id = this.loginService.getLoginUser().id;
        newChat.user = this.loginService.getLoginUser();
        newChat.created_at = new Date();
        newChat.id = this.fileUpload.chat.id;
        this.channelHistory.push(newChat);
        this.scrollToBottom();
      });
  }

  public downloadAttachment(chat: ChannelChat) {
    this.channelService.downloadFIle(chat).then((file) => {
      window.open(window.URL.createObjectURL(file));
    });
  }

  public getMyreaction(chat: ChannelChat): ChannelReaction {
    if (!chat.details) {
      return ChannelReaction.NoReaction;
    }

    let index = chat.details.findIndex(
      (x) => x.user_id == this.loginService.getLoginUser().id
    );
    if (index == -1) {
      return ChannelReaction.NoReaction;
    } else {
      return chat.details[index].reaction;
    }
  }

  public addEmoji(event) {
    this.text = `${this.text}${event.emoji.native}`;
    this.isEmojiPickerVisible = false;
  }

  public openReactions(chat: ChannelChat) {
    this.closeAllReactions();
    chat.showReactions = true;
  }

  public closeAllReactions() {
    for (let chat of this.channelHistory) {
      chat.showReactions = false;
    }
  }

  public getOnlyFileMsg(): ChannelChat[] {
    return this.channelHistory.filter((x) => x.type == 'file');
  }
}
