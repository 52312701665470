import {
  DoCheck,
  EventEmitter,
  Output,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TimeHelper } from 'src/app/common/helper';
import { Field } from 'src/app/model';

@Component({
  selector: 'app-datetime-filter',
  templateUrl: './datetime-filter.component.html',
  styleUrls: ['./datetime-filter.component.css'],
})
export class DatetimeFilterComponent implements OnInit, DoCheck {
  public selectedDate: Date;
  public isFrom: boolean;
  private oldValue: string = null;
  @Input() FROMORTO: string;
  @Input() ITEM: Field;
  @Output() changeDate = new EventEmitter();
  constructor() {}

  ngDoCheck(): void {
    let currentValue: string;

    if (this.isFrom) {
      currentValue = this.ITEM.search_from;
    } else {
      currentValue = this.ITEM.search_to;
    }

    if (this.oldValue != currentValue) {
      this.selectedDate = this.isFrom
        ? new Date(this.ITEM.search_from)
        : new Date(this.ITEM.search_to);
      this.oldValue = this.isFrom ? this.ITEM.search_from : this.ITEM.search_to;
    }
  }

  ngOnInit(): void {
    this.isFrom = this.FROMORTO == 'from';
    if (this.isFrom && this.ITEM.search_from) {
      this.oldValue = this.ITEM.search_from;
      this.selectedDate = new Date(this.ITEM.search_from);
    } else if (!this.isFrom && this.ITEM.search_to) {
      this.oldValue = this.ITEM.search_to;
      this.selectedDate = new Date(this.ITEM.search_to);
    }
  }

  public change(ev) {
    this.changeDate.emit();
    if (this.isFrom) {
      this.ITEM.search_from = TimeHelper.getDatetimeString(this.selectedDate);
    } else {
      this.ITEM.search_to = TimeHelper.getDatetimeString(this.selectedDate);
    }
  }
}
