import {Component, Input, OnInit} from '@angular/core';
import {CostCenterService} from '../../../../../services/cost-center.service';
import {CostCenter} from '../../../../../model';
import {LoginService} from '../../../../../services/login.service';
import {TimeHelper} from "../../../../helper";

@Component({
  selector: 'app-cost-centers',
  templateUrl: './cost-centers.component.html',
  styleUrls: ['./cost-centers.component.css']
})
export class CostCentersComponent implements OnInit{

  @Input() ITEM: any;
  title = '';
  costCenters: CostCenter[];
  chosenCostCenter: number;

  constructor(private costCenterService: CostCenterService,
              private loginService: LoginService) {
  }
  ngOnInit(){
    this.showTitle();
    this.fetchCostCenters();
  }

  private fetchCostCenters() {
    this.costCenterService.fetchCostCenters().then(res => {
      this.costCenters = this.getLeafCostCenters(res);
      this.chosenCostCenter = Number(this.ITEM.value);
    });
  }

  private getLeafCostCenters(costCenters: CostCenter[]) {
    const parentCostCenterIds = costCenters.map(costCenter => costCenter.parentCostCenter);
    return costCenters.filter(costCenter => !parentCostCenterIds.includes(costCenter.id));
  }

  private showTitle() {
    const lang = this.loginService.getLoginUser().lang;
    this.title = lang === 'gr' ? this.ITEM.label_gr : this.ITEM.label_en;
  }

  public change() {
    this.ITEM.value = this.chosenCostCenter;
  }
}
