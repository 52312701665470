


export class RecipeLine {
  id: number;
  entdata_id: number;
  product_id: number;
  product: any;
  qty: number;
  unit:number;
  created_at: Date;
  updated_at: Date;
}
