import {Component, OnInit} from '@angular/core';
import {DashboardService} from '../../../services/dashboard.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ApprovalNewComponent} from '../../approval/approval-new/approval-new.component';
import {WebsocketsService} from '../../../services/websockets.service';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from "../../../services/login.service";
import {DashboardNums} from "../../../model";
import {Router} from "@angular/router";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{

  constructor(
    private dashboardService: DashboardService,
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    public webSocket: WebsocketsService,
    public translateService: TranslateService,
    private loginService: LoginService,
    private router: Router
  ) { }

  dashboardNums: DashboardNums = {
    approvals: 0,
    tasks: 0,
    meetings: 0,
    channelMsg: 0,
    quotes: 0,
    opportunities: 0,
    serviceTickets: 0,
    invoices: 0
  };
  dashboardCols: number;
  dashboardRowHeight: string;
  isSalesman = this.loginService.getLoginUser().isSalesman;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  ngOnInit(): void {
    this.getDashboardNums();

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large,
      Breakpoints.XLarge, Breakpoints.HandsetLandscape]).
    subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {
        this.dashboardRowHeight = '100px';
        this.dashboardCols = 1;
      } else if (result.breakpoints[Breakpoints.Small]) {
        this.dashboardRowHeight = '200px';
        this.dashboardCols = 2;
      } else if (result.breakpoints[Breakpoints.Medium]) {
        this.dashboardRowHeight = '200px';
        this.dashboardCols = 2;
      } else if (result.breakpoints[Breakpoints.Large] || result.breakpoints[Breakpoints.XLarge]) {
        this.dashboardRowHeight = '200px';
        this.dashboardCols = 3;
      }
    });
  }

  private getDashboardNums() {
    this.dashboardService.getDashboardNums().then(res => {
      this.dashboardNums = res;
    });
  }

  public act(action: string) {
    switch (action) {
      case 'notifications':
        this.dashboardService.openNotifications.next(true);
        break;
      case 'questions':
        this.dashboardService.openQuestions.next(true);
        break;
      case 'new_approval':
        this.dashboardService.openNewApproval.next(true);
        this.add_new_approval();
        break;
    }
  }

  public add_new_approval() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {};

    this.dialog.open(
      ApprovalNewComponent,
      dialogConfig
    );
  }

  redirect(action2: string) {
    if (['/quote', '/opportunity', '/service', '/invoice'].includes(action2)){
      this.dashboardService.applySalesmanEntityDateFilter = true;
    }
    this.router.navigate([action2]);
  }
}
