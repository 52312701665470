<mat-form-field>
  <input
    matInput
    [matDatepicker]="datePicker"
    [(ngModel)]="selectedDate"
    (ngModelChange)="change()"
    [disabled]="disabled"
  >
  <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker></mat-datepicker>
</mat-form-field>
