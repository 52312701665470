import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApprovalNewComponent } from '../approval-new/approval-new.component';

@Component({
  selector: 'app-approval-item-diag',
  templateUrl: './approval-item-diag.component.html',
  styleUrls: ['./approval-item-diag.component.css']
})
export class ApprovalItemDiagComponent implements OnInit {

  public approvalId: number;

  constructor(
    public dialogRef: MatDialogRef<ApprovalNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    this.dialogRef.updateSize('80%', '80%');
    this.approvalId = this.data.approvalId;
  }

  public close(hasChanges: boolean) {
    this.dialogRef.close(hasChanges);

  }

}
