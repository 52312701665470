import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BaseService} from "./base.service";
import {NotesTableLine} from "../model";

@Injectable({
  providedIn: 'root'
})
export class NotesService extends BaseService{

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getNotesTableLines(entdataId:number, entfieldId: number): Promise<NotesTableLine[]> {
    return this.get<NotesTableLine[]>(this.apiUrl + `notes/${entdataId}/${entfieldId}`);
  }
}
