<div class="buttonPanel mat-elevation-z3">
  <button mat-button [mat-dialog-close]="null">
    <mat-icon>keyboard_backspace</mat-icon>{{'Generic.back' | translate}}
  </button>
  <mat-divider [vertical]="true"></mat-divider>
</div>
<div mat-dialog-content style="margin-top: 0;">
  <app-data-list *ngIf="entityCode" [ENTITY]="entityCode" (selectEntityItem)="save($event)" [SHOWSEARCH]="true"
    [SHOWTRASHFILTER]="false" [SEARCHONCOLS]="true" [SEARCHFIELDS]="searchFields"></app-data-list>
</div>
