import { DurationHelper } from 'src/app/common/helper';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogleChartInterface } from 'ng2-google-charts';
import { Sprint, TaskDto } from 'src/app/model';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataItemDiagComponent } from 'src/app/common/entity/data-item-diag/data-item-diag.component';
import { LayoutService } from '../../../../../../services/layout.service';

@Component({
  selector: 'app-project-sprint',
  templateUrl: './project-sprint.component.html',
  styleUrls: ['./project-sprint.component.css'],
})
export class ProjectSprintComponent implements OnInit {
  @Input() SPRINT: Sprint;
  @Input() ALLSPRINT: Sprint[] = [];
  @Input() PROJECTID: number;
  @Output() refreshData = new EventEmitter();

  public burnDownChartExpand = false;
  public chart: GoogleChartInterface;
  public durationHelper: DurationHelper = new DurationHelper();
  constructor(
    private __location: Location,
    public dialog: MatDialog,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.chart = {
      chartType: 'ComboChart',
      dataTable: this.fillChart(),
      firstRowIsData: false,
      options: {
        chart: {},
        vAxis: { title: 'Hours', format: 'short' },

        //   hAxis: { title: 'Days from Starting Date', format: 'date' },
        seriesType: 'bars',
        series: { 0: { type: 'line' } },
        height: 200,
        width: 500,
      },
    };
  }

  private fillChart(): any[] {
    const strartdateArr: Date[] = this.getStartDatesArr();
    const enddataArr: Date[] = this.getEndDatesArr();
    const idialDuration = this.durationHelper.getIdialDuration(this.SPRINT);
    const minDateStr = Math.min.apply(null, strartdateArr);
    const maxEndDateStr = Math.max.apply(null, enddataArr);
    const minDate: Date = new Date(minDateStr);
    minDate.setHours(0, 0, 0, 0);
    const maxDate: Date = new Date(maxEndDateStr);
    maxDate.setHours(0, 0, 0, 0);

    const totalDays: number = this.date_diff_indays(minDate, maxDate);

    let arr: any[] = [
      ['date', 'Ideal', 'Hours Remaining'],
      [minDate.toLocaleDateString(), idialDuration, idialDuration],
    ];

    let hoursforIdialLine = idialDuration;
    let hoursForCompletedLine = idialDuration;

    for (let i = 1; i <= totalDays; i++) {
      hoursforIdialLine -= idialDuration / totalDays;

      for (const task of this.SPRINT.tasksDto) {
        if (
          task.completed_at &&
          +this.durationHelper.getDiffInNumOfDays(
            minDate,
            task.completed_at
          ) === i
        ) {
          hoursForCompletedLine -=
            this.durationHelper.durationToMinutes8h(task.task_duration) / 60;
        }
        if (this.date_diff_indays(minDate, new Date()) <= i) {
          hoursForCompletedLine = 0;
        }
      }

      let chartDate: Date = new Date(minDate);
      chartDate.setDate(chartDate.getDate() + i);

      arr.push([
        chartDate.toLocaleDateString(),
        hoursforIdialLine,
        hoursForCompletedLine,
      ]);
    }
    return arr;
  }

  private getStartDatesArr(): Date[] {
    let dateArr: Date[] = [];
    for (const task of this.SPRINT.tasksDto) {
      if (task.task_startdate) {
        dateArr.push(new Date(task.task_startdate));
      }
    }
    dateArr = dateArr.length === 0 ? [new Date()] : dateArr;
    return dateArr;
  }

  private getEndDatesArr(): Date[] {
    let dateArr: Date[] = [];
    for (const task of this.SPRINT.tasksDto) {
      if (task.task_enddate) {
        dateArr.push(new Date(task.task_enddate));
      }
    }
    dateArr = dateArr.length === 0 ? [new Date()] : dateArr;
    return dateArr;
  }

  private date_diff_indays(dt1, dt2) {
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  }

  public getStatusBackground(task: any): string {
    if (task.task_status === 'Done') {
      return 'doneBack';
    } else if (task.task_status === 'Backlog') {
      return 'backlogBack';
    } else if (task.task_status === 'Working on it') {
      return 'workingBack';
    } else {
      return 'elseBack';
    }
  }

  public goBack() {
    this.__location.back();
  }

  public openTask(task: TaskDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: task.id,
      entityCode: 'tasks',
    };
    const dialogRef = this.dialog.open(DataItemDiagComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      this.refreshData.emit(true);
    });
  }
}
