<div class="fld">
  <table class="full-width">
    <tbody>
      <tr>
        <td>
          <span *ngIf="latstDoc">{{ latstDoc.filename }} </span>
        </td>
        <td>
          <span *ngIf="latstDoc">{{ latstDoc.created_at | date }}</span>
        </td>
        <td>
          <span *ngIf="latstDoc">
            <button
              mat-icon-button
              color="primary"
              style="z-index: 1000"
              *ngIf="this.latstDoc.id"
              (click)="downloadFile(latstDoc.id)"
            >
              <mat-icon>save_alt</mat-icon>
            </button>
          </span>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <input
    hidden
    (change)="onSelectedFile($event)"
    #datafileInput
    type="file"
    name="file"
  />
  <div style="width: 100%; height: 10px">
    <mat-progress-bar
      *ngIf="fileUpload.status === 'progress'"
      mode="determinate"
      [value]="fileUpload.message"
    >
    </mat-progress-bar>
  </div>
  <div style="display: flex">
    <div style="margin-top: 13px">
      <button
        mat-icon-button
        color="primary"
        (click)="datafileInput.click()"
        *ngIf="OWNER | hideFileHistoryButton: ITEM as result"
        [disabled]="disabled"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div style="width: 100%">
      <mat-expansion-panel style="margin-top: 10px; margin-bottom: 20px">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ 'Documents.history' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <table class="full-width">
          <tbody>
            <tr *ngFor="let file of files">
              <td>{{ file.filename }}</td>
              <td>{{ file.created_at | date }}</td>
              <td>
                <button
                  mat-icon-button
                  color="primary"
                  style="z-index: 1000"
                  *ngIf="this.file.id"
                  (click)="downloadFile(file.id)"
                >
                  <mat-icon>save_alt</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-expansion-panel>
    </div>
  </div>
</div>
