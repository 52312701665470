<mat-table [dataSource]="warehouses">
  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="title">
    <mat-header-cell *matHeaderCellDef> {{'ERPWarehouse.warehouse' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="stock">
    <mat-header-cell *matHeaderCellDef> {{'ERPWarehouse.stock' | translate}}  </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.stock}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="details">
    <mat-header-cell *matHeaderCellDef> {{'Generic.analyze' | translate}}  </mat-header-cell>
    <mat-cell *matCellDef="let element"> <button mat-icon-button color="primary" (click)="viewHistory(element.id)"><mat-icon>history</mat-icon></button> </mat-cell>
  </ng-container>




  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
