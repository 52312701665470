import { Field } from '../model'
import { ApprovalDecision } from './enums'

export class BaseFilter {
  constructor() {
    this.strValue = ''
    this.isDescending = 0
    this.currentPage = 1
    this.pageSize = 10
    this.trashed = 0
  }
  strValue: string
  orderColumn: string
  isDescending: number
  pageSize: number
  currentPage: number
  trashed: number
  stepValue?: number
}

export class UsersFilter extends BaseFilter {
  constructor() {
    super()
  }
  companyId: number
  departmentId: number
}

export class SemiProductsFilter extends BaseFilter {
  constructor() {
    super()
  }
  semiProdIds: number[]
}

export class EntityItemFilter extends BaseFilter {
  constructor() {
    super()
    this.searchFields = []
    this.onlyFollow = 0
    this.pageSize =
      localStorage.getItem('gridPageSize') !== null
        ? JSON.parse(localStorage.getItem('gridPageSize'))
        : 20
    this.departmentId = null
  }
  connectedWith: number = null
  searchFields: Field[]
  onlyFollow: number
  itemId: number
  ownerId: number
  createdAtSearchDate: Date
  departmentId: number
}

export class ReportFilter {
  from: Date
  to: Date
  companyId: number
}

export class TaskFilter extends EntityItemFilter {
  constructor() {
    super()
    this.status = []
    this.taskgroup_id = null
  }

  status: string[]
  startDate: Date
  endDate: Date
  taskgroup_id: number
  project_id: number
}

export class ScheduleFilter extends EntityItemFilter {
  constructor() {
    super()
  }
  schedule_id: number
}

export class ApprovalFilter extends BaseFilter {
  constructor() {
    super()
  }
  decision: ApprovalDecision
  signatory_id: number
}

export class SpdocFilter extends BaseFilter {
  constructor() {
    super()
  }
  transactor_id: number
  from: string
  to: string
  spdocType?: string
}

export class MoneydocFilter extends BaseFilter {
  constructor() {
    super()
  }
  transactor_id: number
  from: string
  to: string
}
