import { inject, Pipe, PipeTransform } from '@angular/core';
import { WebsocketsService } from '../services/websockets.service';

@Pipe({
  name: 'isFieldQuestioned',
})
export class IsFieldQuestionedPipe implements PipeTransform {
  websocketService = inject(WebsocketsService);
  transform(field: any, entdataId: number): boolean {
    let index = this.websocketService.questions.findIndex(
      (x) =>
        Number(x.entfield_id) === Number(field.id) &&
        Number(x.entdata_id) === entdataId
    );
    return index != -1;
  }
}
