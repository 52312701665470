import { Injectable } from '@angular/core'
import { BaseService } from './base.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { DashboardNums } from '../model'

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseService {
  public openQuestions = new BehaviorSubject<boolean>(false)
  public openNotifications = new BehaviorSubject<boolean>(false)
  public openNewApproval = new BehaviorSubject<boolean>(false)
  public toggleSidebar = new Subject<boolean>()

  public applySalesmanEntityDateFilter = false

  constructor(private httpClient: HttpClient) {
    super(httpClient)
  }

  public getDashboardNums(): Promise<DashboardNums> {
    return this.get<DashboardNums>(this.apiUrl + 'dashboard/nums')
  }
}
