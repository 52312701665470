import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { RecipeLine } from 'src/app/models/recipeline';
import { ProductService } from 'src/app/services/product.service';
import { LayoutService } from '../../../../../services/layout.service';

@Component({
  selector: 'app-prodrecipelist-field',
  templateUrl: './prodrecipelist-field.component.html',
  styleUrls: ['./prodrecipelist-field.component.css'],
})
export class ProdrecipelistFieldComponent implements OnInit {
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  @Input() allAttr: any;

  public lines: RecipeLine[] = [];
  selectedColumns: string[] = ['id', 'product_name', 'qty', 'unit', 'del'];
  @ViewChild(MatTable) table: MatTable<any>;

  constructor(
    private productService: ProductService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.ITEM.recipelines = [];
  }

  ngAfterViewInit(): void {
    if (this.ITEMID != 'new') {
      this.getSavedLines(this.ITEMID);
    } else {
      this.addItemLine(null);
    }
  }

  private getSavedLines(entdataId: string) {
    this.productService.getSavedRecipeLines(entdataId).then((res) => {
      this.ITEM.recipelines = res;
      this.lines = res;
      this.addItemLine(null);
      this.refresh();
    });
  }

  public fillProdlineItem(item: any, line: RecipeLine) {
    line.product = item;
    line.product_id = item.id;
    this.addItemLine(null);
  }

  public removeItemLine(index: number) {
    this.lines.splice(index, 1);
    this.refresh();
  }

  public fillInstallationlineItem(item: any, line: RecipeLine) {
    console.log(item);
    line.product = item;
    line.product_id = item.id;
    this.addItemLine(null);
  }

  public refresh() {
    this.table.renderRows();
    this.ITEM.recipelines = this.lines.filter((x) => x.product);
  }

  public addItemLine(item: any) {
    let newLine: RecipeLine = new RecipeLine();
    newLine.product = item;
    newLine.qty = 1;
    newLine.unit = 0;
    newLine.product_id = item?.id ?? null;
    this.lines.push(newLine);
    this.refresh();
  }
}
