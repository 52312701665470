import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Approval, Assignee } from 'src/app/models/approval'
import { ApprovalService } from 'src/app/services/approval.service'
import { Location } from '@angular/common'
import { NotificationService } from 'src/app/services/notification.service'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { EntitySelectorDiagComponent } from 'src/app/common/entity-selector-diag/entity-selector-diag.component'
import { LoginService } from 'src/app/services/login.service'
import { EntityService } from 'src/app/services/entity.service'
import { DataItemDiagComponent } from 'src/app/common/entity/data-item-diag/data-item-diag.component'

@Component({
  selector: 'app-approval-item',
  templateUrl: './approval-item.component.html',
  styleUrls: ['./approval-item.component.css'],
})
export class ApprovalItemComponent implements OnInit {
  public approvalId: string
  public approval: Approval
  public onDecisionMode: boolean = false
  public desicionMessage: string = ''
  private isRoutedPage: boolean
  private hasChanges: boolean = false

  @Input() APPROVALID: number
  @Output() BACK = new EventEmitter<boolean>()

  constructor(
    private acitveRoute: ActivatedRoute,
    private approvalService: ApprovalService,
    private breakpointObserver: BreakpointObserver,
    private __location: Location,
    private approveService: ApprovalService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    public loginService: LoginService,
    private entityService: EntityService
  ) {}

  ngOnInit(): void {
    if (this.APPROVALID) {
      this.isRoutedPage = false
      this.approvalId = this.APPROVALID + ''
    } else {
      this.isRoutedPage = true
      this.approvalId = this.acitveRoute.snapshot.paramMap.get('aprovalid')
    }

    this.getApproval()
  }

  private getApproval() {
    this.approvalService.getSingleApproval(this.approvalId).then((res) => {
      this.approval = res

      this.onDecisionMode = this.findIfCanSign()
    })
  }

  private findIfCanSign(): boolean {
    let currentUserId = this.loginService.getLoginUser().id
    return (
      this.approval.assignees.some(
        (x) => x.assignee_id == currentUserId && x.decided_at == null
      ) && this.approval.decision == null
    )
  }

  public goBack() {
    if (this.isRoutedPage) {
      this.__location.back()
    } else {
      this.BACK.emit(this.hasChanges)
    }
  }

  public approve() {
    this.hasChanges = true
    let assignee = new Assignee()
    assignee.message = this.desicionMessage

    this.approveService.approve(assignee, this.approval.id).then((res) => {
      if (res) {
        this.notificationService.showSnackbarMessage('Messages.successfulSave')
        this.onDecisionMode = false
        this.getApproval()
      } else {
        this.notificationService.showSnackbarMessage('Messages.failSave')
      }
    })
  }

  public reject() {
    this.hasChanges = true
    let assignee = new Assignee()
    assignee.message = this.desicionMessage

    this.approveService.reject(assignee, this.approval.id).then((res) => {
      if (res) {
        this.notificationService.showSnackbarMessage('Messages.successfulSave')
        this.onDecisionMode = false
        this.getApproval()
      } else {
        this.notificationService.showSnackbarMessage('Messages.failSave')
      }
    })
  }

  public approveAndFrw() {
    this.selectFwrUserDiag()
    //this.approve();
  }

  public selectFwrUserDiag() {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true

    dialogConfig.data = {
      entityCode: 'user',
      // entity_id: this.entity.id
    }

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    )

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.hasChanges = true
        let assignee = new Assignee()
        assignee.message = this.desicionMessage

        this.approvalService
          .approveAndFrw(assignee, this.approval.id, result.id)
          .then((res) => {
            if (res) {
              this.notificationService.showSnackbarMessage(
                'Messages.successfulSave'
              )
              this.onDecisionMode = false
              this.getApproval()
            } else {
              this.notificationService.showSnackbarMessage('Messages.failSave')
            }
          })
      }
    })
  }

  public showItem() {
    this.entityService
      .getItemEntity(this.approval.entdata_id + '')
      .then((res) => {
        this.previewItem(this.approval.entdata_id + '', res.entity_code)
      })
  }

  public previewItem(itemId: string, entcode: string) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true

    dialogConfig.data = {
      itemId: itemId,
      entityCode: entcode,
    }
    this.dialog.open(DataItemDiagComponent, dialogConfig)
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches))
}
