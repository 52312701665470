import { Report } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public newReport(report:Report): Promise<any> {
    return this.post<any>(this.apiUrl + 'admin/report', report);
  }

  public getReportView(reportId:string): Promise<any> {
    return this.getView<any>(this.apiUrl + 'report/view/'+reportId);
  }


  public downloadReportPdf(reportId:string): Promise<any> {
    return this.getFile<any>(this.apiUrl + 'report/pdf/'+reportId);
  }



}
