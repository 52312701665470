import { Company, ReturnData, Department, Module, CompanyReport } from './../model';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ReportFilter } from '../common/filters';
import {BehaviorSubject} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {
  private helper = new JwtHelperService();
  teamworkModules = new BehaviorSubject<Module[]>([]);
  getSavedTabState = new BehaviorSubject<boolean>(false);
  userModules: Module[] = [];
  userAdminModules: Module[] = [];
  userSupplyChainModules: Module[] = [];
  userSalesModules: Module[] = [];
  userManagementModules: Module[] = [];
  userHrModules: Module[] = [];
  userErpModules: Module[] = [];
  userCustomEntityModules: Module[] = [];
  userMaintenanceModules: Module[] = [];
  favoriteModules: Module[] = [];
  favModuleIdsToString = '';

  erpModules = [
    {icon: 'upload_file', title: 'ERP.erp_wh_docs', action: 'erp_docs'},
    {icon: 'transfer_within_a_station', title: 'ERP.accounting', action: 'erp_docs/accounting'},
    {icon: 'savings', title: 'ERPMoneyaccounts.moneyaccount', action: 'erp_docs/moneydocs'},
    {icon: 'warehouse', title: 'ERPCards.warehouse', action: 'erp_docs/card/product'},
    {icon: 'outbox', title: 'ERPCards.account_card', action: 'erp_docs/card/account'},
    {icon: 'move_to_inbox', title: 'ERPCards.supplier_card', action: 'erp_docs/card/supplier'},
    {icon: 'account_balance', title: 'ERPCards.money_account_card', action: 'erp_docs/card/money_account'},
    {icon: 'local_police', title: 'ERPCards.vat_card', action: 'erp_docs/card/vat'}
  ];

  constructor(private httpClient: HttpClient,
              private translateService: TranslateService) {
    super(httpClient);
  }

  public getCompanies(): Promise<Company[]> {
    return this.get<Company[]>(this.apiUrl + 'companies');
  }

  public newCompany(company: Company): Promise<ReturnData<Company>> {
    return this.post<ReturnData<Company>>(this.apiUrl + 'companies', company);
  }

  public getCompanyById(companyId: string): Promise<Company> {
    return this.get<Company>(this.apiUrl + 'companies/' + companyId);
  }

  public updateCompany(company: Company): Promise<ReturnData<any>> {
    return this.put<ReturnData<any>>(this.apiUrl + 'companies', company);
  }

  public deleteCompany(company: Company): Promise<ReturnData<any>> {
    return this.delete<ReturnData<any>>(this.apiUrl + 'companies/' + company.id);
  }

  public addDepartment(department: Department): Promise<ReturnData<Company>> {
    return this.post<ReturnData<Company>>(this.apiUrl + 'departments', department);
  }

  public editDepartment(department: Department): Promise<ReturnData<Company>> {
    return this.put<ReturnData<Company>>(this.apiUrl + 'departments', department);
  }
  public deleteDepartment(department: Department): Promise<ReturnData<Company>> {
    return this.delete<ReturnData<Company>>(this.apiUrl + 'departments/' + department.id);
  }

  public getModules(): Promise<Module[]> {
    return this.get<Module[]>(this.apiUrl + 'modules');
  }

  public getCompanyReport(analysisFilter: ReportFilter): Promise<CompanyReport> {
    return this.post<CompanyReport>(this.apiUrl + 'admin/report/' + analysisFilter.companyId, analysisFilter);
  }

  public getCompanyForUser(): Promise<Company> {
    return this.get<Company>(this.apiUrl + 'companyForUser');
  }


  getUserAuthorizedModules(){
    const jwtToken = localStorage.getItem('token');
    const scopesArray = this.helper.decodeToken(jwtToken).scopes;
    const teamworkModules = this.teamworkModules.getValue();
    const userModules = teamworkModules.filter(mod => scopesArray.includes(mod.scope));
    this.userModules = userModules.map(mod => ({
      ...mod,
      title: this.translateService.instant(`Modules.${mod.path}`)
    }));
    this.storeUserModules();
  }

  private storeUserModules() {
    this.userAdminModules = this.userModules.filter(mod => mod.section === 'admin');
    this.userSupplyChainModules = this.userModules.filter(mod => mod.section === 'supplyChain');
    this.userSalesModules = this.userModules.filter(mod => mod.section === 'sales');
    this.userManagementModules = this.userModules.filter(mod => mod.section === 'management');
    this.userHrModules = this.userModules.filter(mod => mod.section === 'HR');
    this.userErpModules = this.userModules.filter(mod => mod.section === 'warehouse');
    this.userMaintenanceModules = this.userModules.filter(mod => mod.section === 'maintenance');
    this.userCustomEntityModules = this.userModules.filter(mod => mod.section === 'customEntity');
  }

  getUserFavoriteModules(){
    return this.get<any>(this.apiUrl + 'modules/favorites').then(res => {
      if (res){
        this.favModuleIdsToString = res.modules;
        const favModulesIds = this.favModuleIdsToString.split(',').map(id => Number(id));
        this.favoriteModules = this.userModules.filter(x => favModulesIds.includes(x.id));
      }
      this.getSavedTabState.next(true);
    });
  }

  saveUserFavoriteModules(){
    const favModuleIds = this.favoriteModules.map(x => x.id).join(',');
    if (favModuleIds !== this.favModuleIdsToString){
      return this.post<any>(this.apiUrl + 'modules/favorites', {modules: favModuleIds}).then(res => {
        if (res.success){
          this.favModuleIdsToString = favModuleIds;
        }
      });
    }
  }

}
