
<mat-form-field   class="full-width">

  <input  [owlDateTimeTrigger]="dt2"   matInput [owlDateTime]="dt2" [(ngModel)]="selectedDate" (ngModelChange)="change($event)">
  <span class="calendarbtn" matSuffix [owlDateTimeTrigger]="dt2">

    <mat-icon color="primary">calendar_today</mat-icon>
  </span>
  <owl-date-time #dt2></owl-date-time>


</mat-form-field>
