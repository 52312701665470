<div style="display: flex">
  <mat-form-field class="example-form-field">
    <mat-label class="title">{{ LABEL }}</mat-label>
    <input
      matInput
      type="text"
      (click)="showPopUpList()"
      [disabled]="SHOWTABLE"
    />
  </mat-form-field>
</div>

<div class="tooltip" *ngIf="SHOWTABLE">
  <button mat-icon-button class="closeBtn" (click)="hidePopUpList()">
    <mat-icon color="warn">close</mat-icon>
  </button>

  <app-data-list
    [ENTITY]="ENTITY"
    (selectEntityItem)="selectItem($event)"
    [SHOWSEARCH]="false"
    [SHOWSPINNER]="false"
    [SHOWTRASHFILTER]="false"
    [FILTERMODE]="false"
    [MULTISELECT]="false"
    [SEARCHONCOLS]="true"
  >
  </app-data-list>
</div>
