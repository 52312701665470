<div class="buttonPanel mat-elevation-z3">
  <span>
    <button mat-button [mat-dialog-close]="null">
      <mat-icon>keyboard_backspace</mat-icon>
      <span *ngIf="!(isHandset$ | async)">{{
        'Generic.back' | translate
      }}</span>
    </button>
  </span>
  <mat-divider [vertical]="true"></mat-divider>

  <span>
    <button mat-button (click)="save()">
      <mat-icon>save</mat-icon>
      <span *ngIf="!(isHandset$ | async)">{{
        'Generic.save' | translate
      }}</span>
    </button>
  </span>
  <mat-divider [vertical]="true"></mat-divider>
</div>

<div mat-dialog-content class="content">
  <div *ngFor="let attr of getOnlyEditableAttrs()" class="attributeDiv">
    <div>
      <mat-checkbox
        class="example-margin"
        color="primary"
        [(ngModel)]="attr.changeAttr"
      >
        <span *ngIf="!attr.changeAttr">
          {{ attr | translateFieldLabel }}
        </span>
      </mat-checkbox>
      <app-fields
        *ngIf="attr.changeAttr"
        [allAttr]="currentEntItem.data"
        [attr]="attr"
        [ITEMID]="'new'"
        [OWNER]="currentEntItem.owner"
        [entCode]="entityCode"
        style="width: 95%"
        [showMandatoryErrors]="false"
      >
      </app-fields>
    </div>
  </div>
</div>

<div mat-dialog-actions></div>
