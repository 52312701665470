import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Field } from 'src/app/model';

@Component({
  selector: 'app-entity-selector-diag',
  templateUrl: './entity-selector-diag.component.html',
  styleUrls: ['./entity-selector-diag.component.css']
})
export class EntitySelectorDiagComponent implements OnInit {
  entityCode: string;
  searchFields: Field[];
  constructor(private dialogRef: MatDialogRef<EntitySelectorDiagComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.entityCode = this.data.entityCode;
    this.searchFields = this.data.searchFields;

  }

  save(ev) {
    this.dialogRef.close(ev);
  }
}
