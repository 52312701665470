import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
  DestroyRef
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { from } from 'rxjs';
import { ExpenseLine } from 'src/app/model';
import { ExpenseService } from 'src/app/services/expense.service';
import { SettingsService } from '../../../../../services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LayoutService } from '../../../../../services/layout.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-expenselist',
  templateUrl: './expenselist.component.html',
  styleUrls: ['./expenselist.component.css'],
})
export class ExpenseListComponent implements OnInit, AfterViewInit {
  constructor(
    private expenseService: ExpenseService,
    public settingsService: SettingsService,
    private translateService: TranslateService,
    public layoutService: LayoutService,
    private destroyRef: DestroyRef
  ) {}

  public lines: ExpenseLine[] = [];
  displayedColumns: string[] = [
    'id',
    'expense_name',
    'amount',
    'date',
    'pay_method',
    'extranumeric1',
    'extranumeric2',
    'extranumeric3',
    'extrastr1',
    'extrastr2',
    'extrastr3',
    'extraDrpDown1',
    'extraDrpDown2',
    'extraDrpDown3',
    'del',
  ];
  selectedColumns: string[] = [];
  @ViewChild(MatTable) table: MatTable<any>;
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled = false;
  @Input() allAttr: any;
  localstorageKey: string;
  gridCols: any[] = [];
  isHandset = false;

  ngOnInit(): void {
    this.ITEM.expenselines = [];
    this.lines = [];
    this.rememberSelectedCols();

    this.layoutService.isHandset$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x) => {
        this.isHandset = x;
      });
  }

  ngAfterViewInit(): void {
    if (this.ITEMID !== 'new') {
      this.getSavedLines(this.ITEMID);
    } else {
      this.addItemLine(null);
    }
  }

  private getSavedLines(entdataId: string) {
    from(this.expenseService.getSavedLines(entdataId)).subscribe((res) => {
      this.ITEM.expenselines = res;
      this.lines = res;
      this.addItemLine(null);
      this.refresh();
    });
  }

  public removeItemLine(index: number) {
    this.lines.splice(index, 1);
    this.refresh();
  }

  public fillExpenselineItem(item: any, line: ExpenseLine) {
    line.expense = item;
    line.expense_id = item.id;
    line.entfield_id = this.ITEM.id;
    line.entdata_id = this.ITEMID;
    line.amount = 0;
    line.date = item.date;
    line.pay_method = item.pay_method;
    this.addItemLine(null);
  }

  public refresh() {
    this.table.renderRows();
    this.ITEM.expenselines = this.lines.filter((x) => x.expense);
  }

  public addItemLine(item: any) {
    const newLine: ExpenseLine = new ExpenseLine();
    newLine.expense_id = item;
    newLine.amount = 0;
    newLine.expense_id = item?.id ?? null;
    this.lines.push(newLine);
    this.refresh();
  }

  setDate($event: string, element) {
    element.date = $event;
  }

  updateSelectedColumns($event) {
    const difference = this.selectedColumns
      .filter((item) => !$event.includes(item))
      .concat($event.filter((item) => !this.selectedColumns.includes(item)));
    if (this.selectedColumns.includes(difference[0])) {
      this.selectedColumns = this.selectedColumns.filter(
        (item) => item !== difference[0]
      );
    } else {
      this.selectedColumns.push(difference[0]);
    }
    this.storeSettings();
  }

  storeSettings() {
    const delIndex = this.selectedColumns.findIndex((x) => x === 'del');
    if (delIndex > -1) {
      this.selectedColumns.splice(delIndex, 1);
      this.selectedColumns.push('del');
    }
    localStorage.setItem(
      this.localstorageKey,
      JSON.stringify(this.selectedColumns)
    );
  }

  rememberSelectedCols() {
    this.makeGridCols();

    const additionalLocalStorageKey = this.isHandset ? 'mobile' : 'desktop';
    this.localstorageKey = 'grid_expenselist_' + additionalLocalStorageKey;
    const savedColumnsJSON = localStorage.getItem(this.localstorageKey);
    if (savedColumnsJSON) {
      const savedColumns = JSON.parse(savedColumnsJSON).filter(
        (x) =>
          x !== 'deactivateBulkAction' &&
          x !== 'activateBulkAction' &&
          x !== 'editBulkAction'
      );
      this.selectedColumns = [];
      for (const savedColumn of savedColumns) {
        if (this.displayedColumns.find((x) => x == savedColumn)) {
          this.selectedColumns.push(savedColumn);
        }
      }
    } else {
      this.selectedColumns = [
        'id',
        'expense_name',
        'amount',
        'date',
        'pay_method',
        'del',
      ];
    }
  }

  private makeGridCols() {
    this.gridCols.push(
      {
        key: 'id',
        desc: '#',
      },
      {
        key: 'expense_name',
        desc: this.translateService.instant('ExpenseLines.expense_name'),
      },
      {
        key: 'amount',
        desc: this.translateService.instant('ExpenseLines.amount'),
      },
      {
        key: 'date',
        desc: this.translateService.instant('ExpenseLines.date'),
      },
      {
        key: 'pay_method',
        desc: this.translateService.instant('ExpenseLines.payMethod'),
      },
      {
        key: 'extranumeric1',
        desc: this.settingsService.getSavedSettings('expenseConfNumFld1')[0]
          ? this.settingsService.getSavedSettings('expenseConfNumFld1')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 1',
      },
      {
        key: 'extranumeric2',
        desc: this.settingsService.getSavedSettings('expenseConfNumFld2')[0]
          ? this.settingsService.getSavedSettings('expenseConfNumFld2')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 2',
      },
      {
        key: 'extranumeric3',
        desc: this.settingsService.getSavedSettings('expenseConfNumFld3')[0]
          ? this.settingsService.getSavedSettings('expenseConfNumFld3')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 3',
      },
      {
        key: 'extrastr1',
        desc: this.settingsService.getSavedSettings('expenseConfStrFld1')[0]
          ? this.settingsService.getSavedSettings('expenseConfStrFld1')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 4',
      },
      {
        key: 'extrastr2',
        desc: this.settingsService.getSavedSettings('expenseConfStrFld2')[0]
          ? this.settingsService.getSavedSettings('expenseConfStrFld2')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 5',
      },
      {
        key: 'extrastr3',
        desc: this.settingsService.getSavedSettings('expenseConfStrFld3')[0]
          ? this.settingsService.getSavedSettings('expenseConfStrFld3')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 6',
      },
      {
        key: 'extraDrpDown1',
        desc: this.settingsService.getSavedSettings('expenseConfDrpDownFld1')[0]
          ? this.settingsService.getSavedSettings('expenseConfDrpDownFld1')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 7',
      },
      {
        key: 'extraDrpDown2',
        desc: this.settingsService.getSavedSettings('expenseConfDrpDownFld2')[0]
          ? this.settingsService.getSavedSettings('expenseConfDrpDownFld2')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 8',
      },
      {
        key: 'extraDrpDown3',
        desc: this.settingsService.getSavedSettings('expenseConfDrpDownFld3')[0]
          ? this.settingsService.getSavedSettings('expenseConfDrpDownFld3')[0]
              ?.value
          : this.translateService.instant('ProdConfigurator.extrFld') + ' 9',
      },
      {
        key: 'del',
        desc: this.translateService.instant('QuoteConfigurator.del'),
      }
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedColumns,
      event.previousIndex,
      event.currentIndex
    );
    this.storeSettings();
  }
}
