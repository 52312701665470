
import { ReturnData } from '../model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { TransformEntityToErp } from '../models/tranformentitytoerp';

@Injectable({
  providedIn: 'root'
})
export class TransformationService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public quoteToOrder(quoteId: number): Promise<ReturnData<any>> {
    return this.get<ReturnData<any>>(this.apiUrl + 'transfomation/quote/' + quoteId);
  }

  public leadToAccount(leadId: number): Promise<ReturnData<any>> {
    return this.get<ReturnData<any>>(this.apiUrl + 'transfomation/lead/' + leadId);
  }


 public entityToErp( transformEntityToErp: TransformEntityToErp): Promise<ReturnData<any>> {
    return this.post<ReturnData<any>>(this.apiUrl + 'transfomation/entitytoerp',transformEntityToErp);
  }


}
