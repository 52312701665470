<div class="GridDiv">
  <button mat-button [matMenuTriggerFor]="columns">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-table #notesTable class="notes-table" [dataSource]="dataSource" cdkDropList
              cdkDropListOrientation="horizontal">

    <ng-container matColumnDef="field_1">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[0].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_1"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_2">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[1].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_2"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_3">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[2].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_3"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_4">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[3].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_4"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_5">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[4].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_5"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_6">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[5].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_6"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_7">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[6].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_7"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_8">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[7].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_8"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_9">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[8].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_9"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="field_10">
      <mat-header-cell *matHeaderCellDef cdkDrag>
        <span>{{allColumns[9].desc}}</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <textarea matInput cdkTextareaAutosize type="text" [(ngModel)]="element.column_10"></textarea>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef cdkDrag> {{ "QuoteConfigurator.del" | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element;let i = index">
        <button mat-icon-button color="warn" (click)="removeLine(i)">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="selectedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: selectedColumns;">
    </mat-row>

  </mat-table>

  <button mat-raised-button color="primary" class="new-line-button" (click)="addNoteLine(null)">{{"Generic.addNewLine" | translate}}</button>
</div>


<mat-menu #columns="matMenu">

  <mat-selection-list [(ngModel)]="selectedColumns" (ngModelChange)="storeSettings()" style="max-height: 70vh;">
    <mat-list-option color="primary" *ngFor="let column of allColumns; let i = index" [value]="column.name"
                     (click)="$event.stopPropagation();">
      {{column.desc}}
    </mat-list-option>
  </mat-selection-list>
</mat-menu>
