<mat-card class="userFormCard">
  <mat-card-content>
    <div>
      <div *ngIf="thumbnail == ''">
        <mat-icon class="addBtn icon">insert_photo</mat-icon><br><br>
      </div>
      <div >
        <img class="addBtn thumbnail" *ngIf="thumbnail != ''" [src]="thumbnail" >
      </div>
      <br>
      <div>
        <button class="addBtn" type="button" [disabled]="ITEMID==='new'" mat-stroked-button (click)="dietafileInput.click()">
          <span class="material-icons">attachment</span></button>
        <input hidden #dietafileInput type="file" name="file" (change)="onSelectedFile($event.target.files)"
               accept="image/x-png,image/gif,image/jpeg">
      </div>
    </div>
  </mat-card-content>
</mat-card>
