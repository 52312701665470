import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntitySelectorDiagComponent } from 'src/app/common/entity-selector-diag/entity-selector-diag.component';
import { Field, User } from 'src/app/model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.css'],
})
export class UserFilterComponent implements OnInit, DoCheck {
  @Input() search_from: any;
  @Input() ITEM: Field;
  @Input() SHOWUSER:boolean = false
  @Output() selectUserAction = new EventEmitter<User>();

  oldId: string;
  user: User;
  constructor(private userService: UserService, public dialog: MatDialog) {}
  ngOnInit(): void {
    this.oldId = this.search_from;
  }
  ngDoCheck(): void {
    if (this.oldId != this.search_from) {
      this.oldId = this.search_from;
      if (this.search_from) {
        this.getUser();
      }
    }
  }

  getUser() {
    this.userService.getUserCommon(this.search_from).then((resUser) => {
      this.user = resUser;
    });
  }

  public selectUserDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: 'user',
      // entity_id: this.entity.id
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.user = result;
        if(this.ITEM){
          this.ITEM.search_from = result.id;
        }

        this.selectUserAction.emit(result);
      }
    });
  }
}
