
<mat-form-field style="width: 100%">
  <mat-label class="title">{{title}}<span *ngIf="ITEM.isMandatory == '1'">*</span></mat-label>
  <input matInput [matDatepicker]="dp" [formControl]="date">
  <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker #dp
                  startView="multi-year"
                  (monthSelected)="setMonthAndYear($event, dp)"
                  panelClass="example-month-picker">
  </mat-datepicker>
</mat-form-field>
