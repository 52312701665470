import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ModulesComponent } from './modules/modules.component';
import { WelcomeComponent } from './modules/welcome/welcome.component';
import { HttpClient } from '@angular/common/http';
import { GenericModule } from './generic.module';
import { RegisterInvitationComponent } from './register-invitation/register-invitation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SideMenuComponent } from './common/side-menu/side-menu.component';
import { GridComponent } from './common/grid/grid.component';
import { BreadcrumbComponent } from './common/breadcrumb/breadcrumb.component';
import { GlobalSearchComponent } from './modules/global-search/global-search.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SpinnerComponent } from './common/spinner/spinner.component';
import { CalendarModule } from 'angular-calendar';
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgOptimizedImage } from '@angular/common';
import * as Sentry from '@sentry/angular-ivy';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ModulesComponent,
    WelcomeComponent,
    RegisterInvitationComponent,
    BreadcrumbComponent,
    GlobalSearchComponent,
    SpinnerComponent,
  ],
  exports: [GridComponent, SideMenuComponent, TranslateModule],

  imports: [
    BrowserAnimationsModule,
    GenericModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      extend: true,
      isolate: false,
    }),

    AppRoutingModule,

    ServiceWorkerModule.register('/combined-sw.js', {
      enabled: environment.production,
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgOptimizedImage,
  ],

  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
