import { inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { SpinnerType } from '../common/enums';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(protected http: HttpClient) {}
  Url = environment.production
    ? window.location.protocol + '//' + window.location.hostname + '/'
    : 'http://localhost:8001/';
  apiUrl: string = this.Url + 'api/';

  private matSnackBar = inject(MatSnackBar);
  private translationService = inject(TranslateService);

  protected handleError(reason: any) {
    if (reason.status == 401) {
      localStorage.clear();

      window.location.href = '/login';
      return;
    }
    const error = reason.error.error;
    if (error) {
      this.matSnackBar.open(error, 'ok', { duration: 1500 });
    } else {
      this.matSnackBar.open(
        this.translationService.instant('Errors.generalError'),
        'ok',
        { duration: 1500 }
      );
    }
  }

  protected get<T>(
    url: string,
    spinner: SpinnerType = SpinnerType.Big
  ): Promise<T> {
    return this.http
      .get(url, this.getHeaders(spinner))
      .toPromise()
      .then((response) => {
        var data = response;
        return data as T;
      })
      .catch((x) => {
        this.handleError(x);
        return null;
      });
  }

  protected getFile<T>(
    url: string,
    spinner: SpinnerType = SpinnerType.Big
  ): Promise<T> {
    return this.http
      .get(url, this.getFileHeaders(spinner))
      .toPromise()
      .then((response) => {
        var data = response;
        return data as T;
      })
      .catch((x) => {
        this.handleError(x);
        return null;
      });
  }

  protected getWithPostFile<T>(
    url: string,
    data: any,
    spinner: SpinnerType = SpinnerType.Big
  ): Promise<T> {
    return this.http
      .post(url, data, this.getFileHeaders(spinner))
      .toPromise()
      .then((response) => {
        var data = response;
        return data as T;
      })
      .catch((x: HttpErrorResponse) => {
        this.handleError(x);
        return null;
      });
  }

  protected getView<T>(
    url: string,
    spinner: SpinnerType = SpinnerType.Big
  ): Promise<T> {
    return this.http
      .get(url, this.getViewHeaders(spinner))
      .toPromise()
      .then((response) => {
        var data = response;
        return data as T;
      })
      .catch((x) => {
        this.handleError(x);
        return null;
      });
  }

  protected postView<T>(
    url: string,
    data: any,
    spinner: SpinnerType = SpinnerType.Big
  ): Promise<T> {
    return this.http
      .post(url, data, this.getViewHeaders(spinner))
      .toPromise()
      .then((response) => {
        var data = response;
        return data as T;
      })
      .catch((x) => {
        this.handleError(x);
        return null;
      });
  }

  protected post<T>(
    url: string,
    data: any,
    spinner: SpinnerType = SpinnerType.Big
  ): Promise<T> {
    return this.http
      .post(url, data, this.getHeaders(spinner))
      .toPromise()
      .then((response) => {
        try {
          return response as T;
        } catch (e) {
          return null;
        }
      })
      .catch((x) => {
        this.handleError(x);
        return null;
      });
  }

  protected delete<T>(
    url: string,
    spinner: SpinnerType = SpinnerType.Big
  ): Promise<T> {
    return this.http
      .delete(url, this.getHeaders(spinner))
      .toPromise<any>()
      .then((response) => {
        try {
          return response;
        } catch (e) {
          return e;
        }
      })
      .catch((x) => {
        this.handleError(x);
        return null;
      });
  }

  protected put<T>(
    url: string,
    data: any,
    spinner: SpinnerType = SpinnerType.Big
  ): Promise<T> {
    return this.http
      .put(url, data, this.getHeaders(spinner))
      .toPromise()
      .then((response) => {
        try {
          return response as T;
        } catch (e) {
          return null;
        }
      })
      .catch((x) => {
        this.handleError(x);
        return null;
      });
  }

  public getHeaders(useSpinner: SpinnerType): Object {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json , text/html',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        useSpinner: useSpinner + '',
      }),
    };
    return httpOptions;
  }

  public getFileHeaders(useSpinner: SpinnerType): Object {
    const httpOptions = {
      responseType: 'blob',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        useSpinner: useSpinner + '',
      }),
    };
    return httpOptions;
  }

  public getViewHeaders(useSpinner: SpinnerType): Object {
    const httpOptions = {
      responseType: 'text',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        useSpinner: useSpinner + '',
      }),
    };
    return httpOptions;
  }

  public getEventMessage(event: HttpEvent<any>, formData) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
        break;
      case HttpEventType.Response:
        return this.apiResponse(event);
        break;
      default:
        return `File "${formData.get('profile').name}" surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }
}
