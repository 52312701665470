<div class="grid-search-bar" *ngIf="showSearch">
  <section style="display: flex; flex-wrap: wrap; align-items: center">
    <section
      [ngClass]="{
        sectFilter: !(layoutService.isHandset$ | async),
        'sectFilter-M': (layoutService.isHandset$ | async)
      }"
    >
      <mat-form-field class="fullwidth">
        <mat-label>{{ 'Generic.search' | translate }}</mat-label>
        <input
          matInput
          [(ngModel)]="FILTERS.strValue"
          (keydown.enter)="searchStr()"
        />
      </mat-form-field>
    </section>

    <section>
      <button mat-icon-button (click)="searchStr()">
        <mat-icon color="primary">search</mat-icon>
      </button>
    </section>

    <section
      *ngIf="SHOWTRASHFILTER"
      [ngClass]="{
        sectFilter: !(layoutService.isHandset$ | async),
        'sectFilter-M': (layoutService.isHandset$ | async)
      }"
    >
      <mat-form-field class="fullwidth">
        <mat-label>{{ 'Generic.status' | translate }}</mat-label>

        <mat-select [(ngModel)]="FILTERS.trashed" (ngModelChange)="searchStr()">
          <mat-option [value]="2">
            {{ 'Generic.all' | translate }}
          </mat-option>
          <mat-option [value]="0">
            {{ 'Generic.active' | translate }}
          </mat-option>
          <mat-option [value]="1">
            {{ 'Generic.deactivate' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>
  </section>
</div>

<div style="background-color: #ffffff">
  <button mat-button [matMenuTriggerFor]="columns">
    <mat-icon>more_vert</mat-icon>
  </button>
  <span *ngIf="SEARCHONCOLS">
    <mat-form-field class="full-width">
      <mat-label class="title">{{
        'Generic.chooseFilter' | translate
      }}</mat-label>
      <mat-select
        [(ngModel)]="chosenSavedFilterId"
        (ngModelChange)="applyFilter()"
        class="full-width"
      >
        <mat-option
          *ngFor="let savedFilter of savedFilters"
          [value]="savedFilter.id"
        >
          {{ savedFilter.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-icon-button
      color="warn"
      *ngIf="chosenSavedFilterId"
      (click)="clearSavedFilter()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </span>
</div>
<div
  class="mat-elevation-z1 grid-table"
  style="max-height: 3500px; overflow-y: scroll"
>
  <mat-table
    matTableExporter
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortData($event)"
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="drop($event)"
    class="grid-table"
    style="width: 99%; margin-left: auto; margin-right: auto"
  >
    <ng-container matColumnDef="checkbox">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          color="primary"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        ></mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container
      *ngFor="let column of getColumns()"
      [matColumnDef]="column.attr"
    >
      <span>
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          disableClear
          cdkDrag
          style="color: black; font-weight: bold"
        >
          <div>
            <div (click)="getSearchColumns()">{{ column.title }}</div>
          </div>
        </mat-header-cell>
      </span>

      <mat-cell mat-cell *matCellDef="let element">
        <span *ngIf="!column.type || column.type == 'data'">
          <!--          {{element[column.attr]}}-->
          {{ element[column.attr] }}
        </span>

        <span *ngIf="!column.type || column.type == 'date'">
          <span *ngIf="element[column.attr]">{{
            convertToDate(element[column.attr]) | date: 'y-M-d HH:mm'
          }}</span>
        </span>

        <span *ngIf="column.type == 'avatar'">
          <app-show-avatar [USER]="element"></app-show-avatar>
        </span>
        <span
          *ngIf="column.type == 'action'"
          (click)="$event.stopPropagation()"
        >
          <button
            mat-icon-button
            (click)="actionClicked(element, column.action, column.fldId)"
            [color]="column.color"
          >
            <mat-icon>{{ column.icon }}</mat-icon>
          </button>
        </span>
        <span
          *ngIf="column.type == 'color'"
          [style.background]="element[column.attr]"
          style="width: 12px; height: 48px"
        >
        </span>
      </mat-cell>
    </ng-container>

    <ng-container
      *ngFor="let column of getColumns()"
      [matColumnDef]="'search_' + column.attr"
    >
      <mat-header-cell
        *matHeaderCellDef
        style="color: black; font-weight: bold"
      >
        <mat-form-field
          style="width: 100px"
          *ngIf="SEARCHONCOLS && column.search"
        >
          <input
            matInput
            [(ngModel)]="column.searchValue"
            (keyup)="this.rowSearchChanged.next({ ev: $event, col: column })"
          />
        </mat-form-field>
      </mat-header-cell>
    </ng-container>

    <ng-container *ngIf="MULTISELECT" [matColumnDef]="'search_checkbox'">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="bulkactions">
      <mat-footer-cell *matFooterCellDef>
        <mat-icon style="margin-left: 5px"> subdirectory_arrow_right</mat-icon>

        <span>
          <span *ngFor="let action of getActionColumns()">
            <button
              mat-icon-button
              [color]="action.color"
              (click)="bulkActionClicked(action.action, action.fldId)"
              [disabled]="selection.isEmpty()"
            >
              <mat-icon>{{ action.icon }}</mat-icon>
            </button>
            <span *ngIf="!(layoutService.isHandset$ | async)">{{
              action.title
            }}</span>
          </span>
        </span>
      </mat-footer-cell>
    </ng-container>

    <mat-header-row
      mat-header-row
      *matHeaderRowDef="selectedColumns; sticky: true"
    ></mat-header-row>
    <span *ngIf="SEARCHONCOLS">
      <mat-header-row
        mat-header-row
        *matHeaderRowDef="getSearchColumns(); sticky: true"
      ></mat-header-row>
    </span>
    <mat-row
      mat-row
      *matRowDef="let row; columns: selectedColumns"
      (click)="rowClicked(row)"
      class="tableRow"
    >
    </mat-row>
    <span *ngIf="MULTISELECT">
      <mat-footer-row
        *matFooterRowDef="['bulkactions']"
        class="example-second-footer-row"
      ></mat-footer-row>
    </span>
  </mat-table>

  <mat-paginator
    *ngIf="ISDATAPAGED"
    [length]="ELEMENT_DATA.total"
    [pageSize]="FILTERS.pageSize"
    [pageSizeOptions]="PAGESIZEOPTIONS"
    class="paginator"
    (page)="changePage($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

<mat-menu #columns="matMenu">
  <span>
    <button mat-button (click)="toggleSaveViewDialog($event)">
      <mat-icon>save</mat-icon>
      <span> {{ 'Generic.saveview' | translate }} </span>
    </button>
  </span>

  <div
    class="save-view-dialog"
    *ngIf="showSaveViewDialog"
    (click)="$event.stopPropagation()"
  >
    <mat-form-field style="width: 100%" class="save-view-field">
      <input
        matInput
        [(ngModel)]="viewName"
        placeholder="Enter a name"
        (click)="$event.stopPropagation()"
      />
    </mat-form-field>
    <br />
    <button mat-button (click)="addSavedColumns()" color="primary">
      <mat-icon>save_as</mat-icon>
    </button>
    <button mat-button (click)="cancelSaveView()" color="warn">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <button mat-menu-item [matMenuTriggerFor]="views">Προβολές</button>

  <button mat-menu-item (click)="onExportToExcel()">
    {{ 'Generic.export' | translate }}
  </button>
  <mat-selection-list
    [ngModel]="selectedColumns"
    (ngModelChange)="updateSelectedColumns($event)"
  >
    <mat-list-option
      color="primary"
      *ngFor="let column of getColumns()"
      [value]="column.attr"
      (click)="$event.stopPropagation()"
    >
      {{ column.title }}
    </mat-list-option>
  </mat-selection-list>
</mat-menu>

<mat-menu #views="matMenu">
  <div *ngFor="let view of savedColumns" class="button-wrapper">
    <button mat-menu-item (click)="applySavedColumns(view)">
      {{ view.title }}
    </button>
    <button mat-icon-button (click)="deleteSavedColumns(view)">
      <mat-icon aria-label="Delete view">delete</mat-icon>
    </button>
  </div>
</mat-menu>
