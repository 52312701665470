import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { EntityService } from '../../services/entity.service';

@Component({
  selector: 'app-entity-selector-autocomplete',
  templateUrl: './entity-selector-autocomplete.component.html',
  styleUrls: ['./entity-selector-autocomplete.component.css'],
})
export class EntitySelectorAutocompleteComponent {
  public SHOWTABLE = false;
  @Input() LABEL: string;
  @Input() DISABLED: boolean;
  @Input() ENTITY: string;
  @Input() fieldCode: string;
  @Output() selectEntityItem = new EventEmitter<any>();

  entityService = inject(EntityService);

  public selectItem(ev) {
    this.entityService.entitySelectorFieldCode = '';
    this.selectEntityItem.emit(ev);
  }

  showPopUpList() {
    this.entityService.entitySelectorFieldCode = this.fieldCode ?? '';
    this.SHOWTABLE = true;
  }

  hidePopUpList() {
    this.entityService.entitySelectorFieldCode = '';
    this.SHOWTABLE = false;
  }
}
