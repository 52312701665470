import { TranslateService } from '@ngx-translate/core';
import { User, Invitation } from './../model';
import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-register-invitation',
  templateUrl: './register-invitation.component.html',
  styleUrls: ['./register-invitation.component.css']
})
export class RegisterInvitationComponent implements OnInit {
  isTokenValid: boolean = null;
  token: string;
  invitation: Invitation;
  user: User;
  ErrorsShow: boolean = false;
  errors: string[] = [];
  passMutch: boolean = true;
  isSuccessFullyRegister = false;

  passwordFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\\d\\x])(?=.*[!@$#%^&*()]).*$'),
    Validators.minLength(8)
  ]);
  cpasswordFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  firstnameFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  langFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  lastnameFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);

  nicknameFormControl = new UntypedFormControl('', [
    Validators.required,
  ]);
  email2FormControl = new UntypedFormControl('', [
    Validators.email,
  ]);
  departmentFormControl = new UntypedFormControl(0, [
    Validators.required,
  ]);


  hidePass = true;
  hideCPass = true;
  constructor(private route: ActivatedRoute, private userService: UserService, public translateService: TranslateService) { }

  ngOnInit(): void {



    this.token = this.route.snapshot.queryParamMap.get('token');
    if (this.token) {
      this.validateToken();
    } else {
      this.isTokenValid = false;
    }
  }







  private validateToken() {
    this.userService.validateRegisterToken(this.token).then(resVal => {
      this.isTokenValid = resVal.success;
      if (this.isTokenValid) {
        this.invitation = resVal.data;
        this.user = new User();
        this.user.lang = 'en';
        this.user.email = this.invitation.email;

      }
    });
  }


  public check() {

    let hasAnyError: boolean = false;

    if (this.nicknameFormControl.hasError('required') ||
      this.cpasswordFormControl.hasError('required') ||
      this.passwordFormControl.hasError('required') ||
      this.passwordFormControl.hasError('pattern') ||
      this.passwordFormControl.hasError('minlength') ||
      this.firstnameFormControl.hasError('required') ||
      this.lastnameFormControl.hasError('required') ||
      this.langFormControl.hasError('required') ||

      this.departmentFormControl.hasError('required') ||
      this.user.password !== this.user.cpassword) {
      hasAnyError = true;

      this.passMutch = this.user.password === this.user.cpassword;
      if (!this.passMutch) {
        this.cpasswordFormControl.setErrors({ 'incorrect': true });
        this.passwordFormControl.setErrors({ 'incorrect': true });
      }



      this.nicknameFormControl.markAllAsTouched();
      this.cpasswordFormControl.markAllAsTouched();
      this.passwordFormControl.markAllAsTouched();
      this.firstnameFormControl.markAllAsTouched();
      this.lastnameFormControl.markAllAsTouched();
      this.langFormControl.markAllAsTouched();

      this.departmentFormControl.markAllAsTouched();
      this.email2FormControl.markAllAsTouched();

    }
    if (!hasAnyError) {
      this.userService.newUserFromInvitation(this.token, this.user).then(resNewUser => {
        if (resNewUser.success) {
          this.isSuccessFullyRegister = true
          this.ErrorsShow = false;
          this.errors = [];
        } else {
          this.errors = [];
          for (var k in resNewUser.error as any) {
            for (let msg of resNewUser.error[k]) {
              this.errors.push(msg);
            }
          }
          this.ErrorsShow = true;
        }

      });
    }

  }

  public changeLang(ev) {

    this.translateService.setDefaultLang(ev);
  }
}
