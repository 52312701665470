import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  CalendarDayViewBeforeRenderEvent,
  CalendarEvent,
  CalendarEventAction,
  CalendarMonthViewBeforeRenderEvent,
  CalendarView,
  CalendarWeekViewBeforeRenderEvent,
} from 'angular-calendar';
import { addHours, isSameDay, isSameMonth, isEqual } from 'date-fns';
import { Subject, Subscription } from 'rxjs';
import { CalendarService } from '../../../../../services/calendar.service';
import {
  CalendarAbsence,
  CalendarEntityEvent,
  CalendarShift,
  CalendarTask,
  CalendarMeeting,
  CalendarProductionOrder,
} from '../../../../../model';
import { ViewPeriod } from 'calendar-utils';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataItemDiagComponent } from '../../../data-item-diag/data-item-diag.component';
import { CalendarEventComponent } from './calendar-event/calendar-event.component';
import { User } from '../../../../../model';
import { LoginService } from '../../../../../services/login.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../../services/notification.service';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.css'],
})
export class CalendarViewComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  constructor(
    private calendarService: CalendarService,
    private changeDetector: ChangeDetectorRef,
    private loginService: LoginService,
    private translateService: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private notificationService: NotificationService,
    public dialog: MatDialog
  ) {}

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  viewPeriod: ViewPeriod;
  eventsFetched = false;
  activeDayIsOpen = false;
  refresh = new Subject<boolean>();
  events: CalendarEvent[] = [];
  tasks: CalendarTask[] = [];
  shifts: CalendarShift[] = [];
  absences: CalendarAbsence[] = [];
  meetings: CalendarMeeting[] = [];
  productionOrders: CalendarProductionOrder[] = [];
  calendarEntityEvents: CalendarEntityEvent[] = [];
  env = [];
  titleTabs = 3;
  titleRowHeight = '150px';
  currentUser: User;
  @Input() ITEM: any;
  @Input() ITEMID: any;
  private ObserverSubscription: Subscription;
  private notificationSubscription: Subscription;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="material-icons mat-icon">edit</i>',
      cssClass: 'action-icon',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.editEvent(event, false);
      },
    },
    {
      label: '<i class="material-icons mat-icon">delete</i>',
      cssClass: 'action-icon',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.deleteEvent(event);
      },
    },
  ];

  ngOnInit(): void {
    this.observeBreakpoints();
    this.currentUser = this.loginService.getLoginUser();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.ObserverSubscription.unsubscribe();
    this.notificationSubscription.unsubscribe();
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = !(
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      );
      this.viewDate = date;
    }
  }

  onEventClicked(event: CalendarEvent): void {
    if (event.meta.type !== 'event') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      if (event.meta.type === 'task') {
        dialogConfig.data = { itemId: event.id, entityCode: 'tasks' };
      } else if (event.meta.type === 'shift') {
        dialogConfig.data = { itemId: event.id, entityCode: 'shift' };
      } else if (event.meta.type === 'meetings') {
        dialogConfig.data = { itemId: event.id, entityCode: 'meetings' };
      } else if (event.meta.type === 'productionOrder') {
        dialogConfig.data = { itemId: event.id, entityCode: 'productionorder' };
      } else {
        dialogConfig.data = { itemId: event.id, entityCode: 'absence' };
      }

      this.dialog.open(DataItemDiagComponent, dialogConfig);
    } else {
      this.editEvent(event, true);
    }
  }

  updateCalendarEvents(
    viewRender:
      | CalendarMonthViewBeforeRenderEvent
      | CalendarWeekViewBeforeRenderEvent
      | CalendarDayViewBeforeRenderEvent
  ): void {
    console.log(this.viewPeriod);
    console.log(viewRender.period.start);
    console.log(viewRender.period.end);
    if (
      !this.viewPeriod ||
      !isEqual(this.viewPeriod.start, viewRender.period.start) ||
      !isEqual(this.viewPeriod.end, viewRender.period.end)
    ) {
      this.viewPeriod = viewRender.period;
      this.createCalendarEvents();
    }
  }

  public createCalendarEvents() {
    this.events = [];
    if (!this.eventsFetched && this.ITEMID !== 'new') {
      this.calendarService.getCalendarTasks(this.ITEMID).then((tasks) => {
        this.tasks = tasks;
        this.createCalendarEventsFromTasks();
        this.calendarService.getCalendarShifts(this.ITEMID).then((shifts) => {
          this.shifts = shifts;
          this.createCalendarEventsFromShifts();
          this.calendarService
            .getCalendarAbsences(this.ITEMID)
            .then((absences) => {
              this.absences = absences;
              this.createCalendarEventsFromAbsences();
              this.calendarService
                .getCalendarMeetings(this.ITEMID)
                .then((meetings) => {
                  this.meetings = meetings;
                  this.createCalendarEventsFromMeetings();
                  this.calendarService
                    .getCalendarProductionOrders(this.ITEMID)
                    .then((productionOrders) => {
                      this.productionOrders = productionOrders;
                      this.createCalendarProductionOrders();
                      this.calendarService
                        .getCalendarEvents(this.ITEMID)
                        .then((events) => {
                          this.calendarEntityEvents = events;
                          this.eventsFetched = true;
                          this.createCalendarEventsFromEvents();
                        });
                    });
                });
            });
        });
      });
    } else {
      this.createCalendarEventsFromTasks();
      this.createCalendarEventsFromShifts();
      this.createCalendarEventsFromMeetings();
      this.createCalendarEventsFromAbsences();
      this.createCalendarEventsFromEvents();
      this.createCalendarProductionOrders();
      this.refresh.next(true);
      this.changeDetector.detectChanges();
    }
  }

  public createCalendarEventsFromTasks() {
    this.tasks.forEach((task) => {
      if (task.start_date != null) {
        const simpleTask = {
          id: task.id,
          start: this.formatDate(task.start_date),
          end: this.formatDate(task.end_date),
          title: `${this.translateService.instant('Calendar.task')}: ${task.description} (${task.status})`,
          meta: {
            type: 'task',
          },
        };
        this.events.push(simpleTask);
      }
    });
    this.refresh.next(true);
    this.changeDetector.detectChanges();
  }

  public createCalendarEventsFromShifts() {
    this.shifts.forEach((shift) => {
      const calendarShift = {
        id: shift.id,
        start: this.formatDate(shift.start_date),
        end: addHours(this.formatDate(shift.start_date), shift.duration),
        title: `${this.translateService.instant('Calendar.shift')}: ${shift.description}`,
        color: { primary: '#21ad21', secondary: '#E3FAE3' },
        meta: {
          type: 'shift',
        },
      };
      this.events.push(calendarShift);
    });
    this.refresh.next(true);
    this.changeDetector.detectChanges();
  }

  public createCalendarEventsFromAbsences() {
    this.absences.forEach((absence) => {
      const calendarAbsence = {
        id: absence.id,
        start: this.formatDate(absence.start_date),
        end: this.formatDate(absence.end_date),
        title: `${this.translateService.instant('Calendar.absence')}: ${absence.employee_firstname}
        ${absence.employee_lastname} - ${absence.description}`,
        color: { primary: '#e3bc08', secondary: '#FDF1BA' },
        meta: {
          type: 'absence',
        },
      };
      this.events.push(calendarAbsence);
    });
    this.refresh.next(true);
    this.changeDetector.detectChanges();
  }

  public createCalendarEventsFromMeetings() {
    this.meetings.forEach((meeting) => {
      const calendarMeetings = {
        id: meeting.id,
        start: this.formatDate(meeting.start_date),
        end: addHours(this.formatDate(meeting.start_date), meeting.duration),
        title: `${this.translateService.instant('Calendar.meeting')}: ${meeting.description}`,
        color: { primary: '#9321ad', secondary: '#efe1f2' },
        meta: {
          type: 'meetings',
        },
      };
      this.events.push(calendarMeetings);
    });
    this.refresh.next(true);
    this.changeDetector.detectChanges();
  }

  private createCalendarProductionOrders() {
    this.productionOrders.forEach((productionOrder) => {
      const calendarProductionOrders = {
        id: productionOrder.id,
        start: this.formatDate(productionOrder.start_date),
        end: this.formatDate(productionOrder.end_date),
        title: `${this.translateService.instant('Calendar.prodOrder')}:
         ${productionOrder.product_name ?? productionOrder.id} (${productionOrder.status})`,
        color: { primary: '#ff5733', secondary: '#ffe5b4' },
        meta: {
          type: 'productionOrder',
        },
      };
      this.events.push(calendarProductionOrders);
    });
    this.refresh.next(true);
    this.changeDetector.detectChanges();
  }

  private createCalendarEventsFromEvents() {
    this.calendarEntityEvents.forEach((entityEvent) => {
      console.log('create calendar events');
      console.log(entityEvent.start_date);
      console.log(entityEvent.end_date);
      const simpleEntityEvent = {
        id: entityEvent.id,
        start: utcToZonedTime(entityEvent.start_date, 'Europe/Athens'),
        end: utcToZonedTime(entityEvent.end_date, 'Europe/Athens'),
        title: `${this.translateService.instant('Calendar.event')}: ${entityEvent.title}`,
        color: { primary: '#ad2121', secondary: '#FAE3E3' },
        actions:
          this.currentUser.id === Number(entityEvent.owner_id)
            ? this.actions
            : null,
        meta: {
          type: 'event',
          description: entityEvent.description,
          start_date: entityEvent.start_date,
          end_date: entityEvent.end_date,
          calendar_id: entityEvent.calendar_id,
          participants: entityEvent.calendar_event_participants,
        },
      };
      this.events.push(simpleEntityEvent);
    });
    this.refresh.next(true);
    this.changeDetector.detectChanges();

    // Open Event Notification
    this.notificationSubscription =
      this.notificationService.openCalendarEvent.subscribe((res) => {
        if (res) {
          const event = this.events.find(
            (x) => x.id === res && x.meta.type === 'event'
          );
          this.editEvent(event, true);
          this.notificationService.openCalendarEvent.next(null);
        }
      });
  }

  public formatDate(date: string) {
    const s = date.split(' ')[0]?.split('-');
    const m = date.includes('T')
      ? date?.split('T')[1]?.split(':')
      : date?.split(' ')[1]?.split(':');
    return new Date(
      parseInt(s[0], 10),
      parseInt(s[1], 10) - 1,
      parseInt(s[2], 10),
      parseInt(m[0], 10),
      parseInt(m[1], 10),
      0,
      0
    );
  }

  public editEvent(event: CalendarEvent<any>, disable: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      event,
      calendar_id: this.ITEMID,
      disable,
    };

    const dialogRef = this.dialog.open(CalendarEventComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.eventsFetched = false;
        this.createCalendarEvents();
      }
    });
  }

  public deleteEvent(event: CalendarEvent<any>) {
    this.calendarService
      .deleteCalendarEvent(this.ITEMID, event.id)
      .then((res) => {
        this.eventsFetched = false;
        this.createCalendarEvents();
      });
  }

  public observeBreakpoints() {
    this.ObserverSubscription = this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.HandsetLandscape,
      ])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.titleTabs = 1;
          this.titleRowHeight = '50px';
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.titleTabs = 1;
          this.titleRowHeight = '50px';
        } else if (result.breakpoints[Breakpoints.Medium]) {
          this.titleTabs = 3;
          this.titleRowHeight = '150px';
        } else if (result.breakpoints[Breakpoints.Large]) {
          this.titleTabs = 3;
          this.titleRowHeight = '150px';
        } else if (result.breakpoints[Breakpoints.XLarge]) {
          this.titleTabs = 3;
          this.titleRowHeight = '150px';
        }
      });
  }
}
