<div class="pdf-viewer">
  <div *ngIf="showPDFViewer">
<!--    <ngx-extended-pdf-viewer [src]="pdfSrc"></ngx-extended-pdf-viewer>-->
    <iframe [src]="pdfSrc" width="100%" class="iframe"></iframe>
  </div>
  <div *ngIf="imgSrc!==null" style="display: flex; justify-content: center">
    <div>
      <img [src]="imgSrc" alt="document image" width="1000">
    </div>
  </div>
</div>
