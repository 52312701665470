import { TranslateService } from '@ngx-translate/core';
import { ChatPopup, Entity, NotifData, Notify } from '../model';
import { UserService } from 'src/app/services/user.service';
import { WebsocketsService } from '../services/websockets.service';
import { NotificationService } from '../services/notification.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../services/login.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { User } from '../model';
import { ChatService } from '../services/chat.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DataItemDiagComponent } from '../common/entity/data-item-diag/data-item-diag.component';
import { SpinnerType } from '../common/enums';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { SettingsService } from '../services/settings.service';
import { Platform } from '@angular/cdk/platform';
import { PwaService } from '../services/pwa.service';
import { EntityMenuSelectorComponent } from '../common/entity-menu-selector/entity-menu-selector.component';
import { DashboardService } from '../services/dashboard.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { EntityService } from '../services/entity.service';
import { CompanyService } from '../services/company.service';
import { MatSelectionList } from '@angular/material/list';
import { SelectEndataForWorkflowDialogComponent } from './workflows/select-endata-for-workflow-dialog/select-endata-for-workflow-dialog.component';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css'],
})
export class ModulesComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('questionsTrigger') questionsMenuTrigger: MatMenuTrigger;
  @ViewChild('notificationsTrigger') notificationsMenuTrigger: MatMenuTrigger;
  @ViewChild(EntityMenuSelectorComponent)
  entityMenuSelectorComponent: EntityMenuSelectorComponent;
  @ViewChild('favoriteSelectionList') favoriteSelectionList: MatSelectionList;

  currentUser: User;
  public globalsearchStr = '';

  constructor(
    public loginService: LoginService,
    public notificationService: NotificationService,
    private router: Router,
    public webSocket: WebsocketsService,
    private userService: UserService,
    public chatService: ChatService,
    private dialog: MatDialog,
    private sockets: WebsocketsService,
    private breakpointObserver: BreakpointObserver,
    private afMessaging: AngularFireMessaging,
    public translateService: TranslateService,
    private settingsService: SettingsService,
    public platform: Platform,
    public pwaService: PwaService,
    public dashboardService: DashboardService,
    public entityService: EntityService,
    public companyService: CompanyService
  ) {}

  ngOnInit(): void {
    this.getModulesAndEntities();
    this.webSocket.setup();
    this.currentUser = this.loginService.getLoginUser() as User;
    this.newPopupChatroomController();

    this.requestPushNotificationsPermission();
    this.settingsService.getAllSettings();

    this.dashboardService.openNotifications.subscribe((open) => {
      if (open) {
        this.notificationsMenuTrigger.openMenu();
      }
    });
    this.dashboardService.openQuestions.subscribe((open) => {
      if (open) {
        this.questionsMenuTrigger.openMenu();
      }
    });
    this.listenForEntityChoice();

    // Add event listener for detecting when the app returns from minimization
    document.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange.bind(this)
    );
  }

  public logout() {
    this.loginService.logout();
  }

  public close() {
    this.sidenav.close();
  }
  public openModule(link: string) {
    this.router.navigate([link]);
  }

  public hasModulePermission(scope: string): boolean {
    return this.loginService.hasScopePermission(scope);
  }

  public closeChatPopup(chatPopupIndex: number) {
    this.chatService.chatPopups.splice(chatPopupIndex, 1);
  }

  public openItem(itemId: string, entityCode: string) {
    if (entityCode === 'approval') {
      this.notificationService.openApproval.next(Number(itemId));
      this.router.navigate(['/approval']);
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
        itemId: itemId.toString(),
        entityCode: entityCode,
      };

      const dialogRef = this.dialog.open(DataItemDiagComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: any) => {});
    }
  }

  openEvent(notifData: NotifData) {
    this.notificationService.openCalendarEvent.next(notifData.non_entity_id);
    this.openItem(notifData.entdata.toString(), notifData.entity_code);
  }

  public globalSearch() {
    if (this.globalsearchStr !== '') {
      this.router.navigate(['search', { str: this.globalsearchStr }]);
    }
  }

  private newPopupChatroomController() {
    if (
      !this.loginService.hasScopePermission('user-chat') ||
      this.router.url == '/chat'
    ) {
      return;
    }
    this.sockets.subject.subscribe((newMsgLive) => {
      if (
        this.chatService.chatPopups.findIndex(
          (x) => x.userForPopup.id == newMsgLive.chatMsg.user_from
        ) < 0
      ) {
        this.userService
          .getUserCommon(newMsgLive.chatMsg.user_from, SpinnerType.None)
          .then((resUser) => {
            window.innerWidth < 800 ? (this.chatService.chatPopups = []) : '';

            let chatPopup: ChatPopup = new ChatPopup();
            chatPopup.userForPopup = resUser;
            chatPopup.isChatMinimized = false;
            this.chatService.chatPopups.push(chatPopup);
          });
      }
    });
  }

  requestPushNotificationsPermissionIOS() {
    this.afMessaging.requestToken // getting tokens
      .subscribe(
        (token) => {
          // USER-REQUESTED-TOKEN
          console.log('Permission granted! Save to the server!');
          this.userService.subscribeNotifications(token);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  private requestPushNotificationsPermission() {
    // requesting permission
    this.afMessaging.requestToken // getting tokens
      .subscribe(
        (token) => {
          // USER-REQUESTED-TOKEN
          console.log('Permission granted! Save to the server!');
          this.userService.subscribeNotifications(token);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  public getEntityTranslate(entity: Entity): string {
    return this.loginService.getLoginUser().lang === 'en'
      ? entity.name_en
      : entity.name_gr;
  }

  public getFieldTranslate(fld): string {
    return this.loginService.getLoginUser().lang === 'en'
      ? fld.label_en
      : fld.label_gr;
  }

  public getTrancatedMsg(msg: string): string {
    return msg.length > 250 ? msg.substring(0, 250) + '...' : msg;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  handleVisibilityChange() {
    if (
      document.visibilityState === 'visible' &&
      (this.platform.SAFARI || this.platform.IOS)
    ) {
      console.log(this.webSocket);
      this.webSocket.getNotifications();
    }
  }

  private getModulesAndEntities() {
    this.companyService.getModules().then((resModule) => {
      this.companyService.teamworkModules.next(resModule);
      this.companyService.getUserAuthorizedModules();
      this.companyService.getUserFavoriteModules();
    });
    this.entityService.getEntityList().then((res) => {
      this.entityService.storeEntities(res);
    });
  }

  saveFavorites() {
    this.companyService.saveUserFavoriteModules();
  }

  chooseEntdataFromEntity(notification: Notify) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityChoice: notification.data.entityChoice,
      workflowStepId: notification.data.workflowStepId,
    };

    const dialogRef = this.dialog.open(
      SelectEndataForWorkflowDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'success') {
        this.webSocket.readNotification(notification);
      }
    });
  }

  private listenForEntityChoice() {
    this.notificationService.openChooseEntityComponent.subscribe(
      (notification: Notify) => {
        if (notification) {
          this.chooseEntdataFromEntity(notification);
        }
      }
    );
  }
}
