import { ApprovalDecision } from "../common/enums";
import { User } from "../model";

export class Approval {

  constructor() {
     this.assignees = [];
  }
  id: number;
  message: string;

  entdata_id: number;
  entdata_label: string;

  applicant_id: number;
  decision: ApprovalDecision;
  decisionStr: string;
  cuserDecision:ApprovalDecision;
  cuserDecisionStr:string;
  deleted_at: Date;

  applicant: User;
  applicant_label: string;

  assignees: Assignee[];

  created_at: Date;
  updated_at: Date;

}



export class Assignee {
  id: number;

  approval_id: number;
  approval: Approval;

  message: string;

  assignee_id: number;
  assignee: User;


  orderId: number;
  previus_id: number;
  next_id: number;
  decision: ApprovalDecision;

  decided_at: Date;
  created_at: Date;
  updated_at: Date;

}

