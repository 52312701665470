import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReturnData, Sprint } from '../model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public addSprint(sprint: Sprint): Promise<ReturnData<Sprint>> {
    return this.post<ReturnData<Sprint>>(this.apiUrl + 'projects/sprints', sprint);
  }

  public delSprint(sprint_id: number): Promise<ReturnData<Sprint[]>> {
    return this.delete<ReturnData<Sprint[]>>(this.apiUrl + 'projects/sprints/' + sprint_id);
  }

  public getSprints(project_id: string): Promise<Sprint[]> {
    return this.get<Sprint[]>(this.apiUrl + 'projects/sprints/' + project_id);
  }

  public addTaskToSprint(project_id: number, sprint: Sprint, task: any): Promise<ReturnData<Sprint[]>> {
    let obj = {
      project_id: project_id,
      sprint_id: sprint.id,
      task_id: task.id
    };
    return this.post<ReturnData<Sprint[]>>(this.apiUrl + 'projects/sprints/task', obj);
  }

  public copyProject(projectObj:any): Promise<ReturnData<any>> {
    return this.post<ReturnData<any>>(this.apiUrl + 'projects/duplicate' , projectObj);
  }

}
