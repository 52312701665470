import { TranslateService } from '@ngx-translate/core'
import { NotificationService } from 'src/app/services/notification.service'
import { LoginService } from 'src/app/services/login.service'
import { EntityService } from 'src/app/services/entity.service'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { EntityItemFilter } from 'src/app/common/filters'
import { ConnectedEntity, Entconnection, Entity } from 'src/app/model'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { DataItemDiagComponent } from '../../data-item-diag/data-item-diag.component'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-data-connected-items',
  templateUrl: './data-connected-items.component.html',
  styleUrls: ['./data-connected-items.component.css'],
})
export class DataConnectedItemsComponent implements OnInit {
  entityFilters: EntityItemFilter = new EntityItemFilter()
  items: any[] = []
  @Input() ENTITY: ConnectedEntity
  @Output() REFRESH = new EventEmitter<any>()
  @Input() ENTID: number
  constructor(
    private entityService: EntityService,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    console.log('data connected items')
    this.entityFilters.pageSize = 2000000
    this.entityFilters.trashed = 2
    this.entityFilters.connectedWith = this.ENTID
    this.entityService
      .getEntityDataList(this.entityFilters, this.ENTITY.entity_code)
      .then((dataRes) => {
        this.items = dataRes.data
      })
  }

  public getOnlyMbbileFlds(item): any[] {
    return item.fields.filter((x) => x.mobilePreview == '1')
  }

  public getEntityNameTranslate(entity: Entity): string {
    let lang = this.loginService.getLoginUser().lang

    return lang == 'en' ? entity.name_en : entity.name_gr
  }

  public unlinkEntityItem(item: any) {
    let conToDel = new Entconnection()
    conToDel.entdata_id = +this.ENTID
    conToDel.with_entdata_ids = [item.id]
    this.entityService.removeEntConnection(conToDel).then((res) => {
      if (res.success) {
        this.notificationService.showSnackbarMessage(
          'Messages.successDisconnection',
          'Ok',
          { duration: 3000 }
        )
        this.REFRESH.emit()
      } else {
        this.notificationService.showSnackbarMessage(
          'Messages.failDisconnection',
          'Ok',
          { duration: 3000 }
        )
      }
    })
  }

  public previewLinkItem(item: any) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.disableClose = true
    dialogConfig.autoFocus = true

    dialogConfig.data = {
      itemId: item.id,
      entityCode: this.ENTITY.entity_code,
    }
    this.dialog.open(DataItemDiagComponent, dialogConfig)
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches))
}
