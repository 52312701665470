<div class="buttonPanel mat-elevation-z3">
  <button mat-button [mat-dialog-close]="null">
    <mat-icon>keyboard_backspace</mat-icon>
    <span *ngIf="!(layoutService.isHandset$ | async)">{{
      'Generic.back' | translate
    }}</span>
  </button>
  <mat-divider [vertical]="true"></mat-divider>
</div>

<div mat-dialog-content>
  <div>
    <app-grid
      *ngIf="gridColumns"
      [ID]="'erp_warehouse_product_history'"
      [ELEMENT_DATA]="lines"
      [ATTR]="gridColumns"
      [FILTERS]="filters"
      (getData)="refreshList($event)"
      [showSearch]="false"
      [SHOWTRASHFILTER]="false"
      [PAGESIZEOPTIONS]="[2, 20, 50, 100]"
    >
    </app-grid>
  </div>
</div>
