import { inject, Pipe, PipeTransform } from '@angular/core';
import { LoginService } from '../services/login.service';

@Pipe({
  name: 'hideFileHistoryButton',
})
export class HideFileHistoryButtonPipe implements PipeTransform {
  loginService = inject(LoginService);

  transform(owner: any, field: any): boolean {
    if (field.fld_code === 'filehistory') {
      return !(owner && this.loginService.getLoginUser().id !== owner.id);
    } else if (field.fld_code === 'file_history_no_security') {
      return true;
    }
  }
}
