import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sprint } from 'src/app/model';

@Component({
  selector: 'app-spring-diag',
  templateUrl: './spring-diag.component.html',
  styleUrls: ['./spring-diag.component.css']
})
export class SpringDiagComponent implements OnInit {
  sprint: Sprint;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.sprint = this.data.sprint;
    if (this.sprint == null) {
      this.sprint = new Sprint();
      this.sprint.name = '';
      this.sprint.project_id = this.data.project_id;
    }
  }

}
