import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-data-replicate',
  templateUrl: './data-replicate.component.html',
  styleUrls: ['./data-replicate.component.css']
})
export class DataReplicateComponent implements OnInit {
  public amount: number = 1;

  constructor(public dialogRef: MatDialogRef<DataReplicateComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {

  }
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
}
