import { Component } from '@angular/core'
import { appVersion } from '../../../../../app-version'
import { LoginService } from '../../../services/login.service'
import { Router } from '@angular/router'
import { CompanyService } from '../../../services/company.service'
import { EntityService } from '../../../services/entity.service'
import { TranslateService } from '@ngx-translate/core'
import { DashboardService } from '../../../services/dashboard.service'

@Component({
  selector: 'app-sidebar-content',
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.css'],
})
export class SidebarContentComponent {
  protected readonly appVersion = appVersion

  language = ''

  constructor(
    private loginService: LoginService,
    private router: Router,
    public companyService: CompanyService,
    public entityService: EntityService,
    public translateService: TranslateService,
    public dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.language = this.loginService.getLoginUser().lang
  }

  public openModule(link: string) {
    this.router.navigate([link])
  }
}
