import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerType } from '../common/enums';
import { Question, ReturnData } from '../model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }


  public answerQuestion(question: Question): Promise<ReturnData<any>> {
    return this.delete<ReturnData<any>>(this.apiUrl + 'entity/questions/read/' + question.id, SpinnerType.None);
  }


  public getQuestions(): Promise<Question[]> {
    return this.get<Question[]>(this.apiUrl + 'questions');
  }

  public postQuestions(questions:Question[]): Promise<ReturnData<Question[]>> {
    return this.post<ReturnData<Question[]>>(this.apiUrl + 'questions',questions);
  }

}
