<div class="roomCard">
  <div  *ngIf="!selectedConversation">
    <h1 class="mat-headline-4">Select a conversation from the left to start chatting</h1>
  </div>
  <div *ngIf="selectedConversation" #msgList  class="area"
  [class.areaM]="(isHandset$ | async)" [class.area]="!(isHandset$ | async)">
    <mat-spinner *ngIf="showLoader" style="left: 50%; margin-top:25%; margin-left: -4em; position: absolute;"></mat-spinner>
    <mat-list  class="conversation-history"

    [class.listForPopup]="isPopup" [class.listForModule]="!isPopup">
      <mat-list-item style="margin: 10px; height: fit-content;" *ngFor="let chat of chatHistory" [dir]="isMessageMine(chat) ? 'rtl' : 'ltr'">
        <p matLine class="msg" [class.outcomingMsg]="isMessageMine(chat)" [class.incomingMsg]="!isMessageMine(chat)"
          [dir]="'ltr'" ItemLink><span>{{chat.message}}</span></p>
        <span matLine style="font-size: 8px;color: grey;">{{chat.created_at | date:'medium'}} </span>
      </mat-list-item>
    </mat-list>

    <div class="conversation-input">
      <mat-divider></mat-divider>
      <mat-form-field class="full-width">
        <mat-label>{{'Chat.typeMessage' | translate}}</mat-label>
        <input matInput  (keydown.enter)="sendMessage()"  name="text" [(ngModel)]="text">
        <mat-icon color="primary" matSuffix (click)="sendMessage()">send</mat-icon>
      </mat-form-field>
    </div>
  </div>

</div>
