<div *ngIf="(isHandset$ | async)" style="height: 60px;"></div>
<div *ngIf="searchCounters.length > 0" style="display: flex;flex-wrap: wrap;">
  <mat-card *ngFor="let searchCounter of searchCounters" style="min-width:120px;height:120px;margin: 10px;cursor: pointer;"
    [class.background-primary]="selectedEnity && selectedEnity.entity_code == searchCounter.entity.entity_code"
    [class.whitecolor]="selectedEnity && selectedEnity.entity_code == searchCounter.entity.entity_code"
    (click)="showEntityResults(searchCounter.entity)">

    <div class="innerNumDiv">
      <div class="resNum">
        {{searchCounter.find_counter}}</div>
      <mat-divider [vertical]="true" style="margin: 5px;"></mat-divider>
      <div style="margin-top: 45px;width: 100%;"> {{searchCounter.entity.name_gr}}</div>
    </div>
  </mat-card>

</div>
<div style="width: 100%;" *ngIf="searchCounters.length==0">

  <div class="outerNoResult" >
    <div class="noresult">{{'welcome.noResults' | translate}}</div>
  </div>
</div>




<div>
  <mat-divider style="margin: 5px;"></mat-divider>
</div>
<div *ngFor="let searchCounter of searchCounters">

  <app-data-list #grid *ngIf="selectedEnity && selectedEnity.entity_code == searchCounter.entity.entity_code"
    [ENTITY]="searchCounter.entity.entity_code" [entityFilters]="entityFilters" [SHOWSEARCH]="false"
    [SHOWTRASHFILTER]="false" (selectEntityItem)="previewItem($event,selectedEnity.entity_code)"></app-data-list>
</div>
