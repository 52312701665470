import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
})
export class CheckboxComponent implements OnInit {
  @Input() ITEM: any;
  @Input() LABEL: string;
  @Input() disabled: boolean = false;
  public checkBool: boolean;
  constructor() { }

  ngOnInit(): void {
    if (this.ITEM.value && this.ITEM.value == 'true') {
      this.checkBool = true;
    } else {
      this.checkBool = false;
    }
  }
  change(flag) {
    this.ITEM.value = flag.checked;
  }
}
