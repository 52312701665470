<div class="buttonPanel mat-elevation-z3">
  <span>
    <button mat-button [mat-dialog-close]="null">
      <mat-icon>keyboard_backspace</mat-icon>
      <span *ngIf="!(isHandset$ | async)">{{
        'Generic.back' | translate
      }}</span>
    </button>
  </span>
  <mat-divider [vertical]="true"></mat-divider>

  <span>
    <button
      mat-button
      (click)="save()"
      [disabled]="!receiver || getSelectedAttrs().length == 0"
    >
      <mat-icon>save</mat-icon>
      <span *ngIf="!(isHandset$ | async)">{{
        'Generic.save' | translate
      }}</span>
    </button>
  </span>
  <mat-divider [vertical]="true"></mat-divider>
</div>

<div mat-dialog-content class="content">
  <table>
    <tr>
      <td>
        <mat-label
          ><strong>{{ 'Generic.user' | translate }}</strong></mat-label
        >
        <button mat-icon-button (click)="selectUserDiag()">
          <mat-icon color="primary">person_search</mat-icon>
        </button>

        <div class="flexdiv">
          <div *ngIf="receiver" class="flexdiv">
            <span>
              <app-show-avatar [USER]="receiver"></app-show-avatar>
            </span>
            <span
              class="text-alternate-warn"
              style="margin-left: 15px; margin-top: 8px"
              >{{ receiver.firstname }} {{ receiver.lastname }}</span
            >
          </div>
        </div>
      </td>
    </tr>

    <tr>
      <td>
        <mat-label
          ><strong>{{ 'Generic.message' | translate }}</strong></mat-label
        >
        <mat-form-field class="full-width" style="color: black">
          <mat-label class="title">{{
            'Generic.message' | translate
          }}</mat-label>
          <input matInput type="text" [(ngModel)]="message" />
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td>
        <mat-label
          ><strong>{{ 'Generic.fields' | translate }}</strong>
        </mat-label>

        <div
          *ngFor="let attr of getOnlyQuestionableAttrs()"
          class="attributeDiv"
        >
          <div>
            <mat-checkbox
              class="example-margin"
              color="primary"
              [(ngModel)]="attr.changeAttr"
            >
              <span> {{ attr | translateFieldLabel }} </span>
            </mat-checkbox>
          </div>
        </div>
      </td>
    </tr>
  </table>

  <div></div>
</div>
