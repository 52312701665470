import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  public promptEvent: any;

  constructor(
    private bottomSheet: MatBottomSheet,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private translateService: TranslateService
  ) {

    swUpdate.available.subscribe(event => {
      if (this.askUserToUpdate()) {
        window.location.reload();
      }
    });
    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
    });


  }

  private askUserToUpdate(): boolean {
    return confirm(this.translateService.instant('welcome.updatePwa'));
  }


  public installPwa(): void {
    this.promptEvent.prompt();
  }

}
