import { Entdata, Sprint, TaskDto, Taskgroup } from '../model';
import { BaseFilter } from './filters';
import {Platform} from "@angular/cdk/platform";
import {NativeDateAdapter} from "@angular/material/core";


export class DtoConverter {

  public EntdataToTaskDto(item: Entdata): TaskDto {
    let task: TaskDto = new TaskDto();

    task.id = item.id;

    let assigneeIndex = item.fileds.findIndex(x => x.field.fld_code === 'task_assignee');

    task.task_assignee = item.fileds[assigneeIndex]['value'];
    task.task_desc = item.fileds[item.fileds.findIndex(x => x.field.fld_code === 'task_desc')].value;
    task.task_duration = item.fileds[item.fileds.findIndex(x => x.field.fld_code === 'task_duration')].value;
    task.task_group = item['task_group'] as Taskgroup;
    task.task_childtask = +item.fileds[item.fileds.findIndex(x => x.field.fld_code === 'task_childtask')].value;
    let startdateStr: string = item.fileds[item.fileds.findIndex(x => x.field.fld_code === 'task_startdate')].value;

    if (startdateStr) {

      var s = startdateStr?.split(" ")[0]?.split("-");

      var m = (startdateStr.includes("T")) ? startdateStr?.split("T")[1]?.split(":") : startdateStr?.split(" ")[1]?.split(":");
      var d = new Date(parseInt(s[0]), parseInt(s[1]) - 1, parseInt(s[2]), parseInt(m[0]), parseInt(m[1]), 0, 0);
      task.task_startdate = d;
    } else {
      task.task_startdate = null;
    }

    let enddateStr: string = item.fileds[item.fileds.findIndex(x => x.field.fld_code === 'task_enddate')].value;

    if (enddateStr) {
      var s = enddateStr.split(" ")[0].split("-");
      var m = startdateStr.split(" ")[1].split(":")
      var d = new Date(parseInt(s[0]), parseInt(s[1]) - 1, parseInt(s[2]), parseInt(m[0]), parseInt(m[1]), 0, 0);
      task.task_enddate = d;
    } else {
      task.task_enddate = null;
    }


    task.task_status = item.fileds[item.fileds.findIndex(x => x.field.fld_code === 'task_status')].value;
    let completed_atIndex = item.fileds.findIndex(x => x.field.fld_code === 'task_status' && x.value === 'Done');
    if (completed_atIndex >= 0) {
      task.completed_at = new Date(item.fileds[completed_atIndex]['updated_at']);
    } else {
      task.completed_at = null;
    }

    task.created_at = item.created_at;
    task.updated_at = item.updated_at;

    return task;
  }
}



export class TimeHelper {
  public static getDatetimeString(date: Date): string {
    let datetimestr: string = date.getFullYear() + '-';
    datetimestr += ('0' + (date.getMonth() + 1)).slice(-2) + '-';
    datetimestr += date.getDate() + ' ';
    datetimestr += date.getHours() + ':';
    datetimestr += date.getMinutes();

    return datetimestr;
  }

  public static getDatetimeStringNew(date: Date): string {
    let datetimestr: string = date.getFullYear() + '-';
    datetimestr += ('0' + (date.getMonth() + 1)).slice(-2) + '-';
    datetimestr += ('0' + (date.getDate())).slice(-2) + ' ';
    datetimestr += ('0' + (date.getHours())).slice(-2) + ':';
    datetimestr += ('0' + (date.getMinutes())).slice(-2);

    return datetimestr;
  }

  public static getDateString(date: Date): string {
    let datetimestr: string = date.getFullYear() + '-';
    datetimestr += ('0' + (date.getMonth() + 1)).slice(-2) + '-';
    datetimestr += ('0' + (date.getDate())).slice(-2);

    return datetimestr;
  }

  public static getDateFromString(dateString: string): Date {
      var s = dateString.split(" ")[0]?.split("-");
      var m = (dateString.includes("T")) ? dateString?.split("T")[1]?.split(":") : dateString?.split(" ")[1]?.split(":");
      //console.log("format date:"+new Date(parseInt(s[0]), parseInt(s[1]) - 1, parseInt(s[2]), parseInt(m[0]), parseInt(m[1]), 0, 0));
      return new Date(parseInt(s[0]), parseInt(s[1]) - 1, parseInt(s[2]), parseInt(m[0]), parseInt(m[1]), 0, 0);
  }

  public static getNowDate(){
    return new Date();
  }

  public static getStartOfCurrentMonth() {
    let nowDate = this.getNowDate();
    return new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);
  }

  public static getPreviousMonth() {
    let currentDate = this.getNowDate();
    return new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
  }

  public static getStartOfPreviousMonth() {
    let previousMonth = this.getPreviousMonth();
    return new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1);
  }

  public static getEndOfPreviousMonth() {
    let previousMonth = this.getPreviousMonth();
    let lastDay = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0);
    return new Date(previousMonth.getFullYear(), previousMonth.getMonth(), lastDay.getDate(), 23, 59, 59);
  }

  public static getStartOfWeek() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    // Calculate the difference between the current day and Monday
    const daysUntilMonday = (dayOfWeek + 6) % 7;
    // Subtract the difference to get the start of the current week (Monday)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - daysUntilMonday);
    // Set hours, minutes, and seconds to 0
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
  }

  public static getEndOfWeek(){
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    // Calculate the difference between the current day and Sunday (end of the week)
    const daysUntilSunday = (7 - dayOfWeek) % 7;
    // Add the difference to get the start of the next week (Sunday)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + daysUntilSunday);
    // Set hours, minutes, seconds, and milliseconds to the end of the day
    endOfWeek.setHours(23, 59, 59, 999);
    return endOfWeek;
  }
}


export class DurationHelper {

  public durationToMinutes24h(duration: string): number {
    let minutes = 0;
    switch (duration) {
      case '20m':
        minutes = 20;
        break;
      case '1h':
        minutes = 60;
        break;
      case '2h':
        minutes = 120;
        break;
      case '3h':
        minutes = 180;
        break;
      case '5h':
        minutes = 300;
        break;
      case '1d':
        minutes = 1440;
        break;
      case '2d':
        minutes = 2880;
        break;
      case '3d':
        minutes = 4320;
        break;
      case '5d':
        minutes = 7200;
        break;
      case '8d':
        minutes = 11520;
        break;
      case '13d':
        minutes = 18720;
        break;


      default:
        minutes = 0;
        break;
    }

    return minutes;
  }

  public durationToMinutes8h(duration: string): number {
    let minutes = 0;
    switch (duration) {

      case '20m':
        minutes = 20;
        break;
      case '1h':
        minutes = 60;
        break;
      case '2h':
        minutes = 120;
        break;
      case '3h':
        minutes = 180;
        break;
      case '5h':
        minutes = 300;
        break;
      case '1d':
        minutes = 480;
        break;
      case '2d':
        minutes = 960;
        break;
      case '3d':
        minutes = 1440;
        break;
      case '5d':
        minutes = 2400;
        break;
      case '8d':
        minutes = 3840;
        break;
      case '13d':
        minutes = 6240;
        break;

      default:
        minutes = 0;
        break;
    }

    return minutes;
  }

  public getIdialDuration(sprint: Sprint) {

    const sum: number = sprint.tasksDto.filter(x => x.task_duration)
      .reduce((sum, task) => sum + this.durationToMinutes8h(task.task_duration), 0);
    return (sum / 60);
  }

  public getCompletedDuration(sprint: Sprint) {
    const sum: number = sprint.tasksDto.filter(x => x.task_status && x.task_duration && x.task_status == 'Done')
      .reduce((sum, task) => sum + this.durationToMinutes8h(task.task_duration), 0);
    return sum / 60;
  }

  public getDiffInNumOfDays(minDate: Date, startDate: Date): number {
    let diff: number;
    startDate.setHours(0, 0, 0, 0);

    let diffTime = startDate.getTime() - minDate.getTime();
    diff = Math.round(diffTime / (1000 * 3600 * 24));

    return diff;

  }






}

export class FilterHelper {
  public static QueryStringHelper(filter: BaseFilter) {
    let queryString = '?trashed=' + filter.trashed;

    if (filter.strValue != '') {
      queryString += '&strValue=' + filter.strValue;
    }

    return queryString;
  }
}


export class TransformationHelper {
  public static CanEntityTransformToErpHelper(entity: string) {
    return entity == "order"
      || entity == "productionorder"
      || entity == "purchasingorder";
  }
}


export class MonthpickerDateAdapter extends NativeDateAdapter {
  constructor(matDateLocale: string, platform: Platform) {
    super(matDateLocale, platform);
  }

  override parse(value: string): Date | null {
    const monthAndYearRegex = /(10|11|12|0\d|\d)\/[\d]{4}/;
    if (value?.match(monthAndYearRegex)) {
      const parts = value.split('/');
      const month = Number(parts[0]);
      const year = Number(parts[1]);
      if (month > 0 && month <= 12) {
        return new Date(year, month - 1);
      }
    }
    return null;
  }

  override format(date: Date, displayFormat: any): string {
    const month = date.getMonth() + 1;
    const monthAsString = ('0' + month).slice(-2);
    const year = date.getFullYear();
    return monthAsString + '/' + year;
  }
}
