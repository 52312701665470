import { EntityService } from './../../../../../services/entity.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from"@angular/platform-browser";

@Component({
  selector: 'app-view-field',
  templateUrl: './view-field.component.html',
  styleUrls: ['./view-field.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewFieldComponent implements OnInit {
  @Input() ITEM: any;
  @Input() ITEMID: any;
  @Input() disabled: boolean = false;
  public viewHTML: SafeHtml;
  constructor(private entityService:EntityService, public sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    this.getView();
  }

  private getView(){
    this.entityService.getFieldView(this.ITEM.id,this.ITEMID).then(x=>{
      this.viewHTML =  this.sanitizer.bypassSecurityTrustHtml(x);


    });
  }

}
