import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FileErrorInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          // Check if the error status is in the error range (e.g., 400 or 500) and the response type is not json
          if (
            error.status >= 400 &&
            error.status < 600 &&
            request.responseType !== 'json'
          ) {
            // In case of an error, handle response as JSON
            const clonedRequest = request.clone({ responseType: 'json' });
            return next.handle(clonedRequest);
          }
        }
        return throwError(error);
      })
    );
  }
}
