export const environment = {
  production: true,
  pusher: {
    key: 'ABCDE',
    cluster: 'mt1',
  },
  firebase: {
    apiKey: 'AIzaSyBqhwqXxmWpNxlq03Ju-Z10ktXXQGxHH6A',
    authDomain: 'teamwork-os.firebaseapp.com',
    projectId: 'teamwork-os',
    storageBucket: 'teamwork-os.appspot.com',
    messagingSenderId: '363992806276',
    appId: '1:363992806276:web:e57a91033df58874cda3d1',
    measurementId: 'G-8D55B94SYC',
  },
  sentry_dsn:
    'https://99003d0767b645ddc9172d2ceb74ec1d@o4507102656462848.ingest.de.sentry.io/4507102826135632',
};
