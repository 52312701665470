import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User, Company } from 'src/app/model';
import { UserFormComponent } from '../user-form/user-form.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ChangePasswordDiagComponent } from '../change-password-diag/change-password-diag.component';
import { LayoutService } from '../../services/layout.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  public user: User;
  public company: Company = new Company();
  @ViewChild(UserFormComponent) userFormComp: UserFormComponent;
  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.user = this.loginService.getLoginUser();
  }

  public save() {
    if (!this.userFormComp.check()) {
      this.userService.updateProfile(this.user).then((resUser) => {
        this.userFormComp.resultHandling(resUser);
        if (resUser.success) {
          this.loginService.setLoginUser(resUser.data);
          window.location.href = '/';
        }
      });
    }
  }

  public exit() {
    window.location.href = '/';
  }

  public changePass() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      user: this.user,
    };

    const dialogRef = this.dialog.open(
      ChangePasswordDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {});
  }
}
