import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prodScheduleSprint'
})
export class ProdScheduleSprintPipe implements PipeTransform {

  transform(value: any, fieldType: string): unknown {

    switch (fieldType){
      case 'month':
        return value.fileds.filter(field => field.field.fld_code === 'productionorder_month')[0].value;
      case 'status':
        return value.fileds.filter(field => field.field.fld_code === 'productionorder_status')[0].value;
      case 'quantity':
        return value.fileds.filter(field => field.field.fld_code === 'productionorder_normalizedqty')[0].value;
      case 'time':
        return value.fileds.filter(field => field.field.fld_code === 'productionorder_total_time')[0].value;
    }
  }

}
