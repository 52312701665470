<div mat-dialog-content style="width: 100%; height: 100%">
  <app-data-item
    #entityItem
    [ITEMIDCOMP]="itemId"
    [ENTITYCOMP]="entityCode"
    [ITEMSOURCECOMP]="sourceItemId"
    [SHOWTOOLBAR]="true"
    [SHOWHBACKBTN]="true"
    [isDialog]="true"
    (BACK)="dialogRef.close($event)"
    (BACKWORKFLOW)="dialogRef.close($event)"
  ></app-data-item>
</div>

<div mat-dialog-actions></div>
