import { TranslateService } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ModulesComponent } from './modules/modules.component';
import { WelcomeComponent } from './modules/welcome/welcome.component';
import { RegisterInvitationComponent } from './register-invitation/register-invitation.component';
import { ProfileComponent } from './user/profile/profile.component';
import { EntityComponent } from './common/entity/entity.component';
import { DataItemComponent } from './common/entity/data-item/data-item.component';
import { GlobalSearchComponent } from './modules/global-search/global-search.component';

const routes: Routes = [
  {
    path: '',
    component: ModulesComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', component: WelcomeComponent, data: {} },
      {
        path: 'profile',
        component: ProfileComponent,
        data: { breadcrumb: 'Profile' },
      },
      {
        path: 'entity',
        component: EntityComponent,
        data: { breadcrumb: 'Entity' },
      },
      {
        path: 'entity/item/:entity/:item',
        component: DataItemComponent,
        data: { breadcrumb: 'Item' },
      },
      {
        path: 'fulladmin',
        loadChildren: () =>
          import('./modules/fulladmin/fulladmin.module').then(
            (m) => m.FulladminModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'full-admin', breadcrumb: 'Full-Admin' },
      },
      {
        path: 'localadmin',
        loadChildren: () =>
          import('./modules/localadmin/localadmin.module').then(
            (m) => m.LocaladminModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'local-admin,full-admin', breadcrumb: 'Local-Admin' },
      },
      {
        path: 'chat',
        loadChildren: () =>
          import('./modules/chat/chat.module').then((m) => m.ChatModule),
        data: { breadcrumb: 'Chat' },
      },
      {
        path: 'customentity',
        loadChildren: () =>
          import('./modules/crm/crm.module').then((m) => m.CrmModule),
        canActivate: [AuthGuardService],
        data: { role: 'customentity', breadcrumb: 'customentity' },
      },
      {
        path: 'task',
        loadChildren: () =>
          import('./modules/tasks/tasks.module').then((m) => m.TasksModule),
        canActivate: [AuthGuardService],
        data: { role: 'tasks', breadcrumb: 'task' },
      },
      {
        path: 'document',
        loadChildren: () =>
          import('./modules/documents/documents.module').then(
            (m) => m.DocumentsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'documents', breadcrumb: 'document' },
      },
      {
        path: 'project',
        loadChildren: () =>
          import('./modules/projects/projects.module').then(
            (m) => m.ProjectsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'projects', breadcrumb: 'project' },
      },

      {
        path: 'suppliers',
        loadChildren: () =>
          import('./modules/suppliers/suppliers.module').then(
            (m) => m.SuppliersModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'supplier', breadcrumb: 'suppliers' },
      },

      {
        path: 'service',
        loadChildren: () =>
          import('./modules/service/service.module').then(
            (m) => m.ServiceModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'service', breadcrumb: 'service' },
      },
      {
        path: 'installations',
        loadChildren: () =>
          import('./modules/installations/installations.module').then(
            (m) => m.InstallationsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'installations', breadcrumb: 'installations' },
      },
      {
        path: 'salesforecast',
        loadChildren: () =>
          import('./modules/salesforecast/salesforecast.module').then(
            (m) => m.SalesforecastModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'salesforecast', breadcrumb: 'salesforecast' },
      },

      {
        path: 'contact',
        loadChildren: () =>
          import('./modules/contacts/contacts.module').then(
            (m) => m.ContactsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'contact', breadcrumb: 'contact' },
      },
      {
        path: 'search',
        component: GlobalSearchComponent,
        data: { breadcrumb: 'Search' },
      },
      {
        path: 'country',
        loadChildren: () =>
          import('./modules/countries/countries.module').then(
            (m) => m.CountriesModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'country', breadcrumb: 'country' },
      },
      {
        path: 'competitor',
        loadChildren: () =>
          import('./modules/competitor/competitor.module').then(
            (m) => m.CompetitorModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'competitor', breadcrumb: 'competitor' },
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./modules/account/account.module').then(
            (m) => m.AccountModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'account', breadcrumb: 'account' },
      },
      {
        path: 'lead',
        loadChildren: () =>
          import('./modules/lead/lead.module').then((m) => m.LeadModule),
        canActivate: [AuthGuardService],
        data: { role: 'lead', breadcrumb: 'lead' },
      },
      {
        path: 'product',
        loadChildren: () =>
          import('./modules/product/product.module').then(
            (m) => m.ProductModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'product', breadcrumb: 'product' },
      },
      {
        path: 'order',
        loadChildren: () =>
          import('./modules/order/order.module').then((m) => m.OrderModule),
        canActivate: [AuthGuardService],
        data: { role: 'order', breadcrumb: 'order' },
      },
      {
        path: 'opportunity',
        loadChildren: () =>
          import('./modules/opportunity/opportunity.module').then(
            (m) => m.OpportunityModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'opportunity', breadcrumb: 'opportunity' },
      },
      {
        path: 'invoice',
        loadChildren: () =>
          import('./modules/invoice/invoice.module').then(
            (m) => m.InvoiceModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'invoice', breadcrumb: 'invoice' },
      },

      {
        path: 'quote',
        loadChildren: () =>
          import('./modules/quote/quote.module').then((m) => m.QuoteModule),
        canActivate: [AuthGuardService],
        data: { role: 'quote', breadcrumb: 'quote' },
      },
      {
        path: 'productconf',
        loadChildren: () =>
          import('./modules/productconf/productconf.module').then(
            (m) => m.ProductconfModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'productconf', breadcrumb: 'productconf' },
      },
      {
        path: 'semiprod',
        loadChildren: () =>
          import('./modules/semiprod/semiprod.module').then(
            (m) => m.SemiprodModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'semiprod', breadcrumb: 'semiprod' },
      },

      {
        path: 'report',
        loadChildren: () =>
          import('./modules/report/report.module').then((m) => m.ReportModule),
        canActivate: [AuthGuardService],
        data: { role: 'report', breadcrumb: 'report' },
      },
      {
        path: 'colleagues',
        loadChildren: () =>
          import('./modules/colleagues/colleagues.module').then(
            (m) => m.ColleaguesModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'colleagues', breadcrumb: 'colleagues' },
      },
      {
        path: 'purchasingorder',
        loadChildren: () =>
          import('./modules/purchasingorder/purchasingorder.module').then(
            (m) => m.PurchasingorderModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'purchasingorder', breadcrumb: 'purchasingorder' },
      },
      {
        path: 'productionorder',
        loadChildren: () =>
          import('./modules/productionorder/productionorder.module').then(
            (m) => m.ProductionorderModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'productionorder', breadcrumb: 'productionorder' },
      },
      {
        path: 'mrp',
        loadChildren: () =>
          import('./modules/mrp/mrp.module').then((m) => m.MrpModule),
        canActivate: [AuthGuardService],
        data: { role: 'mrp', breadcrumb: 'mrp' },
      },
      {
        path: 'productionschedule',
        loadChildren: () =>
          import('./modules/productionschedule/productionschedule.module').then(
            (m) => m.ProductionscheduleModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'productionschedule', breadcrumb: 'productionschedule' },
      },
      {
        path: 'channel',
        loadChildren: () =>
          import('./modules/channel/channel.module').then(
            (m) => m.ChannelModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'channel', breadcrumb: 'channel' },
      },
      {
        path: 'approval',
        loadChildren: () =>
          import('./modules/approval/approval.module').then(
            (m) => m.ApprovalModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'approval', breadcrumb: 'approval' },
      },

      {
        path: 'emploee',
        loadChildren: () =>
          import('./modules/emploee/emploee.module').then(
            (m) => m.EmploeeModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'emploee', breadcrumb: 'emploee' },
      },

      {
        path: 'hrticket',
        loadChildren: () =>
          import('./modules/hrticket/hrticket.module').then(
            (m) => m.HrticketModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'hrticket', breadcrumb: 'hrticket' },
      },

      {
        path: 'absence',
        loadChildren: () =>
          import('./modules/absence/absence.module').then(
            (m) => m.AbsenceModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'absence', breadcrumb: 'absence' },
      },

      {
        path: 'resume',
        loadChildren: () =>
          import('./modules/resume/resume.module').then((m) => m.ResumeModule),
        canActivate: [AuthGuardService],
        data: { role: 'resume', breadcrumb: 'resume' },
      },

      {
        path: 'interview',
        loadChildren: () =>
          import('./modules/interview/interview.module').then(
            (m) => m.InterviewModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'interview', breadcrumb: 'interview' },
      },
      {
        path: 'training',
        loadChildren: () =>
          import('./modules/training/training.module').then(
            (m) => m.TrainingModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'training', breadcrumb: 'training' },
      },
      {
        path: 'erp_docs',
        loadChildren: () =>
          import(
            './modules/erp/erp-warehouse-docs/erp-warehouse-docs.module'
          ).then((m) => m.ErpWarehouseDocsModule),
        canActivate: [AuthGuardService],
        data: { role: 'erp', breadcrumb: 'erp' },
      },
      {
        path: 'shift',
        loadChildren: () =>
          import('./modules/shift/shift.module').then((m) => m.ShiftModule),
        canActivate: [AuthGuardService],
        data: { role: 'shift', breadcrumb: 'shift' },
      },
      {
        path: 'calendar',
        loadChildren: () =>
          import('./modules/calendar/calendar.module').then(
            (m) => m.CalendarModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'calendar', breadcrumb: 'calendar' },
      },
      {
        path: 'address',
        loadChildren: () =>
          import('./modules/address/address.module').then(
            (m) => m.AddressModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'address', breadcrumb: 'address' },
      },
      {
        path: 'machinery',
        loadChildren: () =>
          import('./modules/machinery/machinery.module').then(
            (m) => m.MachineryModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'machinery', breadcrumb: 'machinery' },
      },
      {
        path: 'spare_part',
        loadChildren: () =>
          import('./modules/sparepart/sparepart.module').then(
            (m) => m.SparepartModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'spare_part', breadcrumb: 'spare_part' },
      },
      {
        path: 'maintenance_file',
        loadChildren: () =>
          import('./modules/maintenancefile/maintenancefile.module').then(
            (m) => m.MaintenancefileModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'maintenance_file', breadcrumb: 'maintenance_file' },
      },
      {
        path: 'breakdown',
        loadChildren: () =>
          import('./modules/breakdown/breakdown.module').then(
            (m) => m.BreakdownModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'breakdown', breadcrumb: 'breakdown' },
      },
      {
        path: 'repair_order',
        loadChildren: () =>
          import('./modules/repairorder/repairorder.module').then(
            (m) => m.RepairorderModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'repair_order', breadcrumb: 'repair_order' },
      },
      {
        path: 'prodrecipe',
        loadChildren: () =>
          import('./modules/prodrecipe/prodrecipe.module').then(
            (m) => m.ProdrecipeModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'prodrecipe', breadcrumb: 'prodrecipe' },
      },
      {
        path: 'statistics',
        loadChildren: () =>
          import('./modules/statistics/statistics.module').then(
            (m) => m.StatisticsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'statistics', breadcrumb: 'statistics' },
      },
      {
        path: 'meetings',
        loadChildren: () =>
          import('./modules/meetings/meetings.module').then(
            (m) => m.MeetingsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'meetings', breadcrumb: 'meetings' },
      },
      {
        path: 'expense',
        loadChildren: () =>
          import('./modules/expense/expense.module').then(
            (m) => m.ExpenseModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'expense', breadcrumb: 'expense' },
      },
      {
        path: 'expensesheet',
        loadChildren: () =>
          import('./modules/expensesheet/expensesheet.module').then(
            (m) => m.ExpensesheetModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'expensesheet', breadcrumb: 'expensesheet' },
      },
      {
        path: 'workflow_template',
        loadChildren: () =>
          import('./modules/workflow-templates/workflow-templates.module').then(
            (m) => m.WorkflowTemplatesModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'workflow_template', breadcrumb: 'workflow_template' },
      },
      {
        path: 'workflow',
        loadChildren: () =>
          import('./modules/workflows/workflows.module').then(
            (m) => m.WorkflowsModule
          ),
        canActivate: [AuthGuardService],
        data: { role: 'workflow', breadcrumb: 'workflow' },
      },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'invitation', component: RegisterInvitationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private translateService: TranslateService) {}
}
