import { NotificationService } from 'src/app/services/notification.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseFilter, EntityItemFilter } from 'src/app/common/filters';
import { Entity, Field, SavedFilter, User } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';
import { LoginService } from 'src/app/services/login.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntitySelectorDiagComponent } from 'src/app/common/entity-selector-diag/entity-selector-diag.component';
import { TimeHelper } from 'src/app/common/helper';
import { UserFilterComponent } from './user-filter/user-filter.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '../../../../services/user.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { SettingsService } from '../../../../services/settings.service';
import { TaskService } from '../../../../services/task.service';

@Component({
  selector: 'app-data-list-filters',
  templateUrl: './data-list-filters.component.html',
  styleUrls: ['./data-list-filters.component.css'],
})
export class DataListFiltersComponent implements OnInit {
  @Input() ENTITY: Entity;
  @Input() FILTERS: EntityItemFilter;
  @Output() getData = new EventEmitter<BaseFilter>();

  public entities: any[] = [];
  savedFilters: SavedFilter[] = [];
  newFilterName: string;
  selectedConnectEntity: Entity;
  fields: any[] = [];
  toogleFilters: boolean = true;
  prefs: any[] = [];

  public ownerFilter: Field = new Field();

  @ViewChild(UserFilterComponent) userFilterCom: UserFilterComponent;

  constructor(
    private loginService: LoginService,
    public dialog: MatDialog,
    private entityService: EntityService,
    private notificationService: NotificationService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private dashboardService: DashboardService,
    private settingsService: SettingsService,
    private taskService: TaskService
  ) {}

  ngOnInit(): void {
    this.getEntityList();
    this.getSavedFiltersList();
    this.fields = this.ENTITY.fields.filter((x) => x.hiddeOnFilters == 0);
    this.addStepFilter();
    if (localStorage.getItem('searchFilterPrefs')) {
      this.prefs = JSON.parse(localStorage.getItem('searchFilterPrefs'));
    }
    this.checkForSalesmanDateFilter();
  }

  changeFilter() {
    this.getData.emit(this.FILTERS);
  }

  public showSearchTo(filed: Field): boolean {
    let flag: boolean = true;

    let hideTypes = [
      'datetime',
      'label',
      'text',
      'email',
      'dropdown',
      'checkbox',
    ];
    if (hideTypes.indexOf(filed.field_specs.fld_code) >= 0) {
      flag = false;
    }
    return flag;
  }

  public showSearchFrom(filed: Field): boolean {
    let flag: boolean = true;

    let hideTypes = ['datetime', 'label', 'dropdown', 'checkbox'];
    if (hideTypes.indexOf(filed.field_specs.fld_code) >= 0) {
      flag = false;
    }
    return flag;
  }

  public clearSearchValues(field: Field) {
    delete field.search_from;
    delete field.search_to;
  }

  public getDBFldTransateLabel(field: any): string {
    let lang = this.loginService.getLoginUser()?.lang;

    return lang == 'en' ? field.label_en : field.label_gr;
  }

  public getSavedFiltersList() {
    this.entityService.getSavedFilters(this.ENTITY.entity_code).then((res) => {
      this.savedFilters = res;
    });
  }

  public saveFilters() {
    let newSavedFilter: SavedFilter = new SavedFilter();
    newSavedFilter.filters = [];
    newSavedFilter.title = this.newFilterName;
    newSavedFilter.entity_code = this.ENTITY.entity_code;

    for (let field of this.fields.filter((x) => x.search_from || x.search_to)) {
      let filterObj = {
        field_code: field.fld_code,
        search_from: field.search_from,
        search_to: field.search_to,
      };

      newSavedFilter.filters.push(filterObj);
    }

    newSavedFilter.trashed = this.FILTERS.trashed;
    newSavedFilter.connectedWith = this.FILTERS.connectedWith;
    newSavedFilter.createdAtSearchDate = this.FILTERS.createdAtSearchDate;
    newSavedFilter.itemId = this.FILTERS.itemId;
    newSavedFilter.ownerId = this.FILTERS.ownerId;
    newSavedFilter.strValue = this.FILTERS.strValue;

    this.entityService.addSavedFilters(newSavedFilter).then((res) => {
      if (res.success) {
        this.notificationService.showSnackbarMessage('Messages.successfulSave');
        newSavedFilter.id = res.data.id;
        this.savedFilters.push(newSavedFilter);
        this.newFilterName = '';
      } else {
        this.notificationService.showSnackbarMessage('Messages.failSave');
      }
    });
  }

  public deleteFilter(savedFilter: SavedFilter) {
    this.entityService.delSavedFilters(savedFilter.id).then((res) => {
      if (res.success) {
        this.notificationService.showSnackbarMessage(
          'Messages.deleteSuccessful'
        );
        let delIndex = this.savedFilters.findIndex(
          (x) => x.id == savedFilter.id
        );
        this.savedFilters.splice(delIndex, 1);
      } else {
        this.notificationService.showSnackbarMessage('Messages.deleteFail');
      }
    });
  }

  public applySavedFilter(filter: SavedFilter) {
    this.clearAllfilters();
    if (!filter) {
      return;
    }
    for (let field of this.fields) {
      let savedFilterIndex = filter.filters.findIndex(
        (x) => x.field_code == field.fld_code
      );

      if (
        savedFilterIndex >= 0 &&
        filter.filters[savedFilterIndex].search_from
      ) {
        field.search_from = filter.filters[savedFilterIndex].search_from;
      }
      if (savedFilterIndex >= 0 && filter.filters[savedFilterIndex].search_to) {
        field.search_to = filter.filters[savedFilterIndex].search_to;
      }
    }

    this.FILTERS.trashed = +filter.trashed;
    this.FILTERS.connectedWith = filter.connectedWith;
    this.FILTERS.ownerId = filter.ownerId;
    this.FILTERS.createdAtSearchDate = filter.createdAtSearchDate;
    this.FILTERS.itemId = filter.itemId;
    this.FILTERS.strValue = filter.strValue;
    this.userFilterCom.search_from = filter.ownerId;
  }

  public autoFillFrom(field: Field) {
    if (field.search_from) {
      return;
    }

    if (
      field.field_specs.fld_code == 'number' ||
      field.field_specs.fld_code == 'scale'
    ) {
      field.search_from = '0';
    } else if (field.field_specs.fld_code == 'datetime') {
      field.search_from = TimeHelper.getDatetimeString(new Date());
    }
  }

  private getEntityList() {
    this.entityService.getEntityList().then((res) => {
      this.entities = res;
    });
  }

  public getEntityNameTranslate(entity: Entity): string {
    let lang = this.loginService.getLoginUser().lang;

    return lang == 'en' ? entity.name_en : entity.name_gr;
  }

  public selectEntityIDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: this.selectedConnectEntity.entity_code,
    };

    const dialogRef = this.dialog.open(
      EntitySelectorDiagComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.FILTERS.connectedWith = result.id;
      }
    });
  }

  public clearConnectedWith() {
    this.FILTERS.connectedWith = null;
  }

  public clearStrValue() {
    this.FILTERS.strValue = null;
  }
  public clearOwnerId() {
    this.userFilterCom.search_from = null;
    this.FILTERS.ownerId = null;
  }

  public clearCreatedAtSearchDate() {
    this.FILTERS.createdAtSearchDate = null;
  }

  public clearItemId() {
    this.FILTERS.itemId = null;
  }

  public clearAllfilters() {
    this.clearConnectedWith();
    this.clearStrValue();
    this.clearOwnerId();
    this.clearCreatedAtSearchDate();
    this.clearItemId();
    this.FILTERS.trashed = 0;

    for (let field of this.fields) {
      this.clearSearchValues(field);
    }
  }

  public selectOwnerFiler(user: User) {
    this.FILTERS.ownerId = user.id;
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  addToPreferences(field: Field) {
    let index = this.prefs.findIndex((x) => x.entfield_id === field.id);
    if (index === -1) {
      this.prefs.push({ entfield_id: field.id });
      localStorage.setItem('searchFilterPrefs', JSON.stringify(this.prefs));
      this.userService.addUserSearchFilterPref(field.id);
    } else {
      this.prefs.splice(index, 1);
      localStorage.setItem('searchFilterPrefs', JSON.stringify(this.prefs));
      this.userService.deleteUserSearchFilterPref(field.id);
    }
  }

  showSearchField(fld_id: number) {
    return this.prefs.some((x) => x.entfield_id === fld_id);
  }

  private addStepFilter() {
    let stepFields = this.ENTITY.fields.filter(
      (x) => x.field_specs.fld_code === 'step'
    );
    if (stepFields.length > 0) {
      let stepNames = '';
      stepFields.forEach((field) => {
        stepNames += this.getDBFldTransateLabel(field) + '|';
      });
      stepNames += 'Closed';
      let stepFilter = {
        id: -23,
        field_specs: { fld_code: 'dropdown' },
        fld_code: 'current_step',
        label_en: 'Current Step',
        label_gr: 'Τρέχον Βήμα',
        for_entity: stepNames,
        search_from: null,
      };
      this.fields.push(stepFilter);
      this.FILTERS.stepValue = null;
    }
  }

  private checkForSalesmanDateFilter() {
    if (this.dashboardService.applySalesmanEntityDateFilter.valueOf()) {
      this.settingsService
        .getSettings(`dashboardFilterFieldId-${this.ENTITY.entity_code}`)
        .then((value) => {
          if (value.length > 0) {
            const filterFieldId = Number(value[0].value);
            const index = this.ENTITY.fields.findIndex(
              (field) => field.id === filterFieldId
            );
            if (index !== -1) {
              this.ENTITY.fields[index].search_from =
                TimeHelper.getDatetimeStringNew(TimeHelper.getStartOfWeek());
              this.ENTITY.fields[index].search_to =
                TimeHelper.getDatetimeStringNew(TimeHelper.getEndOfWeek());
              this.FILTERS.searchFields.push(this.ENTITY.fields[index]);
            }
          }
          this.entityService.getEntityListWithSalesmanFilters.next(true);
        });
    }
  }
}
