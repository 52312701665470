<div class="flexdiv">
  <div *ngIf="user && SHOWUSER"  class="flexdiv" >
    <span>
      <app-show-avatar [USER]="user"></app-show-avatar>
    </span>
    <span class="text-alternate-warn">{{user.firstname}} {{user.lastname}}</span>

  </div>
  <div>
  <button mat-icon-button (click)="selectUserDiag()" >
    <mat-icon color="primary">person_search</mat-icon>
  </button>

</div>
