import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReturnData } from '../model';
import { InstallationLine } from '../models/installationline';
import { PurchasingorderLine } from '../models/purchasingorder';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class InstallationService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getSavedLines(entdataId: string): Promise<InstallationLine[]> {
    return this.get<InstallationLine[]>(this.apiUrl + 'installationlines/' + entdataId);
  }




}
