import { Component, Input, OnInit } from '@angular/core';
import { ProjectDto, ScheduleDto, Sprint, User } from 'src/app/model';
import { LoginService } from 'src/app/services/login.service';
import { Location } from '@angular/common';
import { ProjectService } from 'src/app/services/project.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { SpringDiagComponent } from 'src/app/common/entity/data-item/fields/sprint-list/spring-diag/spring-diag.component';
import { ScheduleFilter, TaskFilter } from '../../../../filters';
import { ProductionscheduleService } from '../../../../../services/productionschedule.service';
import { DataItemDiagComponent } from '../../../data-item-diag/data-item-diag.component';
import { DtoConverter } from '../../../../helper';
import { TaskService } from '../../../../../services/task.service';
import { DurationHelper } from 'src/app/common/helper';

@Component({
  selector: 'app-sprint-list',
  templateUrl: './sprint-list.component.html',
  styleUrls: ['./sprint-list.component.css'],
})
export class SprintListComponent implements OnInit {
  @Input() ITEMID: any;
  @Input() entCode: any;
  schedule: ScheduleDto;
  project: ProjectDto;
  currentUser: User;
  durationHelper: DurationHelper = new DurationHelper();
  constructor(
    private loginService: LoginService,
    private __location: Location,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private scheduleService: ProductionscheduleService,
    private taskService: TaskService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.loginService.getLoginUser() as User;
    this.getData();
    this.taskService.refreshProjectData.subscribe((refresh) =>
      this.getTaskByProject()
    );
  }

  getData() {
    if (this.entCode === 'productionschedule') {
      this.getOrdersBySchedule();
    } else if (this.entCode === 'projects') {
      this.getTaskByProject();
    }
  }

  public goBack() {
    this.__location.back();
  }

  public newSprintDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      sprint: null,
      project_id:
        this.entCode === 'productionschedule'
          ? this.schedule.id
          : this.project.id,
    };

    const dialogRef = this.dialog.open(SpringDiagComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {
        this.projectService.addSprint(result).then((res) => {
          if (res.success) {
            res.data.tasks = [];
            res.data.tasksDto = [];
            res.data.showSprint = true;
            if (this.entCode === 'productionschedule') {
              this.schedule.sprints.push(res.data);
            } else if (this.entCode === 'projects') {
              this.project.sprints.push(res.data);
            }
            this.notificationService.showSnackbarMessage(
              'Messages.successfulSave'
            );
          } else {
            this.notificationService.showSnackbarMessage('Messages.failSave');
          }
        });
      }
    });
  }

  public delSprint(sprint: Sprint) {
    this.projectService.delSprint(sprint.id).then((res) => {
      if (this.entCode === 'productionschedule') {
        this.getOrdersBySchedule();
      } else if (this.entCode === 'projects') {
        this.getTaskByProject();
      }
    });
  }

  drop(ev, sprint: Sprint) {
    if (!sprint) {
      sprint = new Sprint();
      sprint.id = null;
    }

    ev.preventDefault();
    const data = ev.dataTransfer.getData('text');
    const OrderToSprint = JSON.parse(data);
    this.projectService
      .addTaskToSprint(this.schedule.id, sprint, OrderToSprint)
      .then((res) => {
        if (res.success) {
          this.getOrdersBySchedule();
        }
      });
  }

  public getOrdersBySchedule() {
    const orderfilters: ScheduleFilter = new ScheduleFilter();
    orderfilters.schedule_id = +this.ITEMID;
    orderfilters.searchFields = [];
    this.scheduleService.getProdOrdersByScheduleId(orderfilters).then((res) => {
      this.schedule = res;
    });
  }

  public newProductionOrder() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: 'new',
      entityCode: 'productionorder',
      sourceItemId: this.schedule.id,
    };

    const dialogRef = this.dialog.open(DataItemDiagComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      this.getOrdersBySchedule();
    });
  }

  public newProjectTask() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      itemId: 'new',
      entityCode: 'tasks',
      sourceItemId: this.project.id,
    };

    const dialogRef = this.dialog.open(DataItemDiagComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      this.getTaskByProject();
      // this.projItemComp.connectedListComp.getEntCodeConnections();
    });
  }

  public getTaskByProject() {
    const taskfilters: TaskFilter = new TaskFilter();
    taskfilters.project_id = +this.ITEMID;
    taskfilters.searchFields = [];
    this.taskService.getTaskByProjectId(taskfilters).then((res) => {
      this.project = res;
      this.project.sprints = this.taskConverter(res.sprints);
      this.taskService.projectSprints.next(this.project.sprints);
    });
  }

  private taskConverter(sprints: Sprint[]): Sprint[] {
    const dtoConv: DtoConverter = new DtoConverter();
    for (const sprint of sprints) {
      sprint.tasksDto = [];
      for (const taskEnt of sprint.tasks) {
        sprint.tasksDto.push(dtoConv.EntdataToTaskDto(taskEnt));
      }
    }

    return sprints;
  }

  onCreate() {
    if (this.entCode === 'productionschedule') {
      this.newProductionOrder();
    } else if (this.entCode === 'projects') {
      this.newProjectTask();
    }
  }
}
