import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  ViewChild,
  Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'app-data-item-diag',
  templateUrl: './data-item-diag.component.html',
  styleUrls: ['./data-item-diag.component.css'],
})
export class DataItemDiagComponent implements OnInit {
  itemId: number;
  entityCode: string;
  sourceItemId: number;
  @Output() BACK = new EventEmitter();
  public isRoutedPage: boolean;

  @ViewChild('entityItem') entityItem;
  constructor(
    public dialogRef: MatDialogRef<DataItemDiagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private __location: Location
  ) {}

  ngOnInit(): void {
    this.dialogRef.updateSize('80%', '80%');
    this.itemId =
      this.data.itemId === 'new' ? this.data.itemId : Number(this.data.itemId);
    this.entityCode = this.data.entityCode;
    this.sourceItemId = this.data.sourceItemId;
  }

  public goBack() {
    if (this.isRoutedPage) {
      this.__location.back();
    } else {
      this.BACK.emit();
    }
  }
}
