<mat-form-field style="width: 100%">
  <mat-label class="title">{{ title }}</mat-label>
  <mat-date-range-input [rangePicker]="picker" [disabled]="disabled">
    <input
      matStartDate
      placeholder="Start date"
      (dateChange)="convertDateToWeek($event)"
      [ngModel]="startDate"
    />
    <input matEndDate placeholder="End date" [ngModel]="endDate" />
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
