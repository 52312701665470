import { LoginService } from './login.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  helper = new JwtHelperService();
  constructor(public router: Router, private loginService: LoginService) { }

  currentUser: any;

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let token = localStorage.getItem('token');

    const role = route.data.role;
    this.helper.isTokenExpired(token);
    if (!token || this.helper.isTokenExpired(token)) {
      this.router.navigate(['./login']);
      return false;
    }



    if (role && role !== '' && !this.loginService.hasScopePermission(role + '')) {
      alert('Δεν έχετε τα κατάλληλα δικαιώματα για αυτή την ενέργεια');
      return false;
    } else {
      return true;
    }
  }
}
