<mat-form-field class="full-width">
  <mat-select [(ngModel)]="ITEM.value" class="full-width" [disabled]="disabled">
    <mat-option
      *ngFor="let department of company?.departments"
      [value]="department.id"
    >
      {{ department.title }} - {{ department.location }}
    </mat-option>
    <mat-option [value]="-1" *ngIf="showAllDepartmentProperty">
      {{ 'Generic.allDepartments' | translate }}
    </mat-option>
  </mat-select>

  <button
    mat-icon-button
    matSuffix
    (click)="toggleChart($event)"
    style="z-index: 1000 !important"
  >
    <mat-icon>info_outline</mat-icon>
  </button>
</mat-form-field>

<div
  class="positionView"
  *ngIf="company && this.showChart"
  (click)="this.showChart = false"
>
  <app-department-tree
    [department_tree]="company.department_tree"
    class="companyChartCard"
  >
  </app-department-tree>
</div>
