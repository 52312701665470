<div class="GridDiv">
  <mat-table
    [dataSource]="lines"
    [class.prodTable-M]="layoutService.isHandset$ | async"
  >
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span *ngIf="element.installation"> </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="installation_name">
      <mat-header-cell *matHeaderCellDef>
        {{ 'InstallationLines.installation_name' | translate }}</mat-header-cell
      >

      <mat-cell *matCellDef="let element">
        <span *ngIf="element.installation">
          #{{ element.installation.id }}
          {{ element.installation.installation_name }}
        </span>

        <span *ngIf="!element.installation">
          <app-entity-selector-autocomplete
            ENTITY="installations"
            (selectEntityItem)="fillInstallationlineItem($event, element)"
          >
          </app-entity-selector-autocomplete>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="hours">
      <mat-header-cell *matHeaderCellDef>
        {{ 'InstallationLines.working_hours' | translate }}</mat-header-cell
      >
      <mat-cell *matCellDef="let element">
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="element.hours"
            type="number"
            [disabled]="disabled || !element.installation"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="del">
      <mat-header-cell *matHeaderCellDef>
        {{ 'PurchasingOrder.del' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <button
          mat-icon-button
          color="warn"
          (click)="removeItemLine(i)"
          [disabled]="disabled"
          *ngIf="element.installation"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="selectedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: selectedColumns"></mat-row>
  </mat-table>
</div>
