import { OnChanges, SimpleChanges } from '@angular/core'
import { Component, OnInit, Input } from '@angular/core'
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
} from '@angular/forms'
import { User, Company, ReturnData, Module } from 'src/app/model'

import { NotificationService } from 'src/app/services/notification.service'
import { UserService } from 'src/app/services/user.service'

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css'],
})
export class UserFormComponent implements OnChanges {
  userIdToOpen: string
  @Input() user: User
  @Input() editMode: boolean
  @Input() company: Company

  ErrorsShow: boolean = false
  errors: string[] = []
  passMutch: boolean = true
  hidePass = true
  hideCPass = true

  modules: Module[] = []
  selectedModules: Module[] = []

  companyIdFromRouter
  profileForm: UntypedFormGroup
  formData = new FormData()
  constructor(
    private notificationService: NotificationService,
    private userService: UserService
  ) {}

  passwordFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(
      '^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\\d\\x])(?=.*[!@$#%^&*()]).*$'
    ),
    Validators.minLength(8),
  ])
  cpasswordFormControl = new UntypedFormControl('', [Validators.required])
  firstnameFormControl = new UntypedFormControl('', [Validators.required])
  langFormControl = new UntypedFormControl('', [Validators.required])
  lastnameFormControl = new UntypedFormControl('', [Validators.required])

  nicknameFormControl = new UntypedFormControl('', [Validators.required])
  email2FormControl = new UntypedFormControl('', [Validators.email])
  emailFormControl = new UntypedFormControl('', [
    Validators.email,
    Validators.required,
  ])
  departmentFormControl = new UntypedFormControl(0, [Validators.required])

  ngOnChanges(changes: SimpleChanges): void {
    if (this.company && this.company.departments) {
      for (let dep of this.company.departments) {
        dep.id = +dep.id
      }
    }

    if (this.user.department_id) {
      this.user.department_id = +this.user.department_id
    }
  }

  ngOnInit(): void {
    if (this.user.birthdate) {
      this.user.birthdate = new Date(JSON.stringify(this.user.birthdate))
    }
  }

  public check() {
    let hasAnyError: boolean = false

    if (
      (!this.editMode && this.emailFormControl.hasError('required')) ||
      (!this.editMode && this.nicknameFormControl.hasError('required')) ||
      (!this.editMode && this.cpasswordFormControl.hasError('required')) ||
      (!this.editMode && this.passwordFormControl.hasError('required')) ||
      (!this.editMode && this.passwordFormControl.hasError('pattern')) ||
      (!this.editMode && this.passwordFormControl.hasError('minlength')) ||
      this.firstnameFormControl.hasError('required') ||
      this.lastnameFormControl.hasError('required') ||
      this.langFormControl.hasError('required') ||
      this.departmentFormControl.hasError('required') ||
      (!this.editMode && this.user.password !== this.user.cpassword)
    ) {
      hasAnyError = true
      if (!this.editMode) {
        this.passMutch = this.user.password === this.user.cpassword
        if (!this.passMutch) {
          this.cpasswordFormControl.setErrors({ incorrect: true })
          this.passwordFormControl.setErrors({ incorrect: true })
        }

        this.cpasswordFormControl.markAllAsTouched()
        this.passwordFormControl.markAllAsTouched()
      }

      this.nicknameFormControl.markAllAsTouched()
      this.firstnameFormControl.markAllAsTouched()
      this.lastnameFormControl.markAllAsTouched()
      this.langFormControl.markAllAsTouched()
      this.emailFormControl.markAllAsTouched()
      this.departmentFormControl.markAllAsTouched()
      this.email2FormControl.markAllAsTouched()
    }

    return hasAnyError
  }

  public resultHandling(resUser: ReturnData<User>) {
    if (resUser.success) {
      this.editMode = true
      this.notificationService.showSnackbarMessage('Messages.successfulSave')
      this.user = resUser.data
      this.ErrorsShow = false
      this.errors = []
    } else {
      this.notificationService.showSnackbarMessage('Messages.failSave')
      this.errors = []
      for (var k in resUser.error as any) {
        for (let msg of resUser.error[k]) {
          this.errors.push(msg)
        }
      }
      this.ErrorsShow = true
    }
  }
}
