import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ERPSpdoctype } from 'src/app/models/erpspdoctype';
import { TransformEntityToErp } from 'src/app/models/tranformentitytoerp';
import { ErpSpdoctypeService } from 'src/app/services/erp/erp-spdoctype.service';
import { TransformationService } from 'src/app/services/transformation.service';
import { LayoutService } from '../../../../services/layout.service';

@Component({
  selector: 'app-transform-entity-to-erp',
  templateUrl: './transform-entity-to-erp.component.html',
  styleUrls: ['./transform-entity-to-erp.component.css'],
})
export class TransformEntityToErpComponent implements OnInit {
  public spdoctypes: ERPSpdoctype[] = [];
  public idsForTansform: number[] = [];
  public transformEntityToErp: TransformEntityToErp =
    new TransformEntityToErp();
  public responseTransformations: any[] = [];
  public percetange: number = 0;
  public desired_quantity: number;
  public backorderValue: number;

  constructor(
    private dialogRef: MatDialogRef<TransformEntityToErpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private doctypeService: ErpSpdoctypeService,
    private transformationService: TransformationService,
    private router: Router,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.idsForTansform = this.data.entity_ids;

    this.getdocTypes();
    this.desired_quantity = this.data.backorderValue;
  }

  public getdocTypes() {
    this.doctypeService.getActiveSpdoctypes().then((res) => {
      this.spdoctypes = res.filter(
        (x) => x.wh_behavior == 1 || x.wh_behavior == -1
      );
    });
  }
  public selectedSpdocTitle() {
    return (
      this.spdoctypes.find(
        (x) => x.id == this.transformEntityToErp.spdoctype_id
      )?.title ?? ''
    );
  }

  public startTranformation() {
    for (let entdata_id of this.idsForTansform) {
      this.transform(entdata_id, this.desired_quantity);
    }
  }

  public isProductionOrderEntity(): boolean {
    return window.location.pathname.includes('/productionorder');
  }

  private transform(entdata_id: number, desired_quantity: number) {
    this.transformEntityToErp.entdata_id = entdata_id;
    this.transformEntityToErp.desired_quantity = desired_quantity; // Set desired_quantity
    this.transformationService
      .entityToErp(this.transformEntityToErp)
      .then((res) => {
        this.percetange += 100 / this.idsForTansform.length;
        this.responseTransformations.push({
          success: res.success,
          message: !res.success ? res.data : '',
          entity_id: entdata_id,
          doc_id: res.data.doc_id,
          type: res.data.type,
        });
      });
  }

  public previewDoc(doc_id, type: string) {
    if (type == 'wh') {
      this.router.navigate(['/erp_docs/wh/edit/', doc_id]);
      this.dialogRef.close();
    } else if ((type = 'fin')) {
      this.router.navigate(['/erp_docs/fin/edit/', doc_id]);
      this.dialogRef.close();
    }
  }
}
