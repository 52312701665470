
export class TransformEntityToErp {
  desired_quantity: number;
  constructor() {
  }

  entdata_id: number;
  spdoctype_id: number;
}


