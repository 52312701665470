import { LoginService } from 'src/app/services/login.service';
import { ChatPopup } from './../../model';
import { OnChanges, SimpleChanges, Component, Input } from '@angular/core';
import { User } from 'src/app/model';
import { ChatService } from 'src/app/services/chat.service';
import { UserService } from 'src/app/services/user.service';
import { WebsocketsService } from 'src/app/services/websockets.service';

@Component({
  selector: 'app-show-avatar',
  templateUrl: './show-avatar.component.html',
  styleUrls: ['./show-avatar.component.css']
})

export class AvatarShowComponent implements OnChanges {
  avatarBase64: string = '';
  @Input() USER: User;
  @Input() SHOWCONNECTIVITY: boolean = true;
  currentUser: any;
  constructor(private sockets: WebsocketsService, private userService: UserService, private chatService: ChatService, private loginService: LoginService) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.getAvatar();
  }

  ngOnInit(): void {
  }
  public getAvatar() {
    let cachedAvatar = this.userService.getAvatarFormCache(this.USER.id);
    if (cachedAvatar) {
      this.avatarBase64 =   cachedAvatar;
        return;
    }
    this.userService.getAvatar(this.USER.id).then(resAvatar => {
      this.userService.cacheAvatar(this.USER.id, resAvatar.data);
      this.avatarBase64 = resAvatar.data;
    });
  }

  public isUserConnected(): boolean {
    let index = this.sockets.connectedUsers.findIndex(x => x.id == this.USER.id);
    return index >= 0;
  }

  public openChatDiag() {
    if (!this.loginService.hasScopePermission('user-chat')) {
      return;
    }
    if (this.chatService.chatPopups.findIndex(x => x.userForPopup.id == this.USER.id) < 0) {

      window.innerWidth < 800 ? this.chatService.chatPopups = []: "";

      let chatPopup: ChatPopup = new ChatPopup();
      chatPopup.userForPopup = this.USER;
      chatPopup.isChatMinimized = false;
      this.chatService.chatPopups.push(chatPopup);
    }
  }
}
