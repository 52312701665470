<div class="teamHeight">

  <button mat-icon-button color="primary" style="margin-bottom: 20px;" (click)="selectEntityItemDiag()"
    [disabled]="disabled">
    <mat-icon>add</mat-icon>
  </button>
  <table class="full-width"   *ngIf="ITEM"   aria-hidden="true">

    <tbody>
      <tr *ngFor="let user of ITEM.members;let i = index">
        <td>{{i+1}})</td>
        <td>
          <app-show-avatar [USER]="user"></app-show-avatar>
        </td>
        <td>{{user.firstname}}</td>
        <td>{{user.lastname}}</td>
        <td>(@{{user.nickname}})</td>
        <td>
          <button mat-icon-button color="warn" (click)="removeMember(i)" [disabled]="disabled" style="z-index: 1000;">
            <mat-icon>clear</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
