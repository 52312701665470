import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stepValue'
})
export class StepValuePipe implements PipeTransform {

  transform(stepValue: string): string {
    const segments = stepValue.split('|');
    if (segments.length > 1) {
     return segments.length === 2 ? segments[1] : segments[3];
    }
    return stepValue;
  }
}

