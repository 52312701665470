import { Component, Input, OnInit } from '@angular/core';
import { Assignee } from 'src/app/models/approval';

@Component({
  selector: 'app-approval-timeline',
  templateUrl: './approval-timeline.component.html',
  styleUrls: ['./approval-timeline.component.css']
})
export class ApprovalTimelineComponent implements OnInit {

  @Input() ASSIGNEES: Assignee[] = [];

  constructor( ) { }

  ngOnInit(): void {
  }


}
