<mat-sidenav-container
  (backdropClick)="sidenav.close()"
  style="height: calc(100% - 64px)"
>
  <mat-sidenav
    class="navcontainer"
    #sidenav
    (keydown.escape)="sidenav.close()"
    disableClose
    [fixedInViewport]="true"
  >
    <app-sidebar-content></app-sidebar-content>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
