import {Component, ElementRef, Input, Renderer2, ViewChild} from '@angular/core';
import { Post } from 'src/app/model';
import { EntityService } from 'src/app/services/entity.service';

@Component({
  selector: 'app-data-post',
  templateUrl: './data-post.component.html',
  styleUrls: ['./data-post.component.css']
})
export class DataPostComponent  {
  text: string = "";
  @Input() ITEMID: string;
  @Input() disabled: boolean = false;
  posts: Post[] = [];
  scrollPosition = 0;

  constructor(private entityService: EntityService) { }


  delay(ms: number): Promise<void> {
    return new Promise<void>((resolve) => setTimeout(resolve, ms));
  }

  scrollToBottom(): void {
    this.delay(200).then(x => {
      this.scrollPosition = 9999;
    });
  }

  ngOnChanges(): void {
    this.getPosts();
  }

  sendPost() {
    let newPost: Post = new Post();
    newPost.text = this.text;
    newPost.entdata_id = +this.ITEMID;
    this.entityService.sendPost(newPost).then(res => {
      if (res.success) {
        this.text = "";
        this.posts = res.data;
        this.scrollToBottom();
      }
    });
  }

  getPosts() {
    this.entityService.getPosts(this.ITEMID).then(resPosts => {
      this.posts = resPosts;
      this.scrollToBottom();
    });
  }

}
