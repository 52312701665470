import { Notify } from '../../model';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.css'],
})
export class NotifyComponent implements OnInit {
  notify: Notify;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public notificationService: NotificationService
  ) {
    this.notify = data.notify;
  }
  ngOnInit(): void {}

  read() {
    this.data.preClose();
  }
}
