import { User } from 'src/app/model';
import { Component, Input } from '@angular/core';
import { WebsocketsService } from 'src/app/services/websockets.service';
import { LoginService } from '../../../../services/login.service';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.css'],
})
export class FieldsComponent {
  @Input() DISABLED: boolean = false;
  @Input() attr: any;
  @Input() allAttr: any[];
  @Input() ITEMID;
  @Input() entCode;
  @Input() showMandatoryErrors: boolean;
  @Input() showInvalidEndDate: boolean;
  @Input() OWNER: User;

  constructor(
    private websocketService: WebsocketsService,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    if (
      this.attr.editableByOwner === 1 &&
      this.ITEMID !== 'new' &&
      this.loginService.getLoginUser().id !== this.OWNER.id
    ) {
      this.DISABLED = true;
    }
  }

  public answerQuestionFromFld(fld) {
    let questions = this.websocketService.questions.filter(
      (x) => x.entfield_id == fld.id && x.entdata_id == this.ITEMID
    );
    for (let question of questions) {
      this.websocketService.answerQuestion(question);
    }
  }
}
