import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {
  CalendarAbsence,
  CalendarEntityEvent,
  CalendarMeeting,
  CalendarProductionOrder,
  CalendarShift,
  CalendarTask,
  ReturnData
} from '../model';

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends BaseService{

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getCalendarTasks(calendarId): Promise<CalendarTask[]> {
    return this.get<CalendarTask[]>(this.apiUrl + `calendar/${calendarId}/tasks`);
  }

  public getCalendarShifts(calendarId): Promise<CalendarShift[]> {
    return this.get<CalendarShift[]>(this.apiUrl + `calendar/${calendarId}/shifts`);
  }

  public getCalendarAbsences(calendarId): Promise<CalendarAbsence[]> {
    return this.get<CalendarAbsence[]>(this.apiUrl + `calendar/${calendarId}/absences`);
  }

  public getCalendarMeetings(calendarId): Promise<CalendarMeeting[]> {
    return this.get<CalendarMeeting[]>(this.apiUrl + `calendar/${calendarId}/meetings`);
  }

  public getCalendarProductionOrders(calendarId): Promise<CalendarProductionOrder[]> {
    return this.get<CalendarProductionOrder[]>(this.apiUrl + `calendar/${calendarId}/production-orders`);
  }

  public getCalendarEvents(calendarId): Promise<CalendarEntityEvent[]> {
    return this.get<CalendarEntityEvent[]>(this.apiUrl + `calendar/${calendarId}/events`);
  }

  public addCalendarEvent(calendarId, data): Promise<ReturnData<CalendarEntityEvent>> {
    return this.post<ReturnData<CalendarEntityEvent>>(this.apiUrl + `calendar/${calendarId}/event`, data);
  }

  public updateCalendarEvent(calendarId, eventId, data): Promise<ReturnData<CalendarEntityEvent>>{
    return this.put<ReturnData<CalendarEntityEvent>>(this.apiUrl + `calendar/${calendarId}/event/${eventId}`, data);
  }

  public deleteCalendarEvent(calendarId, eventId): Promise<CalendarEntityEvent[]> {
    return this.delete<CalendarEntityEvent[]>(this.apiUrl + `calendar/${calendarId}/event/${eventId}`);
  }
}
