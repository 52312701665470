import { Component } from '@angular/core';

@Component({
  selector: 'app-spinner',
  template:
    `<mat-spinner></mat-spinner>`
})
export class SpinnerComponent {

  constructor() { }
}
