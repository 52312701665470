import { SpinnerService } from '../services/spinner.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerType } from '../common/enums';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(public loaderService: SpinnerService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get('useSpinner') == SpinnerType.Big + '') {
      this.loaderService.showSpinner();
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (req.headers.get('useSpinner') == SpinnerType.Big + '') {
          this.loaderService.stopSpinner();
        }
      })
    );
  }
}
