<div class="listHeight">

  <mat-table [dataSource]="dataSource">
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="semi_prod_name">
      <mat-header-cell *matHeaderCellDef> {{ "ProdConfigurator.name" | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.semi_prod_name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="category_name">
      <mat-header-cell *matHeaderCellDef> {{ "ProdConfigurator.category" | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.category_name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="qty">
      <mat-header-cell *matHeaderCellDef> {{ "ProdConfigurator.qty" | translate}} </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.qty}} </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
