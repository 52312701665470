<div class="teamHeight">

    <button mat-icon-button color="primary" style="margin-bottom: 20px;" (click)="selectEntityItemDiag()"
      [disabled]="disabled">
      <mat-icon>add</mat-icon>
    </button>
    <table class="full-width"   aria-hidden="true">

      <tbody>
        <tr *ngFor="let participant of participants;let i = index">
          <td>{{i+1}})</td>
          <td>
            <app-show-avatar [USER]="participant"></app-show-avatar>
          </td>
          <td>{{participant.firstname}}</td>
          <td>{{participant.lastname}}</td>
          <td>(@{{participant.nickname}})</td>
          <td>
            <button mat-icon-button color="warn" (click)="removeMember(i)" [disabled]="disabled">
              <mat-icon>clear</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
