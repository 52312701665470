import { Component, DestroyRef, OnInit, ViewChild } from '@angular/core'
import { MatSidenav } from '@angular/material/sidenav'
import { DashboardService } from '../../services/dashboard.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav
  language = ''

  constructor(
    private dashboardService: DashboardService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.dashboardService.toggleSidebar
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.sidenav.toggle()
      })
  }
}
