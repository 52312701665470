import {Component, OnChanges, OnInit, Input, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { Department } from 'src/app/model';
import { OrgChart } from 'd3-org-chart';

@Component({
  selector: 'app-department-tree',
  templateUrl: './department-tree.component.html',
  styleUrls: ['./department-tree.component.css']
})
export class DepartmentTreeComponent implements AfterViewInit, OnChanges, OnInit {
  @Input() department_tree: Department;
  @ViewChild('chartContainer') chartContainer: ElementRef;
  chart: any;
  departmentsData: any[] = [];
  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    this.updateChart();
  }

  ngOnChanges() {
    this.updateChart();
  }

  updateChart() {
    if (!this.departmentsData) {
      return;
    }
    if (!this.chart) {
      return;
    }
    this.departmentsData = [];
    this.getDepartmentData(this.department_tree, null);
    this.chart
      .container(this.chartContainer.nativeElement)
      .data(this.departmentsData)
      .nodeWidth(d => 100)
      .nodeHeight(d => 50)
      .nodeContent((node) => {
        return `<div #node style="border: darkgrey solid 1px; background-color: #efefef;
              width:${node.width}px;height:${node.height}px; text-align: center; border-radius: 5%">
              <strong>${node.data.title}</strong><br>${node.data.location}
         </div>`;
      })
      .expandAll()
      .render();
  }

  private getDepartmentData(department: Department, parentId: number) {
    const departNode = {
      id: department.id,
      parentId,
      title: department.title,
      location: department.location
    };

    this.departmentsData.push(departNode);

    if (department.sub_departments.length > 0) {
      for (const subDepartment of department.sub_departments) {
        (this.getDepartmentData(subDepartment, department.id));
      }
    }
  }

}
