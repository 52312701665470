<span style="width: 85%; display: -webkit-inline-box">{{
  notify.data.message
}}</span>
<button
  mat-icon-button
  *ngIf="notify.data.entityChoice"
  (click)="notificationService.openChooseEntityComponent.next(notify)"
>
  <mat-icon>add</mat-icon>
</button>
<button mat-icon-button (click)="read()">
  <mat-icon>mark_email_read</mat-icon>
</button>
