<div class="calendar">
  <mat-grid-list [cols]="titleTabs" [rowHeight]="titleRowHeight">
    <mat-grid-tile>
      <div>
        <button
          mat-flat-button
          color="primary"
          style="margin-right: -3px"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button
          mat-flat-button
          color="primary"
          style="margin-left: -3px"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          <mat-icon>keyboard_arrow_right</mat-icon></button
        >&nbsp;&nbsp;
        <button
          mat-flat-button
          color="primary"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          {{ 'Generic.today' | translate }}
        </button>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <h1>{{ viewDate | calendarDate: view + 'ViewTitle' : 'en' }}</h1>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
          >{{ 'Generic.month' | translate }}</mat-button-toggle
        >
        <mat-button-toggle
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
          >{{ 'Generic.week' | translate }}</mat-button-toggle
        >
        <mat-button-toggle
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
          >{{ 'Generic.day' | translate }}</mat-button-toggle
        >
      </mat-button-toggle-group>
    </mat-grid-tile>
  </mat-grid-list>

  <div [ngSwitch]="view">
    <mwl-calendar-month-view
      *ngSwitchCase="CalendarView.Month"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [activeDayIsOpen]="activeDayIsOpen"
      (dayClicked)="dayClicked($event.day)"
      (eventClicked)="onEventClicked($event.event)"
      (beforeViewRender)="updateCalendarEvents($event)"
    >
    </mwl-calendar-month-view>
    <mwl-calendar-week-view
      *ngSwitchCase="CalendarView.Week"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="onEventClicked($event.event)"
      (beforeViewRender)="updateCalendarEvents($event)"
    >
    </mwl-calendar-week-view>
    <mwl-calendar-day-view
      *ngSwitchCase="CalendarView.Day"
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      (eventClicked)="onEventClicked($event.event)"
      (beforeViewRender)="updateCalendarEvents($event)"
    >
    </mwl-calendar-day-view>
  </div>
  <br />
  <button
    *ngIf="ITEMID !== 'new'"
    mat-fab
    color="primary"
    class="add-button"
    matTooltip="{{ 'Calendar.new_event' | translate }}"
    (click)="editEvent(null, false)"
  >
    <i class="material-icons">add</i>
  </button>
</div>
