import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EntitySelectorDiagComponent } from 'src/app/common/entity-selector-diag/entity-selector-diag.component';
import { User } from 'src/app/model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-field',
  templateUrl: './user-field.component.html',
  styleUrls: ['./user-field.component.css']
})
export class UserFieldComponent implements OnInit {
  @Input() ITEM: any;
  @Input() disabled: boolean = false;
  user: User;
  constructor(private userService: UserService, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.ITEM && this.ITEM.value) {
      this.getUser();
    }
  }

  getUser() {
    this.userService.getUserCommon(this.ITEM.value).then(resUser => {
      this.user = resUser;
    });
  }


  public selectUserDiag() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      entityCode: 'user',
      // entity_id: this.entity.id
    };

    const dialogRef = this.dialog.open(EntitySelectorDiagComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != null) {

        this.ITEM.value = result.id;
        this.user = result;

      }
    });
  }

}
