import { ReturnData, Setting } from './../model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService  extends BaseService {
  public settings:Setting[] = [];
  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public saveSetting(setting:Setting): Promise<ReturnData<any>> {
    return this.post<ReturnData<any>>(this.apiUrl + 'settings', setting);
  }

  public getSettings(key:string): Promise<Setting[]> {
    return this.get<Setting[]>(this.apiUrl + 'settings/'+ key);
  }

  public getSettingsForAdmin(): Promise<Setting[]> {
    return this.get<Setting[]>(this.apiUrl + 'admin/settings');
  }
  public saveSettingsForAdmin(settings:Setting[]): Promise<Setting[]> {
    return this.post<Setting[]>(this.apiUrl + 'admin/settings',settings);
  }

  public async getAllSettings() {
    this.settings = await this.get<Setting[]>(this.apiUrl + 'settings');
  }

  public getSavedSettings(key:string):Setting[]{
    return this.settings.filter(x=> x.key == key);
  }


  public delSettingByKey(key:string): Promise<ReturnData<any>> {
    return this.delete<ReturnData<any>>(this.apiUrl + 'settings/'+ key);
  }

  public delSettingByKeyValue(key:string,value:string): Promise<ReturnData<any>> {
    return this.delete<ReturnData<any>>(this.apiUrl + 'settings/'+ key+'/'+value);
  }

}
