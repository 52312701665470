<div *ngIf="!(isHandset$ | async)">
  <table style="width: 100%;" class="nospace" matSort (matSortChange)="sortData($event)">
    <thead>
      <tr>
        <th style="width:70px;" mat-sort-header="id" scope="col"># </th>
        <th style="width:500px;" mat-sort-header="task_desc" scope="col">{{'Tasks.descr' | translate}}</th>
        <th style="width:300px;" mat-sort-header="task_assignee" scope="col">{{'Tasks.assignee' | translate}}</th>
        <th style="width:150px;" mat-sort-header="task_status" scope="col">{{'Tasks.status' | translate}}</th>
        <th style="width:300px;" mat-sort-header="task_startdate" scope="col">{{'Tasks.startdate' | translate}} </th>
        <th style="width:30px;" mat-sort-header="task_duration" scope="col">{{'Tasks.duration' | translate}}</th>
        <th style="width:150px;" mat-sort-header="task_group" scope="col">{{'Tasks.list' | translate}}</th>
        <th *ngIf="SHOWMOVESPRINT"></th>
      </tr>
    </thead>

    <tbody>

      <tr *ngFor="let task of TASKS" class="taskTr">
        <td [class]="getStatusIdTd(task)" (click)="openTask(task)">#{{task.id}}</td>
        <td (click)="openTask(task)" [class.overdueTask]="isOverdue(task)">{{task.task_desc}}</td>
        <td>
          <div *ngIf="task.task_assignee" style="display: flex; align-items:center;">
            <app-show-avatar [USER]="task.task_assignee"></app-show-avatar>
            <span (click)="openTask(task)">{{task.task_assignee.firstname}}
              {{task.task_assignee.lastname}}</span>
          </div>
        </td>
        <td [class]="getStatusBackground(task)" (click)="openTask(task)"><span
            *ngIf="task.task_status">{{task.task_status}}</span></td>
        <td (click)="openTask(task)"><span *ngIf="task.task_startdate">{{task.task_startdate | date:'yyyy-MM-dd HH:mm'}}</span></td>
        <td (click)="openTask(task)"><span *ngIf="task.task_duration">{{task.task_duration}}</span></td>
        <td (click)="openTask(task)"><span *ngIf="task.task_group"> {{task.task_group.name}}</span></td>
        <td *ngIf="SHOWMOVESPRINT">
          <button mat-icon-button [matMenuTriggerFor]="sprintMenu" [matMenuTriggerData]="{'task':task}">
            <mat-icon>swap_vert</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>

  </table>

</div>





<!--------------------------------------Mobile------------------------------------------>
<div *ngIf="(isHandset$ | async)">
  <mat-list>
    <mat-list-item *ngFor="let task of TASKS">
      <div matListAvatar (click)="openTask(task)">
        <span *ngIf="task.task_assignee" style="display: flex;">
          <app-show-avatar [USER]="task.task_assignee" style="margin-bottom: -10px;"></app-show-avatar>

        </span>
      </div>

      <div mat-line [class.overdueTask]="isOverdue(task)" (click)="openTask(task)">#{{task.id}} {{task.task_desc}} <span
          *ngIf="task.task_duration"> - {{task.task_duration}}</span></div>
      <div mat-line (click)="openTask(task)">{{task.task_assignee.firstname}} {{task.task_assignee.lastname}}</div>

      <div mat-line    *ngIf="task.task_startdate">{{task.task_startdate | date:'yyyy-MM-dd HH:mm'}}</div>
      <div *ngIf="SHOWMOVESPRINT">
        <button mat-icon-button [matMenuTriggerFor]="sprintMenu" [matMenuTriggerData]="{'task':task}">
          <mat-icon>swap_vert</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>

  </mat-list>

</div>

<mat-menu #sprintMenu="matMenu">
  <ng-template matMenuContent let-task="task">


    <button mat-menu-item *ngFor="let sprint of SPRINTS" (click)="moveTaskToSprint(sprint,task)">
      <mat-icon>move_down</mat-icon>{{sprint.name}}
    </button>
  </ng-template>
</mat-menu>
