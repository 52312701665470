


<mat-list class="approvals-list">
  <mat-list-item (click)="add_approval_diag()" style="cursor: pointer;">
    <mat-icon matListItemIcon>add</mat-icon>
    <div style="font-size: 14px;">{{ 'Approvals.newApproval' | translate}}</div>
  </mat-list-item>
  <mat-list-item *ngFor="let approval of approvals" (click)="onSelectApproval(approval)" style="cursor: pointer;">
    <div style="font-size: 14px;"> {{approval.applicant.firstname}} {{approval.applicant.lastname}} -
      <strong>
        <span *ngIf="approval.decision == 1">{{ 'Approvals.approval' | translate}}</span>
        <span *ngIf="approval.decision == 2">{{ 'Approvals.rejection' | translate}}</span>
        <span *ngIf="approval.decision == null">{{ 'Approvals.waitingForApproval' | translate}}</span>
      </strong>
    </div>
    <div> {{approval.message?.substring(0, 40)}}<span *ngIf="approval.message?.length >40">...</span></div>
    <div style="font-size: 10px;">
      <span *ngIf="approval.decision"> {{ approval.decided_at | date:'y-M-d HH:mm'}} </span>
    </div>
  </mat-list-item>
</mat-list>
