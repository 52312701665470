<mat-menu>
  <span *ngFor="let entity of entityService.storedUserEntities$ | async">
    <button mat-menu-item (click)="SELECTENTITY.emit(entity.entity_code)">
      <mat-icon>{{ ICON }}</mat-icon>
      <span>{{ language === 'en' ? entity.name_en : entity.name_gr }}</span>
    </button>
  </span>

  <span
    *ngFor="let entity of entityService.storedActiveCustomEntities$ | async"
  >
    <button mat-menu-item (click)="SELECTENTITY.emit(entity.entity_code)">
      <mat-icon>{{ ICON }}</mat-icon>
      <span>{{ language === 'en' ? entity.name_en : entity.name_gr }}</span>
    </button>
  </span>
</mat-menu>
