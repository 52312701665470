import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { MatMenu } from '@angular/material/menu'
import { EntityService } from 'src/app/services/entity.service'

import { LoginService } from 'src/app/services/login.service'

@Component({
  selector: 'app-entity-menu-selector',
  templateUrl: './entity-menu-selector.component.html',
  styleUrls: ['./entity-menu-selector.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityMenuSelectorComponent implements OnInit {
  @Output() SELECTENTITY = new EventEmitter<string>()
  @ViewChild(MatMenu, { static: true }) menu: MatMenu
  @Input() ICON: string = 'library_add'
  language = ''

  constructor(
    private loginService: LoginService,
    public entityService: EntityService
  ) {}

  ngOnInit(): void {
    this.language = this.loginService.getLoginUser().lang
  }
}
