<ngx-scanner-qrcode
  #scanner
  (event)="selectQRCode($event)"
></ngx-scanner-qrcode>
<br />
<div
  *ngIf="scanner.devices.value.length > 1"
  style="display: flex; justify-content: center"
>
  <button mat-fab color="primary" (click)="changeCamera()">
    <mat-icon>switch_camera</mat-icon>
  </button>
</div>
