import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
  @Input() ITEM: any;
  @Input() disabled = false;
  @Input() title = '';
  @Input() isMandatory = '0';
  options: string[] = [];

  constructor() { }

  ngOnInit(): void {
    this.options = this.ITEM.for_entity.split('|');
    if (this.ITEM.value == null) {
      this.ITEM.value = this.options[0];
    }

  }



}
