import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DatePipe, NgIf } from '@angular/common';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { enGB } from 'date-fns/locale';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/yyyy',
  },
  display: {
    dateInput: 'MM/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: enGB },
    DatePipe,
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
  ],
})
export class MonthPickerComponent implements OnInit {
  @Input() ITEM: any;
  @Input() disabled = false;
  @Input() title = '';
  @Input() isMandatory = '0';

  date = new FormControl();

  constructor(private datePipe: DatePipe) {}

  ngOnInit() {
    if (this.ITEM.value !== null) {
      const value = this.ITEM.value.split('-');
      const ctrlValue = new Date(Number(value[0]), Number(value[1]) - 1);
      this.date.setValue(ctrlValue);
    }
    if (this.disabled) {
      this.date.disable();
    }
  }

  setMonthAndYear(selectedDate: Date, datepicker: MatDatepicker<Date>) {
    this.date.setValue(selectedDate);
    this.ITEM.value = this.datePipe.transform(selectedDate, 'yyyy-MM');
    datepicker.close();
  }
}
