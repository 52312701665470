<div class="event-window">
  <div class="buttonPanel mat-elevation-z3">
    <button mat-button [mat-dialog-close]="null">
      <mat-icon>keyboard_backspace</mat-icon>{{'Generic.back' | translate}}
    </button>
    <mat-divider [vertical]="true"></mat-divider>
    <button mat-button (click)="save()" *ngIf="!data.disable">
      <mat-icon>save</mat-icon>{{'Generic.save' | translate}}
    </button>
    <mat-divider [vertical]="true"></mat-divider>
  </div>
  <br>
  <mat-card>
    <mat-form-field class="full-width">
         <mat-label class="title">Τίτλος*</mat-label>
    <input matInput type="text"  [(ngModel)]="event.title" [readonly]="data.disable">
    </mat-form-field>

    <br>
    <mat-form-field class="full-width">
        <mat-label class="title">Περιγραφή</mat-label>
    <input matInput type="text"  [(ngModel)]="event.description" [readonly]="data.disable">
  </mat-form-field>

    <br>
    <span>Έναρξη*</span>
    <mat-form-field class="full-width">
      <input [owlDateTimeTrigger]="dt1" matInput [owlDateTime]="dt1" [(ngModel)]="event.start_date" [readonly]="data.disable">
      <span class="calendarbtn" matSuffix [owlDateTimeTrigger]="dt1">
        <mat-icon color="primary">calendar_today</mat-icon>
      </span>
      <owl-date-time #dt1 [disabled]="data.disable"></owl-date-time>
    </mat-form-field>
    <br>
    <span>Λήξη*</span>
    <mat-form-field class="full-width">
      <input [owlDateTimeTrigger]="dt2" matInput [owlDateTime]="dt2" [(ngModel)]="event.end_date" [readonly]="data.disable">
      <span class="calendarbtn" matSuffix [owlDateTimeTrigger]="dt2">
        <mat-icon color="primary">calendar_today</mat-icon>
      </span>
      <owl-date-time #dt2 [disabled]="data.disable"></owl-date-time>
    </mat-form-field>
    <br>
    Συμμετέχοντες
   <app-event-team [EVENT]="event" [disabled]="data.disable"></app-event-team>
  </mat-card>
</div>
