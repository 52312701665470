<h1 mat-dialog-title>{{ 'Projects.newSprint' | translate }}</h1>
<div mat-dialog-content>

  <mat-form-field class="example-user-input">
    <mat-label>{{ 'Tasks.sprintTitle' | translate }}</mat-label>
    <input matInput type="text" cdkFocusInitial [(ngModel)]="sprint.name">
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">{{ 'Generic.cancel' | translate }}</button>
  <button mat-button [mat-dialog-close]="sprint">{{ 'Generic.save' | translate }}</button>
</div>
