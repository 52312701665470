


export class InstallationLine {
  id: number;
  entdata_id: number;
  installation_id: number;
  installation: any;
  hours: number;
  created_at: Date;
  updated_at: Date;
}
