import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Photo } from 'src/app/models/image';
import { LayoutService } from '../../../../../../services/layout.service';

@Component({
  selector: 'app-gallery-carusel',
  templateUrl: './gallery-carusel.component.html',
  styleUrls: ['./gallery-carusel.component.css'],
})
export class GalleryCaruselComponent implements OnInit {
  photo: Photo;
  photolist: Photo[] = [];
  currentIndex: number;

  constructor(
    private dialogRef: MatDialogRef<GalleryCaruselComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.photo = this.data.photo;
    this.photolist = this.data.photolist;
    this.currentIndex = this.data.index;
    this.getPhotoData();
  }

  getPhotoData() {}

  goNext() {
    this.currentIndex++;
    this.photo = this.photolist[this.currentIndex];
    this.getPhotoData();
  }

  goPrevious() {
    this.currentIndex--;
    this.photo = this.photolist[this.currentIndex];
    this.getPhotoData();
  }

  public goBack() {
    this.dialogRef.close(null);
  }
}
