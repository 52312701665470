<div style="display: flex">
  <mat-card style="width: 100%">
    <mat-card-subtitle>
      {{ 'ProdSchedule.orderlist' | translate }}
    </mat-card-subtitle>

    <div *ngIf="!(layoutService.isHandset$ | async)">
      <table style="width: 100%" class="nospace">
        <thead>
          <tr>
            <th style="width: 70px; text-align: start" scope="col">#</th>
            <th style="width: 100px; text-align: start" scope="col">
              {{ 'ProdSchedule.prodMonth' | translate }}
            </th>
            <th style="width: 100px; text-align: start" scope="col">
              {{ 'ProdSchedule.status' | translate }}
            </th>
            <th style="width: 300px; text-align: start" scope="col">
              {{ 'ProdSchedule.product' | translate }}
            </th>
            <th style="width: 100px; text-align: start" scope="col">
              {{ 'ProdSchedule.productionQty' | translate }}
            </th>
            <th style="width: 300px; text-align: start" scope="col">
              {{ 'ProdSchedule.productionLine' | translate }}
            </th>
            <th style="width: 70px; text-align: start" scope="col">
              {{ 'ProdSchedule.productiontime' | translate }}
            </th>
            <th scope="col"></th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let order of SPRINT.orders" class="taskTr">
            <td (click)="openTask(order)">{{ order.id }}</td>
            <td (click)="openTask(order)">
              {{ order | prodScheduleSprint: 'month' }}
            </td>
            <td (click)="openTask(order)">
              {{ order | prodScheduleSprint: 'status' }}
            </td>
            <td (click)="openTask(order)">{{ order.productName }}</td>
            <td (click)="openTask(order)">
              {{ order | prodScheduleSprint: 'quantity' }}
            </td>
            <td (click)="openTask(order)">
              {{ order.installationName }}
            </td>
            <td (click)="openTask(order)">
              {{ order | prodScheduleSprint: 'time' }}
            </td>
            <button
              mat-icon-button
              [matMenuTriggerFor]="sprintMenu"
              [matMenuTriggerData]="{ order: order }"
            >
              <mat-icon>swap_vert</mat-icon>
            </button>
          </tr>
        </tbody>
      </table>
    </div>

    <!--------------------------------------Mobile------------------------------------------>
    <div *ngIf="layoutService.isHandset$ | async">
      <mat-list>
        <mat-list-item *ngFor="let order of SPRINT.orders">
          <div mat-line (click)="openTask(order)">
            #{{ order.id }} {{ order | prodScheduleSprint: 'month' }} -
            {{ order | prodScheduleSprint: 'status' }}
          </div>
          <div mat-line (click)="openTask(order)">
            {{ order.productName }} - {{ order.installationName }}
          </div>
          <div mat-line (click)="openTask(order)">
            Qty: {{ getQty(order) }} - Time:{{ getTime(order) }}
          </div>
          <div>
            <button
              mat-icon-button
              [matMenuTriggerFor]="sprintMenu"
              [matMenuTriggerData]="{ order: order }"
            >
              <mat-icon>swap_vert</mat-icon>
            </button>
          </div>

          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card>

  <mat-card
    class="burndownChartCard"
    *ngIf="!(layoutService.isHandset$ | async)"
  >
    <mat-card-subtitle>
      {{ 'ProdSchedule.prodLineChart' | translate }} - {{ SPRINT.name }}
    </mat-card-subtitle>
    <span>
      <google-chart [data]="chart"></google-chart
      ><!-- -->
    </span>
  </mat-card>
</div>

<mat-card
  style="width: 100%; overflow-x: auto; margin-top: 10px"
  *ngIf="layoutService.isHandset$ | async"
  (click)="burnDownChartExpand = !burnDownChartExpand"
>
  <span>
    {{ 'ProdSchedule.prodLineChart' | translate
    }}<mat-icon>query_stats</mat-icon></span
  >
  <span>
    <mat-icon class="arrowPos" *ngIf="!burnDownChartExpand"
      >expand_more</mat-icon
    >
    <mat-icon class="arrowPos" *ngIf="burnDownChartExpand"
      >expand_less</mat-icon
    >
  </span>
  <google-chart [data]="chart" *ngIf="burnDownChartExpand"></google-chart>
</mat-card>

<mat-menu #sprintMenu="matMenu">
  <ng-template matMenuContent let-order="order">
    <button
      mat-menu-item
      *ngFor="let sprint of SPRINTS"
      (click)="moveOrderToSprint(sprint, order)"
    >
      <mat-icon>move_down</mat-icon>{{ sprint.name }}
    </button>
  </ng-template>
</mat-menu>
