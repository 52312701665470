<div style="display:flex; align-items:center; justify-content:center;  height: 40px; width: 40px;">
  <mat-icon *ngIf="avatarBase64 == ''" style="transform: scale(1.4)">
    account_circle
  </mat-icon>
  <img *ngIf="avatarBase64 != ''" [src]="avatarBase64" class="avatar" >
  <div *ngIf="SHOWCONNECTIVITY"  class="connectBullet" [class.connected]="isUserConnected()"
       [class.disconneted]="!isUserConnected()" (click)="openChatDiag()">
  </div>
</div>



