import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EntityItemFilter } from '../common/filters'
import {
  ConfQuote,
  ReturnData,
  Field,
  ConfQuoteLine,
  ProductValidation,
} from '../model'
import { BaseService } from './base.service'

@Injectable({
  providedIn: 'root',
})
export class QuoteService extends BaseService {
  constructor(private httpClient: HttpClient) {
    super(httpClient)
  }

  public saveQoute(quote: ConfQuote): Promise<ReturnData<any>> {
    return this.post<ReturnData<any>>(this.apiUrl + 'quoteconf', quote)
  }

  public getProductList(
    filters: EntityItemFilter,
    qouteId: string
  ): Promise<any> {
    return this.post<any>(
      this.apiUrl + 'quoteconf/productlist/' + qouteId,
      filters
    )
  }

  public getSavedConf(qouteId: string): Promise<ConfQuoteLine[]> {
    return this.get<ConfQuoteLine[]>(this.apiUrl + 'quoteconf/' + qouteId)
  }

  public getExtraFlds(qouteId: string): Promise<Field[]> {
    return this.get<Field[]>(
      this.apiUrl + 'quoteconf/settings/extraflds/' + qouteId
    )
  }

  public validateProduct(
    productId: number,
    codeFieldId: number,
    codeFieldValue: string
  ): Promise<ProductValidation> {
    return this.post<ProductValidation>(
      this.apiUrl + 'quoteconf/productlist/validate-product',
      {
        productId,
        codeFieldId,
        codeFieldValue,
      }
    )
  }
}
