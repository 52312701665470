import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SpinnerType } from '../common/enums';
import { ReturnData, ChannelChat, ChannelDetail } from '../model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ChannelService extends BaseService {


  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getChannelHistory(itemId: string): Promise<ChannelChat[]> {
    return this.get<ChannelChat[]>(this.apiUrl + 'entity/channel/' + itemId);
  }

  public newChannelChat(chat:ChannelChat): Promise<ChannelChat> {
    return this.post<ChannelChat>(this.apiUrl + 'entity/channel/'+chat.entdata_id, chat, SpinnerType.None);
  }

  public setReaction(chatDetail:ChannelDetail): Promise<ChannelChat> {
    return this.put<ChannelChat>(this.apiUrl + 'entity/channel/reaction', chatDetail, SpinnerType.None);
  }


  uploadAttachment(formData,itemId) {
    return this.http.post<any>(`${this.apiUrl + 'entity/channel/attachment/'+itemId}`, formData, {
      reportProgress: true,
      observe: 'events',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      //  catchError(this.handleFileError)
    );
  }

  public downloadFIle(chat: ChannelChat): Promise<any> {
    return this.getFile<any>(this.apiUrl + 'entity/channel/attachment/' + chat.id);
  }

  public markAsRead(itemId:string): Promise<any> {
    return this.get<any>(this.apiUrl + 'entity/channel/markasread/' + itemId,SpinnerType.None);
  }


}
