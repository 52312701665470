import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerType } from 'src/app/common/enums';
import { BaseFilter } from 'src/app/common/filters';
import { FilterHelper } from 'src/app/common/helper';
import { PagedData, ReturnData } from 'src/app/model';
import { ERPWarehouse, ERPWhStocks } from 'src/app/models/erpwarehouse';
import { ERPSpdocline } from 'src/app/models/spdocline';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class ErpWarehouseService extends BaseService {


  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getActiveWarehouses(): Promise<ERPWarehouse[]> {
    return this.get<ERPWarehouse[]>(this.apiUrl + 'erp/warehouses');
  }


  public getWarehousesTree(): Promise<ERPWarehouse> {
    return this.get<ERPWarehouse>(this.apiUrl + 'erp/warehousestree');
  }

  public storeWarehouse(warehoust: ERPWarehouse): Promise<ReturnData<ERPWarehouse>> {
    return this.post<ReturnData<ERPWarehouse>>(this.apiUrl + 'erp/warehouses', warehoust);
  }

  public updateWarehouse(warehoust: ERPWarehouse): Promise<ReturnData<ERPWarehouse>> {

    return this.put<ReturnData<ERPWarehouse>>(this.apiUrl + 'erp/warehouses/' + warehoust.id, warehoust);
  }


  public getAllWarehouses(filters: BaseFilter): Promise<ERPWarehouse[]> {

    let queryString = FilterHelper.QueryStringHelper(filters);
    return this.get<ERPWarehouse[]>(this.apiUrl + 'erp/warehouses_all' + queryString);
  }

  public getSingleWarehouse(id: number): Promise<ERPWarehouse> {
    return this.get<ERPWarehouse>(this.apiUrl + 'erp/warehouses/' + id);
  }



  public getProductWhStocks(product_id: number): Promise<ERPWhStocks[]> {
    return this.get<ERPWhStocks[]>(this.apiUrl + 'erp/product/' + product_id + '/stocks');
  }

  public getProductWhHistory(filters: BaseFilter, product_id: number, warehouse_id: number): Promise<PagedData<ERPSpdocline>> {
    return this.get<PagedData<ERPSpdocline>>(this.apiUrl + 'erp/history/' + product_id + '/' + warehouse_id + '/warehouse?page=' + filters.currentPage + "&pageSize=" + filters.pageSize);
  }

}
