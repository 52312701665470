import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApprovalFilter } from '../common/filters';
import {  PagedData, ReturnData } from '../model';
import { Approval, Assignee } from '../models/approval';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }



  public getUserApprovalsForSign(filters: ApprovalFilter): Promise<PagedData<Approval>> {
    return this.post<PagedData<Approval>>(this.apiUrl + `approvals/forsign?page=${filters.currentPage}`, filters);
  }
   public getUserApprovalsRequests(filters: ApprovalFilter): Promise<PagedData<Approval>> {
    return this.post<PagedData<Approval>>(this.apiUrl + `approvals/requests?page=${filters.currentPage}`, filters);
  }

  public getSingleApproval(approvalId:string): Promise<Approval> {
    return this.get<Approval>(this.apiUrl + 'approval/'+approvalId);
  }


  public addApproval(approval:Approval): Promise<ReturnData<Approval>> {
    return this.post<ReturnData<Approval>>(this.apiUrl + 'approval', approval);
  }



  public approve(assignee:Assignee,approvalId): Promise<Approval> {
    return this.post<Approval>(this.apiUrl + "approval/approve/"+approvalId,assignee);
  }


  public reject(assignee:Assignee,approvalId): Promise<Approval> {
    return this.post<Approval>(this.apiUrl + "approval/reject/"+approvalId,assignee);
  }

  public approveAndFrw(assignee:Assignee,approvalId,userId): Promise<Approval> {
    return this.post<Approval>(this.apiUrl + "approval/approveFrw/"+approvalId+"/"+userId,assignee);
  }

  public getApprovalTree(approvalId:string): Promise<Approval[]> {
    return this.get<Approval[]>(this.apiUrl + "approval/tree/"+approvalId);
  }

  public getApprovalsForItem(itemId:string): Promise<Approval[]> {
    return this.get<Approval[]>(this.apiUrl + "approvals/item/"+itemId);
  }


}
