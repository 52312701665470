import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../services/notification.service';
import { UserService } from '../services/user.service';
import { userSearchFilterPref } from '../model';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  loginError: boolean = false;
  userPrefs: userSearchFilterPref[] = [];

  constructor(
    public loginService: LoginService,
    private router: Router,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private userService: UserService,
    public layoutService: LayoutService
  ) {}

  ngOnInit(): void {}

  public login() {
    let formData = new FormData();
    formData.append('email', this.email);
    formData.append('password', this.password);

    this.loginService.login(formData).then((response) => {
      if (response.success) {
        console.log(response);
        this.loginError = false;
        this.setUpSession(response);
      } else {
        this.loginError = true;
      }
    });
  }

  private setUpSession(response: any) {
    this.loginService.setSession(response.success.token, response.success.user);
    this.userService.getUserSearchFilterPrefs().then((res) => {
      this.userPrefs = res;
      this.userPrefs.forEach((x) => {
        console.log(typeof x.entfield_id);
      });
      localStorage.setItem('searchFilterPrefs', JSON.stringify(this.userPrefs));
    });
    this.notificationService.showWelcomeMessage(
      response.success.user.firstname
    );
    this.translate.use(response.success.user.lang);
    this.router.navigate(['']);
  }
}
