
<h1 mat-dialog-title>{{'Generic.copy' | translate}}</h1>
<div mat-dialog-content>


  <mat-form-field >
    <mat-label class="title">{{'Generic.copies' | translate}}
    </mat-label>
    <input matInput type="number" [value]="1"  [(ngModel)]="amount" >
  </mat-form-field>


</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null" >{{'Generic.cancel' | translate}}</button>
  <button mat-button [mat-dialog-close]="amount" cdkFocusInitial>{{'Generic.copy' | translate}}</button>
</div>
