import { Component, Input, OnInit } from '@angular/core';
import { Taskgroup } from 'src/app/model';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'app-taskgroup-field',
  templateUrl: './taskgroup-field.component.html',
  styleUrls: ['./taskgroup-field.component.css']
})
export class TaskgroupFieldComponent implements OnInit {
  @Input() ITEM: any;
  @Input() disabled: boolean = false;
  taskgroups: Taskgroup[] = [];
  constructor(private porjectService: TaskService) { }

  ngOnInit(): void {
    this.ITEM.value = +this.ITEM.value;
    this.getspirnts();
  }

  public getspirnts() {
    this.porjectService.getUserGroups().then(resSp => {
      this.taskgroups = resSp;
    });

  }
}
