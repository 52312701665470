import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpenseLine } from '../model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService extends BaseService {

  constructor(private httpClient: HttpClient) {
    super(httpClient);
  }

  public getSavedLines(entdataId: string): Promise<ExpenseLine[]> {
    return this.get<ExpenseLine[]>(this.apiUrl + 'expenselines/' + entdataId);
  }




}
