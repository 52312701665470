<mat-toolbar color="primary" class="customToolbar">
  <button
    class="icon-button"
    mat-icon-button
    (click)="dashboardService.toggleSidebar.next(true)"
  >
    <img
      class="icon"
      ngSrc="../../assets/icons/icon-72x72.png"
      alt="teamwork-logo"
      height="72"
      width="72"
    />
  </button>

  <nav
    *ngIf="!(isHandset$ | async)"
    style="width: 350px; display: flex; align-items: center"
  >
    <a routerLink="" class="mat-h4 customerTopMargin">TeamWork OS</a>
    <app-breadcrumb></app-breadcrumb>
  </nav>

  <span class="example-spacer"></span>
  <div class="search-box" *ngIf="!(isHandset$ | async)">
    <input
      type="text"
      class="input"
      placeholder="Type to search"
      [(ngModel)]="globalsearchStr"
      (keydown.enter)="globalSearch()"
    />
    <mat-icon class="globalSearchIcon" (click)="globalSearch()"
      >search</mat-icon
    >
  </div>

  <span class="example-spacer"></span>

  <button
    mat-icon-button
    *ngIf="isHandset$ | async"
    class="globalSearchIcon"
    [matMenuTriggerFor]="globalSearchInput"
  >
    <mat-icon>search</mat-icon>
  </button>

  <button
    mat-icon-button
    class="globalSearchIcon"
    [matMenuTriggerFor]="EntitySelectionMenu.menu"
    matTooltip="{{ 'welcome.quickCreator' | translate }}"
  >
    <mat-icon>library_add</mat-icon>
  </button>

  <button
    mat-icon-button
    class="globalSearchIcon"
    [matMenuTriggerFor]="favoriteSelectionMenu"
    (menuClosed)="saveFavorites()"
    matTooltip="{{ 'welcome.addToFavorites' | translate }}"
  >
    <mat-icon>star_border</mat-icon>
  </button>

  <!--  <span class="example-spacer"></span>-->
  <button
    mat-icon-button
    #questionsTrigger="matMenuTrigger"
    [matMenuTriggerFor]="questionsMenu"
    [matBadge]="(webSocket.questions$ | async).length"
    [matBadgeHidden]="(webSocket.questions$ | async).length == 0"
    matBadgeColor="warn"
    class="notif-button"
    matTooltip="{{ 'Generic.questions' | translate }}"
  >
    <mat-icon>help_outline</mat-icon>
  </button>

  <button
    mat-icon-button
    #notificationsTrigger="matMenuTrigger"
    [matMenuTriggerFor]="notificationsMenu"
    [matBadge]="(webSocket.notifications$ | async).length"
    [matBadgeHidden]="(webSocket.notifications$ | async).length == 0"
    matBadgeColor="warn"
    class="notif-button"
    matTooltip="{{ 'Generic.notifications' | translate }}"
  >
    <mat-icon>notifications</mat-icon>
  </button>

  <span *ngIf="!(isHandset$ | async)" class="mat-h3 customerTopMargin"
    >{{ currentUser.firstname }} {{ currentUser.lastname }}</span
  >
  <button
    mat-icon-button
    class="example-icon"
    [matMenuTriggerFor]="usermenu"
    style="display: flex; justify-content: center; align-items: center"
  >
    <app-show-avatar
      [SHOWCONNECTIVITY]="false"
      [USER]="currentUser"
    ></app-show-avatar>
  </button>
</mat-toolbar>

<app-sidebar></app-sidebar>

<mat-menu #usermenu="matMenu">
  <button mat-menu-item routerLink="/profile">
    <mat-icon>settings</mat-icon>
    <span>{{ 'Generic.profile' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="pwaService.promptEvent"
    (click)="pwaService.installPwa()"
  >
    <mat-icon *ngIf="!(isHandset$ | async)">install_desktop</mat-icon>
    <mat-icon *ngIf="isHandset$ | async">install_mobile</mat-icon>
    <span>{{ 'Generic.pwaInstall' | translate }}</span>
  </button>

  <button
    mat-menu-item
    *ngIf="platform.SAFARI && !pwaService.promptEvent"
    (click)="requestPushNotificationsPermissionIOS()"
  >
    <mat-icon *ngIf="!(isHandset$ | async)">install_desktop</mat-icon>
    <mat-icon *ngIf="isHandset$ | async">install_mobile</mat-icon>
    <span>{{ 'Generic.enablePushNotifications' | translate }}</span>
  </button>

  <button mat-menu-item (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
    <span>{{ 'Generic.logout' | translate }}</span>
  </button>
</mat-menu>

<ng-template
  #moduleCardMenu
  style="background-color: red"
  let-icon="icon"
  let-title="title"
  let-action="action"
  let-scope="scope"
>
  <div
    class="navMenuItem"
    *ngIf="hasModulePermission(scope)"
    (click)="openModule(action)"
  >
    <mat-icon>{{ icon }}</mat-icon>
    <div style="margin-top: 3px; font-size: 15px; margin-left: 3px">
      {{ title }}
    </div>
  </div>
</ng-template>

<mat-menu #questionsMenu="matMenu" class="notification-menu">
  <mat-list role="list" style="padding: 0">
    <mat-list-item
      *ngFor="let question of webSocket.questions$ | async"
      role="listitem"
      class="notifText"
      style="height: 400px"
    >
      <div matListItemTitle>
        {{ getEntityTranslate(question.item.entity) }}:
        <strong
          >#{{ question.entdata_id }} -
          {{ getFieldTranslate(question.field) }}</strong
        >
      </div>
      <div matListItemLine>{{ question.message }}</div>
      <div matListItemLine style="font-size: 9px">
        {{ question.sender.firstname }} {{ question.sender.lastname }}(@{{
          question.sender.nickname
        }})
      </div>
      <div
        matListItemLine
        style="display: flex; justify-content: end; height: 48px"
      >
        <button
          mat-icon-button
          color="primary"
          (click)="openItem(question.item.id, question.item.entity.entity_code)"
        >
          <mat-icon>visibility</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          (click)="webSocket.answerQuestion(question); $event.stopPropagation()"
        >
          <mat-icon>task_alt</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>
    <mat-list-item
      *ngIf="(webSocket.questions$ | async).length === 0"
      role="listitem"
    >
      <div mat-line class="notifText">
        {{ 'Messages.noUnansweredQuestions' | translate }}
      </div>
    </mat-list-item>
  </mat-list>
</mat-menu>

<mat-menu #notificationsMenu="matMenu" class="notification-menu">
  <mat-list role="list" style="padding: 0">
    <mat-list-item
      *ngFor="let notification of webSocket.notifications$ | async"
      role="listitem"
      class="notifText"
      style="height: 400px"
    >
      <div matListItemTitle>
        {{ getTrancatedMsg(notification.data.message) }}
      </div>
      <div
        matListItemLine
        style="height: 50px; display: flex; justify-content: flex-end"
      >
        <button
          mat-icon-button
          *ngIf="
            notification.data.entdata &&
            notification.data.entity_code &&
            notification.data.entity_code != 'calendar'
          "
          color="primary"
          (click)="
            openItem(notification.data.entdata, notification.data.entity_code)
          "
        >
          <mat-icon>visibility</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          *ngIf="notification.data.non_entity_type === 'approval'"
          (click)="
            openItem(
              notification.data.non_entity_id,
              notification.data.non_entity_type
            )
          "
        >
          <mat-icon>visibility</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          *ngIf="notification.data.entity_code === 'calendar'"
          (click)="openEvent(notification.data)"
        >
          <mat-icon>visibility</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          *ngIf="notification.data.entityChoice"
          (click)="chooseEntdataFromEntity(notification)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          (click)="
            webSocket.readNotification(notification); $event.stopPropagation()
          "
        >
          <mat-icon>mark_email_read</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider>
    </mat-list-item>
    <mat-list-item
      (click)="webSocket.readAllNotifications()"
      *ngIf="(webSocket.notifications$ | async).length > 1"
      id="mark-as-read"
      role="listitem"
      class="notifText"
    >
      <div matListItemTitle style="font-weight: bold">
        {{ 'Generic.markAllAsRead' | translate }}
      </div>
      <mat-icon matListItemIcon style="color: #2a7189 !important"
        >mark_email_read</mat-icon
      >
    </mat-list-item>
    <mat-list-item
      *ngIf="(webSocket.notifications$ | async).length == 0"
      role="listitem"
    >
      <div mat-line class="notifText">
        {{ 'Messages.noUnreadNotif' | translate }}
      </div>
    </mat-list-item>
  </mat-list>
</mat-menu>

<div
  class="chatPopUp"
  *ngFor="let chatPopup of chatService.chatPopups; let i = index"
  [class.chatPopUpMinimized]="chatPopup.isChatMinimized"
  style.right="{{ i * 320 }}px"
>
  <div class="chat-header background-primary">
    <span style="margin-right: auto"
      >{{ chatPopup.userForPopup.firstname }}
      {{ chatPopup.userForPopup.lastname }}</span
    >
    <button
      class="chat-button"
      mat-icon-button
      (click)="chatPopup.isChatMinimized = !chatPopup.isChatMinimized"
    >
      <mat-icon *ngIf="chatPopup.isChatMinimized == false">remove</mat-icon>
      <mat-icon *ngIf="chatPopup.isChatMinimized == true"
        >keyboard_arrow_up</mat-icon
      >
    </button>
    <button class="chat-button2" mat-icon-button (click)="closeChatPopup(i)">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div [class.hideMsges]="chatPopup.isChatMinimized">
    <app-chat-room
      [userToChat]="chatPopup.userForPopup"
      [isPopup]="true"
    ></app-chat-room>
  </div>
</div>

<mat-menu #favoriteSelectionMenu>
  <app-favorites-selector></app-favorites-selector>
</mat-menu>

<app-entity-menu-selector
  #EntitySelectionMenu
  (SELECTENTITY)="openItem('new', $event)"
></app-entity-menu-selector>

<mat-menu #globalSearchInput class="mobile-search-input full-width">
  <mat-form-field>
    <input
      matInput
      type="text"
      class="full-width"
      placeholder="Type to search"
      [(ngModel)]="globalsearchStr"
      (keyup)="globalSearch()"
      (click)="$event.stopPropagation()"
    />
  </mat-form-field>
</mat-menu>
