export const enum Trashed {
  Only = 1,
  No = 0,
  All = 2,
}

export enum SpinnerType {
  None = 0,
  Big = 1,
  Small = 2,
}

export enum MeasurementUnit {
  Piece = 0,
  SmallBox = 1,
  Box = 2,
  Palet = 3,
  Metre = 4,
  Kilo = 5,
}

export enum FinancialType {
  Sales = 1,
  BuyGoods = 2,
  WarehouseChange = 3,
  TireExpenses = 4,
  SalesCost = 5,
  GrossMargin = 6,
  PercentGrossMargin = 7,
  InelasticExpenses = 8,
  NetMargin = 9,
  PercentNetMargin = 10,
  InitialFund = 11,
  Payments = 12,
  Receipts = 13,
  FinalFund = 14,
  CustomerBal1 = 15,
  CustomerBal31 = 16,
  SuppliersBal1 = 17,
  SuppliersBal31 = 18,
}

export enum ReportType {
  Forecast = 1,
  Actual = 2,
}

export enum ChannelReaction {
  NoReaction = 0,
  Like = 1,
  LOL = 2,
  Question = 3,
  Ok = 4,
}
export enum ApprovalDecision {
  Approve = 1,
  Reject = 2,
  Null = null,
  None = 3,
  All = -1,
}
