<ng-template #loginCardTem>
  <mat-card class="text-alternate-warn mat-elevation-z5">
    <mat-card-header style="display: flex; justify-content: center">
      <mat-card-title
        class="headerTitle"
        *ngIf="!(layoutService.isHandset$ | async)"
        >TeamWork OS</mat-card-title
      >
      <img
        src="./../../assets/logos/LoginLogo.png"
        class="logoM"
        *ngIf="layoutService.isHandset$ | async"
      />
    </mat-card-header>
    <br />
    <mat-card-content>
      <form class="example-form">
        <table class="example-full-width">
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-icon matPrefix>person</mat-icon>
                <mat-label>Email</mat-label>
                <input
                  matInput
                  placeholder="email@example.com"
                  [(ngModel)]="email"
                  (keydown.enter)="login()"
                  name="username"
                  autofocus
                />
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <td>
              <mat-form-field class="example-full-width">
                <mat-icon matPrefix>lock</mat-icon>
                <mat-label>Password</mat-label>
                <input
                  matInput
                  (keydown.enter)="login()"
                  placeholder="****"
                  [(ngModel)]="password"
                  type="password"
                  name="password"
                />
              </mat-form-field>
            </td>
          </tr>
        </table>
      </form>
      <mat-error *ngIf="loginError" class="errorArea">
        {{ 'Messages.wrongCreds' | translate }}
      </mat-error>
    </mat-card-content>
    <div>
      <button
        mat-raised-button
        color="primary"
        class="loginbtn"
        (keydown.enter)="login()"
        (click)="login()"
      >
        Login
      </button>
    </div>
  </mat-card>
</ng-template>

<!--Desktop-->
<div
  style="display: flex; height: 100%"
  *ngIf="!(layoutService.isHandset$ | async)"
>
  <div class="leftDiv background-primary"></div>
  <div class="rightDiv">
    <div class="loginCustomer">
      <ng-container *ngTemplateOutlet="loginCardTem"></ng-container>
    </div>
  </div>
</div>

<!--Mobile-->
<div *ngIf="layoutService.isHandset$ | async">
  <div class="backDivsM">
    <div class="upDivM background-primary"></div>
    <div class="downDivM">
      <div class="loginCustomerM">
        <ng-container *ngTemplateOutlet="loginCardTem"></ng-container>
      </div>
    </div>
  </div>
</div>
