import { inject, Pipe, PipeTransform } from '@angular/core';
import { LoginService } from '../services/login.service';

@Pipe({
  name: 'translateFieldLabel',
})
export class TranslateFieldLabelPipe implements PipeTransform {
  loginService = inject(LoginService);
  transform(field: any): string {
    const activeLanguage = this.loginService.getLoginUser()?.lang;
    return activeLanguage === 'en' ? field.label_en : field.label_gr;
  }
}
